import React, {memo} from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import '../../assets/styles/sass/components/banner.scss'
import {BannerStyled, Item, NextArrowStyled, PrevArrowStyled} from './styled'
import BannerItem from './BannerItem'
import {sha256} from '../../utils/utilities'
import {buildImageUrl} from '../../utils/buildImageUrl'

const BannerSimple = ({content}) => {
  const bannersFactory = () => {
    const newBanner = []
    const bannersGroup = {banners: [content]}

    bannersGroup.banners.forEach((banner, index) => {
      banner.images.forEach((image, idx) => {
        newBanner.push({
          id: sha256(`id-${index}-${idx}`),
          title: image.title ? image.title : '',
          description: image.description ? image.description : '',
          alternateText: banner.alternateText || null,
          link: image.link || '',
          action: image.action || '_self',
          image: image.image
            ? buildImageUrl(image.image.url, banner.width || 1920, banner.height || 455)
            : null,
          interval: image.transitionTime || 4000,
          activeFrom: image.activeFrom,
          activeUntil: image.activeUntil
        })
      })
    })

    return newBanner
  }

  const PrevArrow = ({onClick}) => {
    return <PrevArrowStyled className="fal fa-angle-left" onClick={onClick} />
  }

  const NextArrow = ({onClick}) => {
    return <NextArrowStyled className="fal fa-angle-right" onClick={onClick} />
  }

  const getIntervals = () => {
    const banners = bannersFactory()
    const intervals = []

    banners.forEach((banner) => {
      intervals.push(banner.interval)
    })

    return intervals
  }

  const intervals = getIntervals()
  return (
    <BannerStyled>
      <Slider
        dots={false}
        infinite={intervals.length > 1}
        slidesToShow={1}
        slidesToScroll={1}
        arrows
        speed={500}
        draggable={false}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        fade
        autoplay>
        {bannersFactory().map((banner) => (
          <Item key={`banner-${banner.id}`}>
            <BannerItem banner={banner} />
          </Item>
        ))}
      </Slider>
    </BannerStyled>
  )
}

BannerSimple.propTypes = {
  content: PropTypes.object.isRequired
}

export default memo(BannerSimple)
