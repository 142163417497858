import styled from 'styled-components'

export const Wrap = styled.div`
  border-bottom: 1px solid rgba(45, 62, 80, 0.12);
  margin-bottom: 30px;
`

export const Question = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  color: #002c63;
`

export const QuestionIcon = styled.div`
  width: 45px;
  display: flex;
  margin-top: 12px;
`

export const QuestionIconInner = styled.div`
  position: relative;
  width: 24px;
  height: 2px;
  transition: transform 0.3s;
  transform: rotate(180deg);
  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 0;
    border-bottom: 2px solid #002c63;
    position: absolute;
    bottom: 24px / 2 - 2px /2;
    transform: rotate(90deg);
    transition: width 0.3s;
  }
  &.open {
    transform: rotate(0deg);
    &::before {
      width: 0;
    }
  }
  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 0;
    border-bottom: solid 2px #002c63;
    position: absolute;
    bottom: 24px /2 - 2px /2;
  }
`

export const QuestionText = styled.div``

export const Answer = styled.div`
  padding-left: 45px;
  font-size: 16px;

  figure {
    @media all and (max-width: 997px) {
      width: 100%;
      margin: 0;
    }

    img {
      @media all and (max-width: 997px) {
        width: 100%;
      }
    }
  }
`
