import {hasProperty} from '../../../utils/functions'

const Types = {
  Article: {
    title: 'Artigo',
    slug: 'artigo'
  },
  Ebook: {
    title: 'E-book',
    slug: 'e-book'
  },
  Infographic: {
    title: 'Infográfico',
    slug: 'infografico'
  },
  Podcast: {
    title: 'Podcast',
    slug: 'podcast'
  },
  VideoConference: {
    title: 'Videocoferência',
    slug: 'videoconferencia'
  },
  Video: {
    title: 'Vídeo',
    slug: 'video'
  },
  Webinar: {
    title: 'Webinar',
    slug: 'webinar'
  },
  Free: {
    title: 'Cursos Gratuitos',
    slug: 'gratuitos'
  },
  Course: {
    title: 'Curso',
    slug: 'curso'
  },
  Saved: {
    title: 'Favoritos',
    slug: 'favoritos'
  },
  Certificates: {
    title: 'Certificados',
    slug: 'certificados'
  },
  ViewedContents: {
    title: 'Conteúdos Visualizados',
    slug: 'conteudos-visualizados'
  },
  MyCourses: {
    title: 'Meus conteúdos comprados',
    slug: 'meus-cursos'
  },
  Contents: {
    title: 'Conteúdos',
    slug: 'conteudos'
  }
}

export const getType = (type) => {
  return hasProperty(Types, type) ? Types[type].slug : ''
}

export const getTitleByType = (type) => {
  return hasProperty(Types, type) ? Types[type].title : ''
}

export const getTypeBySlug = (slug) => {
  let type = ''
  Object.keys(Types).forEach((key) => {
    if (hasProperty(Types, key) && Types[key].slug === slug) {
      type = key
    }
  })

  return type
}

export const getTypeTitle = (slug) => {
  let title = null
  Object.keys(Types).forEach((key) => {
    if (hasProperty(Types, key) && (Types[key].slug === slug || key === slug)) {
      title = Types[key].title
    }
  })
  return title
}
