import React, {useCallback, useContext, useState} from 'react'
import PropTypes from 'prop-types'
import Script from 'react-load-script'
import {useHistory} from 'react-router-dom'
import toSlugCase from 'to-slug-case'
import {settings} from '../../config/settings'
import {getSettings} from '../../services/endpoints/general/settings'
import {postContentView} from '../../services/endpoints/contents/contents'
import {getType} from '../Contents/Enum/types'
import {WatchedContext} from '../../pages/Contents/context'
import {getToken} from '../../utils/auth'

const SambaPlayer = ({reference, contentId, progressVideo = false, typeContent, nextVideo}) => {
  const handleCurrentWatched = useContext(WatchedContext)
  const [playerKey, setPlayerKey] = useState(null)
  const {location} = useHistory()
  let flag = false
  const loadSettings = useCallback(async () => {
    try {
      const response = await getSettings()
      const data = response.data['hydra:member'] || []

      setPlayerKey(
        data.filter(
          (i) => i.name === 'sambavideos_playerKey' || i.name === 'sambavideos_uploaderKey'
        )[0].value || null
      )
    } catch (e) {
      console.error('SambaPlayer.error', e)
    }
  }, [])

  const initSambaPlayer = () => {
    loadSettings()
  }

  const dispatchActionView = (time, duration) => {
    if (getToken()) {
      const percent = (time * 100) / (duration / 1000);
      (async () => {
        try {
          await postContentView({
            content: contentId,
            percent: Math.round(percent) || 100,
            progress: time
          })
        } catch (e) {
          console.error('SambaPlayer.eventListener.onListen', e)
        }
      })()
    }
  }

  const eventListener = (player) => {
    const eventParam = parseInt(player.eventParam, 10)
    const minWatched = Math.round((player.duration * settings.minPercentageToView) / 100)

    switch (player.event) {
      case 'onLoad':
        break
      case 'onStart':
        break
      case 'onListen':
        if (!flag && eventParam > 1 && (eventParam % 15 === 0 || eventParam === minWatched)) {
          dispatchActionView(
            eventParam === minWatched ? player.duration : eventParam,
            player.duration
          )
          flag = true
        } else if (
          flag &&
          eventParam > 1 &&
          (eventParam % 16 === 0 || eventParam === minWatched + 1)
        ) {
          flag = false
        }
        break
      case 'onPause':
        break
      case 'onResume':
        break
      case 'onSeek':
        break
      case 'onCuepoint':
        break
      case 'onMediaView':
        break
      case 'onProgress':
        break
      case 'onFinish':
        dispatchActionView(player.duration / 1000, player.duration)
        handleCurrentWatched()
        if (Object.keys(nextVideo).length) {
          setTimeout(() => {
            window.location.href = `/conteudos/${toSlugCase(getType(nextVideo['@type']))}/${
              nextVideo.slug
            }${location.search}`
          }, 1000)
        }
        break
      case 'onError':
        break
      default:
    }
  }

  if (playerKey) {
    window.document.getElementById('player').innerHTML = ''
    const playerParams = {
      enabledShare: false
    }
    if (progressVideo) {
      Object.assign(playerParams, {
        resume: progressVideo
      })
    }
    (() =>
      new window.SambaPlayer('player', {
        ph: playerKey,
        m: reference,
        playerParams,
        events: {
          '*': eventListener
        }
      }))()
  }

  return (
    <>
      <Script
        // url="https://player.sambatech.com.br/v3/samba.player.api.js"
        url="/js/samba.player.api.js"
        onLoad={initSambaPlayer}
      />
      <div
        className={`embed-responsive embed-responsive-${
          typeContent === 'Podcast' ? '10' : '16by9'
        } mb-3`}>
        <div className="embed-responsive-item" id="player" />
      </div>
    </>
  )
}

SambaPlayer.propTypes = {
  reference: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  progressVideo: PropTypes.number,
  typeContent: PropTypes.string.isRequired,
  nextVideo: PropTypes.oneOfType([PropTypes.object]).isRequired
}
export default SambaPlayer
