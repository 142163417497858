import styled, {keyframes} from 'styled-components'
import {slideInUp} from 'react-animations'
import {Button as ButtonStyled} from '../Elements/Button'

export const Item = styled.div`
  display: block !important;
  position: relative;
`

export const BannerStyled = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`

export const BannerMask = styled.div`
  position: relative;
  height: 455px;
  background: #f7f7f7;
`

export const BannerImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PrevArrowStyled = styled.i`
  font-size: 60px;
  color: #ffffff;
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 1.5em;
  justify-content: center;
  cursor: pointer;
`

export const NextArrowStyled = styled(PrevArrowStyled)`
  right: 0;
`

export const MetaInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
`

export const MetaInfoContent = styled.div`
  @media (min-width: 992px) {
    max-width: 55%;
  }
`

const slideInUpAnimation = keyframes`${slideInUp}`

export const TitleAlternate = styled.h2`
  color: #002c63;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  animation: 1s ${slideInUpAnimation};
`

export const Title = styled.h2`
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #002c63;
  margin-bottom: 10px;
  animation: 1s ${slideInUpAnimation};
`

export const Description = styled.p`
  margin: 30px 0;
  font-size: 15px;
  color: #40444d;
  line-height: 23px;
  animation: 1.2s ${slideInUpAnimation};
`

export const Button = styled(ButtonStyled)`
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1.2s ${slideInUpAnimation};
  &:hover {
    border-color: rgba(253, 218, 112, 0.7);
    background-color: rgba(253, 218, 112, 0.7);
  }
  a {
    text-decoration: none;
    color: #002c63 !important;
  }
`
