import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import Breadcrumb from '../../components/Breadcrumb'
import {hasProperty} from '../../utils/functions'
import PartnerItem from '../../components/Partners/PartnerItem'
import {getGroupPartner} from '../../services/endpoints/contents/contents'
import {comparar} from '../../utils/masks'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'

const PartnersList = ({match, history}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState([])
  const {slug} = match.params

  const loadContents = useCallback(async () => {
    try {
      const response = await getGroupPartner(slug)
      const {data} = response
      setContent(data || [])
    } catch (error) {
      console.error('loadContents.getEndpoints', error)
      history.push('/404')
    }

    setLoading(false)
  }, [slug, history])

  useEffect(() => {
    loadContents()
    return () => {
      setLoading(true)
    }
  }, [loadContents])

  if (loading) {
    return null
  }

  return (
    <Container className="pb-5">
      <h1 style={{marginBottom: 15}}>{content.name}</h1>
      <Breadcrumb contents={[{title: 'Parceiros', url: '/parceiros'}, {title: content.name}]} />
      <div
        style={{marginBottom: 45}}
        dangerouslySetInnerHTML={{__html: sanitizeHtml(content.description)}}
      />

      {hasProperty(content, 'partners') && content.partners.length ? (
        <Row>
          {content.partners.sort(comparar).map((partner) => (
            <Col key={partner['@id']} xs={12} sm={6} md={4} lg={3}>
              <PartnerItem partner={partner} />
            </Col>
          ))}
        </Row>
      ) : (
        <p>Sem conteúdos</p>
      )}
    </Container>
  )
}

export default PartnersList
