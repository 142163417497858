const passToPascalCase = (string) => {
  let newString = string.charAt(0).toUpperCase()
  for (let i = 1; i < string.length; i += 1) {
    if (string.charAt(i - 1) === '-') {
      newString += string.charAt(i).toUpperCase()
    } else if (string.charAt(i) !== '-') {
      newString += string.charAt(i)
    }
  }

  return newString
}

const nameFormat = (name) => {
  const arrayName = name.split(' ')
  const firstName = arrayName[0]
  const lastChar = name.length > 1 ? arrayName[arrayName.length - 1].charAt(0).toUpperCase() : ''

  return `${firstName}${firstName ? ` ${lastChar}` : ''}.`
}

const splitFullname = (str) => {
  const pos = str.indexOf(' ')
  const firstname = str.substring(0, pos)
  const lastname = str.substring(pos + 1)

  return {firstname, lastname}
}

const getSrc = (str) => {
  const regex = /src\s*=\s*"(.+?)"/
  const strClear = regex.exec(str)
  return strClear ? strClear[1] : str
}

export {passToPascalCase, nameFormat, splitFullname, getSrc}
