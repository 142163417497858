import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const noToken = axios.create({
  baseURL: apiUrl.replace('/v1', ''),
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default noToken