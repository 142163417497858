export const getAtualContent = (lessons) => {
  let index = 0
  let courseWatcheds = 0
  const noWatcheds = []
  const watcheds = []

  lessons.forEach((lesson, i) => {
    let checkExam = true

    if (lesson && lesson.content.exam) {
      checkExam = false
    }

    if (lesson.watched && checkExam) {
      // index = i;
      courseWatcheds += 1
      watcheds.push(i)
    } else {
      noWatcheds.push(i)
    }
  })

  if (noWatcheds.length > 0) {
    index = Math.min.apply(null, noWatcheds)
  } else {
    index = Math.max.apply(null, watcheds)
  }

  return {
    currentLesson: index,
    courseProgress: Math.ceil((courseWatcheds * 100) / lessons.length) || 0
  }
}
