import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import {Col} from 'reactstrap'
import {useHistory} from 'react-router-dom'
import {getPartners} from '../../../services/endpoints/contents/contents'
// import {getTitle} from '../../../components/Contents/Enum/categories';
import {Label, Select, SelectGroup} from './styled'

const SelectPartners = ({mayHave, setFieldValue}) => {
  const isMounted = useRef(true)
  const [loading, setLoading] = useState(true)
  const [contents, setContents] = useState([])
  const history = useHistory()
  const params = queryString.parse(history.location.search)

  const loadContents = useCallback(async () => {
    try {
      const response = await getPartners({
        perPage: 40
      })
      const {data} = response
      setContents(data['hydra:member'] || [])
    } catch (error) {
      console.error('SelectPartners.loadContents', error)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      loadContents()
    }
    return () => {
      setLoading(true)
      setContents([])
      isMounted.current = false
    }
  }, [loadContents])

  if (loading || !contents.length) {
    return null
  }

  return (
    <Col xs={12} md={6} lg={4} className="mb-3 mb-lg-0">
      {mayHave ? 
        <SelectGroup>
          <Label>Selecione</Label>
          <Select
            type="select"
            name="parceiro"
            onChange={setFieldValue}
            defaultValue={params.parceiro}>
            <option value="">Parceiro</option>
            {contents
              .map((content) => (
                <option key={content['@id']} value={content.slug}>
                  {content.name}
                </option>
              ))}
          </Select>
        </SelectGroup> : 
        <SelectGroup>
          <Label>Selecione</Label>
          <Select
            type="select"
            name="parceiro"
            onChange={setFieldValue}
            defaultValue={params.parceiro}>
            <option value="">Parceiro</option>
            {contents
              .filter(content => content.slug !== 'cvm')
              .map((content) => (
                <option key={content['@id']} value={content.slug}>
                  {content.name}
                </option>
              ))}
          </Select>
        </SelectGroup>}
    </Col>
  )
}

SelectPartners.propTypes = {
  setFieldValue: PropTypes.func.isRequired
}
export default SelectPartners
