import Cookie from 'js-cookie'
import {asyncLocalStorage} from './asyncLocalStorage'
// eslint-disable-next-line import/no-cycle
import api from '../services/api'

const getLogoutPage = `${process.env.REACT_APP_SIGNIN_URL}/sair`

function deleteToken () {
  Object.keys(Cookie.get()).forEach((item) => {
    Cookie.remove(item)
  })
  return Cookie.remove(process.env.REACT_APP_TOKEN)
}

export const createCookie = (response) => {
  if (window.location.origin.indexOf('localhost') !== -1) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken)
  }

  Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken, {
    domain: process.env.REACT_APP_DOMAIN
  })
}

function getToken () {
  // return Cookie.get(process.env.REACT_APP_TOKEN) || null;
  // return JSON.parse(Cookie.get('authB3')) || null;
  let localCookie = Cookie.get('authB3') || null
  localCookie = localCookie ? JSON.parse(localCookie) : null
  return localCookie
}

function getCurrentUser () {
  if (getToken()) {
    return JSON.parse(localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`))
  }
  return {
    user: {},
    profile: {}
  }
}

async function getAsyncCurrentUser () {
  return asyncLocalStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`)
}

function setCurrentUser (data) {
  localStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(data))
}

function setAsyncCurrentUser (data) {
  return asyncLocalStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(data))
}

const getLoginPage = `${process.env.REACT_APP_SIGNIN_URL}/login`

const logout = async () => {
  localStorage.clear()
  sessionStorage.clear()
  deleteToken()
  await api.get('/invalidate_session')
  setTimeout(() => {
    window.location.href = getLogoutPage
  }, 3000)

  return null
}

function redirectToLogin () {
  localStorage.clear()
  deleteToken()

  setTimeout(() => {
    window.location.href = `${getLoginPage}?redirect=${btoa(document.location.href)}`
  }, 500)
}

function refreshLogin () {
  localStorage.clear()
  deleteToken()
  window.location = `${getLoginPage}?redirect=${btoa(document.location.href)}`
}

export {
  getToken,
  getCurrentUser,
  getAsyncCurrentUser,
  setCurrentUser,
  setAsyncCurrentUser,
  redirectToLogin,
  refreshLogin,
  logout,
  getLoginPage,
  getLogoutPage
}
