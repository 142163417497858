import {getContents} from '../../../services/endpoints/contents/contents'

export const makeContentFree = async () => {
  let slugOff = window.location.pathname.split('/')
  slugOff = slugOff[slugOff.length - 1]

  const res = await getContents('/contents', {
    slug: slugOff
  })
  const {data} = res

  if (data['hydra:member']) {
    return data['hydra:member'][0]
  }
  return null
}
