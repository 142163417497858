import {getCourseLessons, getFreeCourseLessons} from '../../../services/endpoints/contents/contents'
import {userWatchedTheContent} from './userWatchedTheContent'
import {loadExamUser} from './loadExamUser'
import {initialContentParams} from './initialContentParams'
import {getToken} from '../../../utils/auth'

export const loadContentLessons = async (idContent) => {
  let data
  let lessons
  try {
    if (!getToken()) {
      const response = await getFreeCourseLessons(idContent)
      data = response.data['hydra:member'] || []
    } else {
      const response = await getCourseLessons(idContent)
      data = response.data['hydra:member'] || []
    }
    
    lessons = await Promise.all(
      data.map(async (lesson) => {
        let examsParams = {}
        if (getToken() && lesson.content.exam) {
          examsParams = await loadExamUser(lesson.content.id)
          
          // Object.assign(params, await loadExamUser(lesson.content.id));
        }
        const params = {
          ...initialContentParams,
          ...userWatchedTheContent(lesson.content.userCollections),
          ...examsParams
        }
        
        return {
          ...lesson,
          ...params
        }
      })
    )
    
    if (lessons.length > 0) {
      return lessons || {}
    // eslint-disable-next-line no-else-return
    } else {
      return data
    }
  } catch (e) {
    console.error('loadContentLessons[getCourseLessons]', e)
    return [...data || []]
  }
}
