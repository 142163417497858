import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 50%;
  background: white;
  padding: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h2 {
    margin-top: 20px;
    font-size: 45px;
    line-height: 56.25px;
    letter-spacing: -1px;
    margin-bottom: 24px;
  }

  button.SignUp {
    margin-top: 64px;
    margin-bottom: 32px;
  }

  @media (max-width: 768px) {
    padding: 24px;

    h2 {
      font-size: 28px;
      line-height: 35px;
      letter-spacing: -1px;
    }

    @media (max-width: 380px) {
      h2 {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 10px;
      }

      button.SignUp {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 180px;
        font-size: 12px;
        padding: 0 20px;
      }
    }
  }
`

export const SignInButton = styled.button`
  font-weight: 700;
  background: transparent;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1.5px;
  border: 0;
  cursor: pointer;
  padding: 8px;
  color: #002C63;

  @media (max-width: 380px) {
    font-size: 12px;
    letter-spacing: 1px;
  }
`
