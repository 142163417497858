import React, {useEffect} from 'react'
import {positions, Provider} from 'react-alert'
import {hotjar} from 'react-hotjar'
import ReactGA from 'react-ga'
import AlertMUITemplate from 'react-alert-template-mui'
import LinkedInTag from 'react-linkedin-insight'
import ReactPixel from 'react-facebook-pixel'
import {settings} from './config/settings'
import Routes from './routes'

const options = {
  position: positions.MIDDLE
}

function App () {
  hotjar.initialize(settings.codes.hotjar.hjid, settings.codes.hotjar.hjsv)
  ReactGA.initialize(settings.codes.gtm.uaId, {
    debug: false
  })
  ReactGA.pageview(window.location.pathname + window.location.search)
  ReactGA.event({
    category: 'Initialize',
    action: 'Navigation',
    label: 'conversion'
  })
  useEffect(() => {
    LinkedInTag.init(settings.codes.linkedin.partnerId)
    LinkedInTag.track(settings.codes.linkedin.conversionId)
  }, [])

  ReactPixel.init(
    settings.codes.pixel.id,
    {},
    {
      autoConfig: true,
      debug: false
    }
  )

  ReactPixel.pageView(window.location.pathname + window.location.search) // For tracking page view
  useEffect(() => {
    if (window.location.pathname === '/') {
      const script = document.createElement('script')
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-615809848'
      script.async = true
      script.setAttribute('id', 'gtagcustom')
      document.head.prepend(script)
    }
  }, [window.location.pathname])

  return (
    <Provider template={AlertMUITemplate} {...options}>
      <Routes />
    </Provider>
  )
}

export default App
