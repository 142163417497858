import {getRelatedContents} from '../../../services/endpoints/contents/contents'

export const loadRelatedContents = async (idContent) => {
  try {
    const response = await getRelatedContents(idContent)
    return response.data['hydra:member'] || []
  } catch (e) {
    console.error('loadRelatedContents.getRelatedContents', e)
    return []
  }
}
