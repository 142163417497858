import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {hasExternalLink} from '../../utils/utilities'
import {
  BannerImage,
  BannerMask,
  Button,
  Description,
  MetaInfo,
  MetaInfoContent,
  Title
} from './styled'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'

const BannerItem = ({banner}) => {
  const MyLink = (props) => {
    const {children} = props

    if (hasExternalLink(banner.link)) {
      return (
        <a href={banner.link || '/'} target={banner.action || '_self'} rel="noopener noreferrer">
          {props.children}
        </a>
      )
    }

    return <Link to={banner.link}>{children}</Link>
  }

  return (
    <div>
      <BannerMask>
        <BannerImage src={banner.image} alt={banner.title} />
      </BannerMask>
      <MetaInfo>
        <div className="container">
          <MetaInfoContent>
            <Title>{banner.title}</Title>
            <Description dangerouslySetInnerHTML={{__html: sanitizeHtml(banner.description)}} />
            <Button color="primary" type="div">
              <MyLink>{banner.alternateText || 'Saiba Mais'}</MyLink>
            </Button>
          </MetaInfoContent>
        </div>
      </MetaInfo>
    </div>
  )
}

BannerItem.propTypes = {
  banner: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default memo(BannerItem)
