import React from 'react'
import Amplify from 'aws-amplify'
import ReactDOM from 'react-dom'
import './assets/styles/sass/base.scss'
import App from './App'

Amplify.configure({
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDP_ID,
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {}
})

ReactDOM.render(<App />, document.getElementById('root'))
