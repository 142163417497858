import React from 'react'
import {Container} from 'reactstrap'
import styled from 'styled-components'

const PageErrors = () => {
  return (
    <Main nonce={btoa(process.env.REACT_APP_TOKEN)}>
      <Container>
        <Box className="text-center pt-5 pb-5">
          <h1 className="hide-border">500</h1>
          <p>Ops! Ocorreu um erro em nosso sistema, tente novamente mais tarde.</p>
        </Box>
      </Container>
    </Main>
  )
}

const Main = styled.div`
  background: #002c63;
  height: 100vh;
  display: flex;
  flex: 1;
  overflow: hidden;
  color: #fff;
  align-items: center;
  h1 {
    color: #fff;
  }
`

const Box = styled.div`
  position: relative;
`

export default PageErrors
