import React from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row} from 'reactstrap'
import {AboutBlockInternal as AboutBlockInternalStyled, Description, Image, Title} from './styled'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'

const AboutBlockInternal = ({content}) => {
  const feature = content || {}
  return (
    <AboutBlockInternalStyled>
      <Container>
        <Row>
          <Col xs={12} md={{size: 10, offset: 1}}>
            <Row>
              <Col xs={12} sm={5} md={6} className="mb-3 d-flex flex-column justify-content-center">
                <Title>{feature.title}</Title>
                <Description
                  dangerouslySetInnerHTML={{__html: sanitizeHtml(feature.description)}}
                />
              </Col>
              <Col xs={12} sm={5} md={6} className="mb-3">
                {content.image && <Image src={content.image.url} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </AboutBlockInternalStyled>
  )
}

AboutBlockInternal.propTypes = {
  content: PropTypes.object.isRequired
}

export default AboutBlockInternal
