import api from '../../api'
import {getCurrentUser} from '../../../utils/auth'

export const getMe = (params = {}) => {
  return api.get(`/me`, {
    params: {
      ...params
    }
  })
}

export const getUserStatistics = () => {
  return api.get(`/user_statistics`)
}

export const updateUser = (values) => {
  const {user} = getCurrentUser()

  return api.put(user['@id'].replace('/v1/', '/'), {
    ...values
  })
}

export const changePasswordUser = (params) => {
  return api.post(`${getCurrentUser().user['@id'].replace('/v1/', '')}/change_password`, params)
}

export const updateAvatar = (image) => {
  return api.post('/current_customer_avatars', image, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
