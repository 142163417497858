export const decodeHtml = (text) => {
  const encodes = ['&amp;']
  const decodes = ['&']
  let decodeText = text

  if (text) {
    for (let i = 0; i < encodes.length; i += 1) {
      const regex = RegExp(encodes[i], 'g')
      decodeText = text.replace(regex, decodes[i])
    }
  }
  return decodeText
}
