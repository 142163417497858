import assign from 'lodash.assign' // Basic configuration
import sanitizeHTML from 'sanitize-html'

const CONFIG = {
  allowedTags: [
    'p',
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'a',
    'em',
    'strong',
    'br',
    'span',
    'b',
    'i',
    'img',
    'figure',
    'iframe',
    'table',
    'td',
    'tr',
    'th',
    'tbody',
    'thead',
    'tfoot',
    'blockquote',
    's',
    'hr',
    'sub',
    'sup'
  ],
  allowedAttributesByTags: {
    a: ['href', 'title', 'class'],
    img: ['src', 'alt', 'title', 'class'],
    figure: ['src', 'alt', 'title', 'class'],
    iframe: ['src', 'class', 'class'],
    div: ['class', 'class'],
    strong: ['class'],
    p: ['class'],
    b: ['class'],
    i: ['class'],
    em: ['class'],
    span: ['class'],
    h1: ['class'],
    h2: ['class'],
    h3: ['class'],
    h4: ['class'],
    h5: ['class'],
    h6: ['class'],
    hr: ['class'],
    s: ['class']
  },
  allowedSchemas: ['http', 'https']
}

function sanitizeHtml (data, settings) {
  const defaultSettings = {
    allowedTags: CONFIG.allowedTags,
    allowedAttributes: CONFIG.allowedAttributesByTags,
    allowedSchemes: CONFIG.allowedSchemas
  }
  if (!data) {
    return ''
  }
  return sanitizeHTML(data, assign(defaultSettings, settings))
}

export {sanitizeHtml}
