import React from 'react'
import {hasProperty} from './functions'

const Icons = {
  youtube: 'youtube',
  facebook: 'facebook-f',
  whatsapp: 'whatsapp',
  instagram: 'instagram',
  messenger: 'facebook-messenger',
  'facebook-messenger': 'facebook-messenger',
  twitter: 'twitter',
  twitch: 'twitch',
  linkedin: 'linkedin',
  line: 'line',
  pinterest: 'pinterest',
  skype: 'skype',
  snapchat: 'snapchat',
  reddit: 'reddit'
}

const IconsSvg = {}
IconsSvg['fab fa-tiktok'] = require('../assets/icons/tiktok').default

const CallIcon = ({component}) => {
  const Component = IconsSvg[component]
  if (!Component) {
    return null 
  }
  return <Component />
}

const GetIcons = ({icon}) => {
  if (hasProperty(Icons, icon.toLowerCase())) {
    return <i className={Icons[icon.toLowerCase()]} />
  }
  if (hasProperty(IconsSvg, icon.toLowerCase())) {
    return <CallIcon component={icon.toLowerCase()} />
  }
  return <i className={icon.toLowerCase()} />
}

export default GetIcons
