import React, {useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row} from 'reactstrap'
import toSlugCase from 'to-slug-case'
import Truncate from 'react-truncate'
import styled from 'styled-components'
import {useMediaQuery} from 'react-responsive'
import Collections from '../_partials/Collections'
import {sanitizeHtml} from '../../../utils/santinize/sanitizeHtml'
import {
  Box,
  Button,
  Category,
  ContentTitle,
  Description,
  FreeContent,
  Image,
  Item,
  LinkImage,
  MetaInfo,
  Partner,
  Price,
  TagsButtons,
  Title
} from '../_partials/styled'
import {getType} from '../Enum/types'
import {priceFormat} from '../../../utils/functions'
import {getToken} from '../../../utils/auth'
import {getImageContentItem} from '../../../utils/buildImageUrl'
import {loadPublicImage} from '../../../utils/loadPublicImage'
import {decodeHtml} from '../../../utils/santinize/decodeHtml'

const ContentLoggedOut = ({content}) => {
  const type = content['@type']
  const urlType = `/conteudos/${toSlugCase(getType(type))}`
  const isMobile = useMediaQuery({query: '(max-width: 575px)'})

  useLayoutEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  const urlContent =
    content.price && getToken()
      ? `/carrinho/add/${content.id}`
      : `${process.env.REACT_APP_SIGNIN_URL}/cadastro?redirect=${btoa(window.location.href)}`

  return (
    <Container>
      <Row>
        <Col xs={12} sm={8} md={8}>
          {/* <ContentTitle>{content.title}</ContentTitle>
          <Description dangerouslySetInnerHTML={{__html: content.summary}} /> */}
          <ContentTitle contentLoggedOutInMobile={isMobile}>Ops! Você precisa se cadastrar para acessar esse conteúdo.</ContentTitle>

          <AlignCenter>
            <Button
              href={urlContent}
              type="a"
              color={getToken() && content.price ? 'green' : 'primary-light'}>
              {getToken() && content.price ? 'Comprar agora' : 'Cadastre-se grátis'}
            </Button>

            {!isMobile && <OrText>ou</OrText>}

            {!getToken() && (
              <ButtonSignIn
                href={`${process.env.REACT_APP_SIGNIN_URL}/login?redirect=${btoa(
                  window.location.href
                )}`}>
                Já tenho cadastro
              </ButtonSignIn>
            )}
          </AlignCenter>
        </Col>
        <Col xs={12} sm={8} md={4}>
          <Box>
            <Item>
              <LinkImage href={urlContent} type="a" widescreen>
                {content.free && <FreeContent>Gratuito</FreeContent>}
                {content.thumb ? (
                  <Image src={getImageContentItem(content.thumb.url)} />
                ) : (
                  <Image src={loadPublicImage('/no-course.jpg')} alt={decodeHtml(content.title)} />
                )}
              </LinkImage>

              <MetaInfo>
                <Collections content={content} />

                <TagsButtons>
                  {getType(type) && <Category to={urlType}>{getType(type)}</Category>}
                  {content.partner && (
                    <Partner
                      to={`/conteudos?by=parceiros&typeFilter=${btoa(content.partner['@id'])}`}>
                      {content.partner.name}
                    </Partner>
                  )}
                </TagsButtons>

                {getToken() && !!content.price && (
                  <Price>
                    {!content.promotionalPrice && <div> de {priceFormat(content.price)}</div>}
                    {priceFormat(content.currentPrice)}
                  </Price>
                )}

                <Title href={urlContent} type="a" height={1}>
                  <Truncate lines={3}>{decodeHtml(content.title)}</Truncate>
                </Title>

                <Description height={2}>
                  <Truncate lines={2}>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(content.summary)}} />
                  </Truncate>
                </Description>

                <Button href={urlContent} type="a" color="primary-light">
                  {getToken() && content.price ? 'Comprar agora' : 'Cadastre-se grátis'}
                </Button>
              </MetaInfo>
            </Item>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const OrText = styled.div`
  padding: 30px 0;
`
const ButtonSignIn = styled.a`
  color: #313131;
  
  @media all and (max-width: 575px) {
    margin: 20px 0;
  }
`

ContentLoggedOut.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired
}
export {ContentLoggedOut}
