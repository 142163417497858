import React from 'react'
import PropTypes from 'prop-types'
import {Container} from 'reactstrap'
import * as Yup from 'yup'
import {useAlert} from 'react-alert'
import GenerateForm from '../GenerateForm'
import {ContactForm} from './styled'
import {sendContact} from '../../services/endpoints/cms/contact_messages'
import {settings} from '../../config/settings'

const Contacts = ({content}) => {
  const alert = useAlert()
  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Nome e sobrenome',
          hasPlaceholder: true,
          name: 'name',
          value: '',
          required: true,
          validations: {
            name: Yup.string().required('Campo obrigatório')
          }
        },
        {
          label: 'E-mail',
          hasPlaceholder: true,
          name: 'email',
          value: '',
          required: true,
          validations: {
            email: Yup.string().required('Campo obrigatório').email('Informe um email válido')
          }
        },
        {
          label: 'Assunto',
          hasPlaceholder: true,
          name: 'subject',
          value: '',
          required: true,
          validations: {
            subject: Yup.string().required('Campo obrigatório')
          }
        },
        {
          label: 'Mensagem',
          hasPlaceholder: true,
          name: 'message',
          value: '',
          required: true,
          validations: {
            message: Yup.string().required('Campo obrigatório')
          },
          type: 'textarea'
        }
        /* {
          label: 'Aceitar a Newsletter',
          hasPlaceholder: false,
          name: 'newsletter',
          type: 'radio',
        }, */
      ]
    }
  ]

  const onSubmit = async (values, actions) => {
    try {
      await sendContact(content.slug, {
        ...values,
        newsletter: true
      })

      const resetValues = {}
      Object.keys(values).forEach((key) => {
        Object.assign(resetValues, {[key]: ''})
      })

      alert.show('Em breve, nossa equipe de atendimento retornará.', {
        title: 'Mensagem enviada com sucesso!',
        closeCopy: 'Ok'
      })

      actions.resetForm(resetValues)
    } catch (e) {
      alert.show('Erro ao enviar formulário', {
        title: settings.titleSite,
        closeCopy: 'Ok'
      })
    }

    actions.setSubmitting(false)
  }

  return (
    <Container>
      <ContactForm>
        <GenerateForm
          onSubmit={onSubmit}
          groupFields={groupFields}
          settings={{
            button: {
              text: 'Enviar mensagem',
              textSubmitting: 'Enviando...'
            }
          }}
        />
      </ContactForm>
    </Container>
  )
}

Contacts.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired
}
export default Contacts
