import React, {useEffect, useRef, useState} from 'react'
import Axios from 'axios'
import {Link, useHistory, useRouteMatch} from 'react-router-dom'
import {useDebouncedCallback} from 'use-debounce'
import {useAlert} from 'react-alert'
import {Box, Content, ContentLists, Input, Results} from './styled'
import {getSearchGlobal} from '../../../services/endpoints/general/search'
import {Button} from '../../Elements'
import {settings} from '../../../config/settings'
import {makeUrlContent} from '../../../pages/Contents/data/makeUrlContent'
import {decodeHtml} from '../../../utils/santinize/decodeHtml'

const {CancelToken} = Axios
let cancel

const SearchBlock = () => {
  const node = useRef({})
  const match = useRouteMatch()
  const alert = useAlert()
  const history = useHistory()
  const [active, setActive] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [contents, setContents] = useState([])
  const [valueField, setValueField] = useState('')
  const [objetives, setObjetives] = useState([])
  const [query, setQuery] = useState('')

  const autocompleteSearch = (search) => {
    if (cancel !== undefined) {
      cancel()
    }

    return getSearchGlobal(search, {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response))
  }

  const autoCompleteDebounced = async (value) => {
    if (value.length > 1) {
      try {
        const results = await autocompleteSearch(value)
        setContents(results.contents.slice(0, 3) || [])
        setObjetives(results.trails.slice(0, 3) || [])
      } catch (error) {
        if (error !== undefined) {
          console.error('SearchBlock.autoCompleteDebounced', error) 
        }
      }
      setActive(true)
      setShowResults(true)
    } else {
      setContents([])
      setObjetives([])
      setActive(false)
      setShowResults(false)
    }
    return null
  }

  const [autoComplete] = useDebouncedCallback((value) => autoCompleteDebounced(value), 500)

  const FormatContentTitle = (title) => {
    const strpos = title.indexOf(' ')
    if (strpos !== -1) {
      const thin = title.slice(0, strpos)
      return (
        <>
          {thin} <b>{decodeHtml(title.slice(strpos))}</b>
        </>
      )
    }

    return decodeHtml(title)
  }

  const handleClickOutsite = (e) => {
    if (node.current.contains(e.target)) {
      return
    }

    setActive(false)
  }

  const handleChange = (value) => {
    setQuery(encodeURIComponent(value))
    autoComplete(value)
  }

  const handlePressEnter = (e) => {
    if (e.keyCode === 13) {
      if (query.length > 1) {
        history.push(`/busca?query=${query}`)
      } else {
        alert.show('A busca deve conter pelo menos 2 caracteres', {
          title: settings.titleSite,
          closeCopy: 'Ok'
        })
      }
    }
  }
  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutsite)
    } else {
      document.removeEventListener('mousedown', handleClickOutsite)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsite)
    }
  }, [active])

  useEffect(() => {
    if (history.location.search.length) {
      setValueField((prev) => prev)
    } else {
      setValueField('')
    }
  }, [match, history])

  return (
    <Box ref={node}>
      <Input
        type="text"
        value={valueField}
        placeholder="Qual assunto deseja buscar?"
        onChange={(e) => {
          setValueField(e.target.value)
          handleChange(e.target.value)
        }}
        onFocus={() => setActive(true)}
        onKeyDown={handlePressEnter}
      />

      <Button color="transparent" type={Link} to={`/busca?query=${query}`}>
        <i className="fal fa-search" />
      </Button>

      {valueField.length >= 2 && active
        ? showResults && (
          <Results>
            {!!contents.length && (
              <ContentLists hasBorder={!!objetives.length}>
                {contents.map((content) => (
                  <Content
                    key={content['@id']}
                    to={makeUrlContent(content)}
                    title={content.title}>
                    {FormatContentTitle(content.title)}
                  </Content>
                ))}
              </ContentLists>
            )}
            {!contents.length ? (
              <p>Nenhum resultado encontrado</p>
            ) : (
              <Button
                className="mt-3"
                color="blue-to-white"
                type={Link}
                to={`/busca?query=${query}`}>
                  Veja resultado completo
              </Button>
            )}
          </Results>
        )
        : null}
    </Box>
  )
}

export {SearchBlock}
