import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Container} from 'reactstrap'
import AnimateHeight from 'react-animate-height'
import {Answer, Question, QuestionIcon, QuestionIconInner, QuestionText, Wrap} from './styled'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'

const Faq = ({content}) => {
  const faqs = content['hydra:member'] || []
  const [toogle, setToogle] = useState(0)

  const handleToogle = (id) => {
    setToogle((prevValue) => (prevValue !== id ? id : 0))
  }

  return (
    <Container>
      {faqs
        .sort((a, b) => {
          return a.position - b.position
        })
        .map((faq) => (
          <Wrap key={faq['@id']}>
            <Question onClick={() => handleToogle(faq.id)}>
              <QuestionIcon>
                <QuestionIconInner className={faq.id === toogle ? 'open' : ''} />
              </QuestionIcon>
              <QuestionText>{faq.question}</QuestionText>
            </Question>

            <AnimateHeight duration={500} height={faq.id === toogle ? 'auto' : 0}>
              <Answer dangerouslySetInnerHTML={{__html: sanitizeHtml(faq.answer)}} />
            </AnimateHeight>
          </Wrap>
        ))}
    </Container>
  )
}

Faq.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default Faq
