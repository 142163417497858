import React from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row} from 'reactstrap'
import PartnerItem from './PartnerItem'

const Partners = ({content}) => {
  const partners = content['hydra:member'] || []
  return (
    <Container>
      <Row>
        {partners.map((partner) => (
          <Col key={partner['@id']} xs={12} sm={6} md={4} lg={3}>
            <PartnerItem partner={partner} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

Partners.propTypes = {
  content: PropTypes.object.isRequired
}
export default Partners
