import React, {useEffect, useRef, useState} from 'react'
import {MenuFooter} from './styled'
import ThemesMenu from '../Menu/_partials/ThemesMenu'
import PartnersMenu from '../Menu/_partials/PartnersMenu'
import {getGroupPartners, getThemes} from '../../services/endpoints/contents/contents'

const MenuFooterAutomatic = () => {
  const isMounted = useRef(true)
  const [contentsPartners, setContentsPartners] = useState([])
  const [contentsTheme, setContentsTheme] = useState([])
  const menuSession = JSON.parse(sessionStorage.getItem('menus'))

  useEffect(() => {
    if (isMounted.current) {
      (async () => {
        try {
          if (!menuSession) {
            const responsePartners = await getGroupPartners()
            const responseTheme = await getThemes()
            setContentsPartners(responsePartners.data['hydra:member'] || [])
            setContentsTheme(responseTheme.data['hydra:member'] || [])
          } else {
            setContentsPartners(menuSession.partners)
            setContentsTheme(menuSession.themes)
          }
        } catch (e) {
          console.error('Menu.useEffect', e)
        }
      })()
    }
    return () => {
      isMounted.current = false
    }
  }, [menuSession])
  return (
    <MenuFooter>
      <ThemesMenu contents={contentsTheme} />
      <PartnersMenu contents={contentsPartners} />
    </MenuFooter>
  )
}

export default MenuFooterAutomatic
