import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {hasProperty} from '../../utils/functions'
import PartnerItem from '../../components/Partners/PartnerItem'
import {getGroupPartners} from '../../services/endpoints/contents/contents'
import {comparar} from '../../utils/masks'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'

const Partners = () => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('parceiros')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const [loading, setLoading] = useState(true)
  const [contents, setContents] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const response = await getGroupPartners()
        const {data} = response

        setContents(data['hydra:member'] || [])
      } catch (error) {
        console.error('loadContents.loadContents', error)
      }
      setLoading(false)
    })()
    return () => {
      setLoading(true)
    }
  }, [])

  if (loading) {
    return null
  }

  return (
    <Container className="pb-5">
      {contents.map((content) => (
        <Items key={content['@id']}>
          <Title to={`/parceiros/${btoa(content['@id'])}`}>{content.name}</Title>

          {hasProperty(content, 'partners') && content.partners.length ? (
            <Row>
              {content.partners.sort(comparar).map((partner) => (
                <Col key={partner['@id']} xs={12} sm={6} md={4} lg={3}>
                  <PartnerItem partner={partner} />
                </Col>
              ))}
            </Row>
          ) : (
            <p>Sem conteúdos</p>
          )}
        </Items>
      ))}
    </Container>
  )
}

const Items = styled.div`
  margin-bottom: 30px;
`

const Title = styled(Link)`
  color: #002c63;
  font-size: 25px;
  line-height: 120%;
  font-weight: 400;
  margin-bottom: 15px;
  text-decoration: none;
  display: inline-block;
`

export default Partners
