import styled from 'styled-components'

export const BurguerMenu = styled.div`
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
  text-align: right;
  /* font-size: 30px; */
  color: #fff;
  margin-top: 20px;
  /* text-align: right; */
  border: 1px solid #fff;
  padding: 5px 13px;
  border-radius: 99px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  max-width: 104px;
  position: absolute;
  top: 0;
  right: 15px;
  @media (min-width: 576px) {
    display: none;
  }
`
export const ButtonMobile = styled.button`
  background: none;
  border: none;
  padding: 0;
`
export const LinkNotification = styled.span`
  i {
    &.has:after {
      background-color: #fb7c48;
      right: -10px;
      @media (max-width: 1200px) {
        top: -15px;
      }
    }
    color: #ffd861;
    font-size: 16px;
  }
`
export const FloatingButton = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    display: flex;
    align-items: center;
    i {
      margin-left: 5px;
      font-size: 18px;
    }
  }
`

export const BtnClose = styled.i`
  width: 20px;
  height: 20px;
  background: #666e7a;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Navbar = styled.nav`
  position: relative;
  z-index: 99;
  width: 100%;
  @media (max-width: 767px) {
    position: unset;
  }
  .colapse-mobile {
    display: flex;
    flex-wrap: wrap;
  }

  .colapse-mobile {
    & .btn-notification.active:before {
      left: -3px;
    }
    & .active {
      &:before {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        background-color: #00b0e6;
        left: -5px;
      }
      .arrow {
        transform: rotate(90deg);
      }
    }
    &.profile {
      a {
        background: none;
        border: none;
        position: relative;
        color: #ffffff;
        text-decoration: none;
        padding: 10px;
        font-size: 15px;
        font-weight: 600;
        font-family: Muli, sans-serif;
        display: flex;
        align-items: center;
        min-height: 40px;
        color: #ffd861;
      }
      .arrow {
        color: #ffd861;
      }
    }
    .btn-notification {
      background: transparent;
      border: none;
      position: absolute;
      right: 0;
      top: 12px;
    }
    .btn-notification a {
      .has:after {
        background-color: #fb7c48;
        right: -10px;
        @media (max-width: 1200px) {
          top: -15px;
        }
      }
      color: #ffd861;
      i {
        font-size: 16px;
      }
    }
    & .arrow {
      transition: ease all 300ms;
      background: transparent;
      border: none;
      color: #fff;
      position: absolute;
      left: 50%;
      font-size: 20px;
      padding: 0 20px;
      transition: ease all 400ms;
    }
  }
  .w-100 {
    width: 100%;
  }
  .notification-mobile {
    position: relative;
  }
  .position-relative {
    position: unset;
    padding: 0 0px 0 5px;
  }
  .position-relative {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
  }
`

export const NavMenu = styled.nav`
  ul {
    list-style: none;
    padding: 0;
  }
  @media (min-width: 576px) {
    > div {
      height: auto !important;
      overflow: inherit !important;
      > div {
        display: block !important;
      }
    }
  }
  > div > div > ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (min-width: 576px) {
      flex-direction: row;
    }
    > li {
      position: relative;
      a,
      .a {
        background: none;
        border: none;
        position: relative;
        color: #ffffff;
        text-decoration: none;
        @media (min-width: 992px) {
          padding: 10px 25px;
        }
        font-size: 15px;
        font-weight: 600;
        font-family: Muli, sans-serif;
        display: flex;
        align-items: center;
        min-height: 40px;
        transition: all 0.3s ease;
        margin-bottom: 10px;
        &:hover {
          background: rgba(0, 176, 230, 0.08);
          border-radius: 3px;
        }
        .icon-user {
          margin-right: 5px;
          position: relative;
          font-size: 14px;
        }
        > i {
          &.fa-angle-down {
            display: none;
          }
          @media (min-width: 576px) {
            &.fa-angle-down {
              display: inline-block;
            }
            margin-left: 8px;
          }
          font-size: 20px;
          line-height: 0.6;
        }
      }
      &:hover > ul {
        display: block;
      }
      ul {
        padding-left: 15px;
        @media (min-width: 576px) {
          border-radius: 3px;
          padding-left: 0;
          display: none;
          position: absolute;
          width: 100%;
          min-width: 220px;
          max-width: 220px;
          max-height: 340px;
          overflow: auto;
          border: 1px solid #e3e5e7;
          background-color: #ffffff;
          box-shadow: 0 6px 6px rgba(102, 110, 122, 0.1);
          color: #003475;
          z-index: 2;
        }
        li {
          a {
            @media (min-width: 576px) {
              color: #003475;
              &:hover {
                background-color: #003475;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
`
