import styled from 'styled-components'

export const Header = styled.header`
  background-color: #002c63;
  .logo {
    display: flex;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      margin-bottom: 0;
      width: 50%;
      flex: 0 0 50%;
    }
    @media (min-width: 992px) {
      margin-right: 40px;
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      flex-basis: inherit;
    }
  }
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 992px) {
    flex-wrap: inherit;
  }
  align-items: center;
  padding: 5px 0 10px;
  &.logo-mobile {
    align-items: flex-start;
  }
`

export const Copyright = styled.div`
  color: #ffffff;
  font-size: 14px;
`
