import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Spinner} from 'reactstrap'
import {
  getContentDownload,
  postContentView,
  postContentDownload
} from '../../../services/endpoints/contents/contents'
import {buildImageUrl} from '../../../utils/buildImageUrl'
import {getToken} from '../../../utils/auth'

const ContentFile = ({content, currentWatched, setCurrentWatched}) => {
  const [loading, setLoading] = useState(false)
  const RenderImage = () => {
    if (content.cover) {
      return <ImageResponsive src={buildImageUrl(content.cover.url, 930, 650)} />
    }
    if (content.thumb) {
      return <ImageResponsive src={buildImageUrl(content.thumb.url, 930, 650)} />
    }
    return null
  }

  const getDownload = async (id, name) => {
    await getContentDownload(id, name)
  }

  const handleClick = async () => {
    if (!currentWatched && getToken()) {
      try {
        await postContentView({
          content: content['@id'],
          percent: 100,
          progress: 1
        })

        setCurrentWatched()
      } catch (e) {
        console.error('ContentFile.handleClick.postContentView', e)
      }
    }

    setLoading(true)
    if (getToken()) {
      await postContentDownload({
        content: `/v1/ebooks/${content.id}`
      })
    }
    setLoading(true)

    await getDownload(content.id, content.title)
    if (getToken()) {
      await postContentDownload({
        content: `/v1/ebooks/${content.id}`
      })
    }
    setLoading(false)
  }

  return (
    <AdjustImage>
      <RenderImage />
      {content.file && (
        <AlignCenter>
          {loading ? (
            <Spinner />
          ) : (
            <ContentFileLink onClick={() => handleClick()}>
              <i className="fal fa-file-download" />
              Clique aqui para realizar download
            </ContentFileLink>
          )}
        </AlignCenter>
      )}
    </AdjustImage>
  )
}

const AdjustImage = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 41%;
  margin-bottom: 30px;
`

const ImageResponsive = styled.img`
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const AlignCenter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`

const ContentFileLink = styled.a`
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  i {
    font-size: 50px;
    margin-bottom: 20px;
  }
`

ContentFile.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currentWatched: PropTypes.bool.isRequired,
  setCurrentWatched: PropTypes.func.isRequired
}
export {ContentFile}
