import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import queryString from 'query-string'
import Pagination from 'react-js-pagination'
import Spinner from 'reactstrap/es/Spinner'
import {getSearchGlobal} from '../../services/endpoints/general/search'
import {ContentItem} from '../../components/Contents'
import {SearchTitle, TitleHeader} from './styled'
import FilterBar from '../Contents/_partials/FilterBar'
import {getEndpoints} from '../../services/endpoints/getEndpoints'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'

const Search = ({location, history}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const [loading, setLoading] = useState(true)
  const {search} = location
  const {query = '', format = '', parceiro = '', page} = queryString.parse(search)
  const {perPage} = settings
  const [totalItens, setTotalItens] = useState(0)
  const currentPage = page ? parseInt(page, 10) : 1
  const [contents, setContents] = useState([])

  useEffect(() => {}, [])

  useEffect(() => {
    (async () => {
      try {
        const parameters = {
          perPage,
          page: currentPage
        }

        if (format) {
          Object.assign(parameters, {
            resourceType: format
          })
        }
        if (parceiro) {
          try {
            const resPartner = await getEndpoints(`/v1/partners/slug/${parceiro}`)
            const dataPartner = resPartner.data || {}
            Object.assign(parameters, {
              partner: dataPartner.id
            })
          } catch (err) {
            console.warn('Search.useEffect.partnerId invalid', err)
          }
        }

        const response = await getSearchGlobal(query, parameters)
        const {data} = response
        data.contents = data.contents.concat(data.courses || [])
        setContents(data.contents || [])
        setTotalItens(data.totalContents || 0)
      } catch (e) {
        console.error('Search.loadContents', e)
      }
      window.scrollTo(0, 0)
      setLoading(false)
    })()
    return () => {
      setLoading(true)
    }
  }, [query, format, parceiro, currentPage])

  const handlePageChange = (pageNumber) => {
    let filter = queryString.parse(search)
    if (filter && pageNumber) {
      filter = Object.assign(filter, {
        page: pageNumber
      })
    }
    history.push({
      search: queryString.stringify(filter)
    })
    window.scrollTo(0, 0)
  }

  return (
    <Container className="pb-5">
      <TitleHeader>Resultado completo:</TitleHeader>

      <FilterBar by="parceiros" path="/busca" />

      <SearchTitle>{query}</SearchTitle>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {contents.length ? (
            <Row>
              {contents.map((content) => (
                <Col key={content['@id']} xs={12} sm={6} md={4} lg={3}>
                  <ContentItem content={content} />
                </Col>
              ))}
            </Row>
          ) : (
            <p>Nenhum resultado foi encontrado</p>
          )}
        </>
      )}

      {contents.length > 0 && totalItens > perPage && (
        <Pagination
          {...settings.pagination}
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={totalItens}
          onChange={handlePageChange}
        />
      )}
    </Container>
  )
}

export default Search
