/* eslint-disable consistent-return */
import React, {useEffect, useState} from 'react'
import {Container} from 'reactstrap'
import Spinner from 'reactstrap/es/Spinner'
import {Helmet} from 'react-helmet'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'
import {getTheme} from '../../services/endpoints/contents/contents'
import {makeContent} from './data/makeContent'
import {getToken} from '../../utils/auth'
import {loadRelatedContents} from './utils/loadRelatedContents'
import {initialContentParams} from './utils/initialContentParams'
import {ContentTitle} from '../../components/Contents/_partials/styled'
import Breadcrumb from '../../components/Breadcrumb'
import ContentShowByType from './_contents/ContentShowByType'
import {getEndpoints} from '../../services/endpoints/getEndpoints'
import {makeContentFree} from './data/makeContentFree'
import {decodeHtml} from '../../utils/santinize/decodeHtml'
import {getImageHeader} from '../../utils/getImageHeader'

const ContentShowByTheme = ({match, history}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()
  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const {subcategory, slug} = match.params
  const [theme, setTheme] = useState({})
  useEffect(() => {
    (async () => {
      try {
        const response = await getTheme(subcategory)
        setTheme(response.data || {})
      } catch (e) {
        console.error('ContentShowByTheme.useEffect.getTheme', e)
        history.push('/404')
      }
    })()
  }, [subcategory, history])

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState({})
  const [relatedContents, setRelatedContents] = useState([])
  const [preferences, setPreferences] = useState(initialContentParams)

  const mountPreferences = async () => {
    setPreferences(initialContentParams)
    const contentShow = await makeContent(slug)
    const {params, data} = contentShow
    setPreferences({...preferences, ...params})

    return {params, data}
  }

  useEffect(() => {
    (async () => {
      try {
        const {data} = await mountPreferences()

        setContent(data || {})
        setRelatedContents(getToken() ? await loadRelatedContents(data.id) : [])
        setLoading(false)
      } catch (e) {
        if (e.response && e.response.status === 404) {
          history.push('/404')
        }
        if (e.response && (e.response.status === 401 || e.response.status === 500)) {
          try {
            const data = await makeContentFree()
            if (data) {
              setContent(data)
            } else {
              history.push('/404')
            }
            setLoading(false)
          } catch (error) {
            console.error('erro ao buscar conteudo privado')
            history.push('/404')
            return error
          }
        }
        console.error('ContentShow.loadContent.getContents', e)
      }
    })()
  }, [slug, history])

  const [playlist, setPlaylist] = useState([])
  useEffect(() => {
    (async () => {
      try {
        const response = await getEndpoints('/v1/contents', {
          'exists[themes]': true,
          themes: theme.id
        })
        const data = response.data['hydra:member'] || []
        setPlaylist(data)
      } catch (e) {
        console.error('ContentShow.useEffect.getPlaylist', e)
        setPlaylist([])
      }
    })()
  }, [theme])

  return (
    <Container className="pb-5">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{decodeHtml(content.title)}</title>
            <meta property="og:title" content={decodeHtml(content.title)} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={getImageHeader(content)} />
            <meta property="twitter:card" content="summary_large_image" />
          </Helmet>

          <ContentTitle>{decodeHtml(content.title)}</ContentTitle>
          <Breadcrumb
            contents={[
              {title: 'Para você', url: '/para-voce'},
              {title: decodeHtml(theme.title), url: `/para-voce/${theme.slug}`},
              {title: decodeHtml(content.title)}
            ]}
          />
          <ContentShowByType
            content={content}
            relatedContents={relatedContents}
            preferences={preferences}
            playlist={playlist}
            updatePreferences={mountPreferences}
          />
        </>
      )}
    </Container>
  )
}

export default ContentShowByTheme
