import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const TitleHeader = styled.h1`
  margin-top: 45px;
  &:after {
    display: none;
  }
`

export const SearchTitle = styled.div`
  margin-bottom: 30px;
  color: #ffd862;
  font-size: 34px;
  position: relative;
  font-weight: bold;
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    left: 0;
    bottom: -10px;
    background-color: #ffd862;
    height: 2px;
  }
`

export const ContentItem = styled.div`
  margin-bottom: 30px;
`

export const Title = styled(Link)`
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
  display: inline-block;
  transition: color 300ms ease;
  text-decoration: none;
  &:hover {
    color: #002c63;
  }
`

export const Description = styled.div``
