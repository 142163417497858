import React from 'react'
import PropTypes from 'prop-types'
import Components from './ComponentList'

const ComponentFactory = ({componentName, content, block}) => {
  const ComponentToRender = Components[componentName]

  if (ComponentToRender) {
    return <ComponentToRender content={content} block={block} />
  }
  return null
}

ComponentFactory.propTypes = {
  componentName: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  block: PropTypes.oneOfType([PropTypes.object])
}

export default ComponentFactory
