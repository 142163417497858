import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import moment from 'moment'
import momentPT from 'moment/locale/pt-br'
import {useHistory} from 'react-router-dom'
import {seeNotification} from '../../services/endpoints/notifications/notifications'
import {LinkNotification} from '../Menu/styled'
import {makeUrlContent} from '../../pages/Contents/data/makeUrlContent'
import {decodeHtml} from '../../utils/santinize/decodeHtml'

const BlockNotifications = ({contents, screenType, open, setOpen, removeNotification}) => {
  moment.updateLocale('pt-br', momentPT)
  const history = useHistory()

  const handleClick = async (id, content) => {
    removeNotification(id)
    const url = makeUrlContent(content)

    try {
      await seeNotification(id)
      history.push(url)
    } catch (e) {
      window.alert('Ocorreu um erro ao visualizar o conteúdo')
    }
  }

  if (!contents) {
    return null 
  }

  return (
    <>
      {!screenType && (
        <Li>
          <span className="a">
            <I
              count={contents.length || 0}
              className={`far fa-bell${contents.length ? ' has' : ''}`}
            />
          </span>
          <Items>
            {contents.length ? (
              contents.map((content) => (
                <Item key={content['@id']} onClick={() => handleClick(content.id, content.content)}>
                  <Title title={content.content.title}>{decodeHtml(content.content.title)}</Title>
                  <Date>
                    {moment(content.content.publishAt, 'YYYY MM DD').calendar().includes('Hoje')
                      ? 'Hoje'
                      : moment(content.content.publishAt, 'YYYY MM DD').fromNow()}
                  </Date>
                </Item>
              ))
            ) : (
              <li>
                <p>Você não tem notificações até o momento.</p>
              </li>
            )}
          </Items>
        </Li>
      )}
      {screenType === 'mobile' && (
        <li className="colapse-mobile">
          <div className="position-unset">
            <button
              type="button"
              onClick={() => {
                setOpen(() => (open === 'notify' ? false : 'notify'))
              }}
              className={`btn-notification ${open === 'notify' ? 'active' : ''}`}>
              <LinkNotification>
                <I
                  count={contents.length || 0}
                  className={`far fa-bell${contents.length ? ' has' : ''}`}
                />
              </LinkNotification>
            </button>
          </div>
          <AnimateHeight duration={500} className="w-100" height={open === 'notify' ? 'auto' : 0}>
            <Items>
              {contents.length ? (
                contents.map((content) => (
                  <Item
                    key={content['@id']}
                    onClick={() => handleClick(content.id, content.content)}>
                    <Title title={content.content.title}>{content.content.title}</Title>
                    <Date>{moment(content.createdAt, 'YYYY MM DD').fromNow()}</Date>
                  </Item>
                ))
              ) : (
                <li>
                  <p>Você não tem notificações até o momento.</p>
                </li>
              )}
            </Items>{' '}
          </AnimateHeight>
        </li>
      )}
    </>
  )
}

const Items = styled.ul`
  right: 0;
  @media (max-width: 575px) {
    max-height: 200px;
    overflow: scroll;
    background: #00376d;
  }

  p {
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    color: #ffffff;
    @media (min-width: 576px) {
      color: #003475;
    }
  }
`

const Item = styled.li`
  padding: 5px 10px;
  cursor: pointer;
  color: #ffffff;
  @media (min-width: 576px) {
    color: #003475;
  }
  &:hover {
    background-color: #003475;
    color: #ffffff;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Date = styled.div`
  font-size: 12px;
`

const Li = styled.li`
  & span.a {
    &:hover {
      background: transparent !important;
      i {
        background: rgb(0 176 230 / 11%);
        border-radius: 3px;
      }
    }
  }
  @media (min-width: 1200px) {
    .a {
      padding: 0px 10px 0px 45px !important;
      i {
        padding: 14px;
      }
    }
  }
  a {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
`

const I = styled.i`
  margin-right: 5px;
  position: relative;
  font-size: 14px;
  &.has:after {
    content: ${(props) => `'${props.count}'`};
    position: absolute;
    color: #002c63;
    width: 20px;
    height: 20px;
    background: #ffd862;
    border-radius: 50%;
    top: -2px;
    right: 7px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    font-family: Muli, sans-serif;
    @media (max-width: 1200px) {
      top: -16px;
      right: -11px;
    }
  }
`

BlockNotifications.propTypes = {
  contents: PropTypes.array.isRequired,
  screenType: PropTypes.string,
  removeNotification: PropTypes.func
}

export default BlockNotifications
