import styled from 'styled-components'

export const MenuFooter = styled.ul`
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  justify-content: flex-end;
  > li {
    width: 100%;
    flex: 0 0 100%;
    padding: 0 5px;
    margin-bottom: 15px;
    @media (min-width: 576px) {
      flex: 0 0 33.33333%;
      width: 33.33333%;
    }
    > a,
    > .a {
      background: none;
      border: none;
      padding: 0;
      color: #00b0e6;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.5px;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 10px;
      text-decoration: none;
      i {
        display: none;
      }
    }
    > ul {
      padding-left: 0;
      list-style: none;
      li {
        a {
          display: inline-block;
          padding: 5px 0;
          font-size: 12px;
          line-height: 120%;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
`
