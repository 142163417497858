import styled from 'styled-components'

export const ContactForm = styled.div`
  .element-field {
    margin-bottom: 15px;

    .label-title {
      font-weight: 300;
      display: block;
      margin-bottom: 5px;
    }

    .form-control:not([type='radio']) {
      font-family: Muli, sans-serif;
      border: 0;
      outline: none;
      border-bottom: 1px solid #00b0e6;
      background: #eee;
      font-size: 14px;
      padding: 8px 12px;
      display: block;
      width: 100%;
      &:not(textarea) {
        height: 38px;
      }
      &.form-textarea {
        height: 100px;
        border: 1px solid #00b0e6;
      }
    }

    span {
      color: rgba(255, 0, 0, 0.56);
      font-size: 12px;
    }
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;

    button {
      height: 46px;
      padding: 0;
      border-radius: 30px;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 600;
      margin: 0 7px;
      cursor: pointer;
      transition: all 0.3s linear;
      outline: none;
      text-decoration: none;
      border: 1px solid #ffd862;
      background: #ffd862;
      color: #002c63;
      &:hover {
        color: #002c63;
        border-color: rgba(253, 218, 112, 0.7);
        background-color: rgba(253, 218, 112, 0.8);
      }
    }
  }
`
