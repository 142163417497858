import React from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import toSlugCase from 'to-slug-case'
import {getType, getTitleByType} from '../../../components/Contents/Enum/types'
import {hasProperty} from '../../../utils/functions'
import {getImagePlaylistItem} from '../../../utils/buildImageUrl'
import {Image, Item, MetaInfo, Title, Type, Watched} from './PlaylistStyled'
import {loadPublicImage} from '../../../utils/loadPublicImage'

const Playlist = ({content, playlist}) => {
  const {location} = useHistory()

  const hasWatched = (current) => {
    const userCollectionView =
      current.userCollections.filter((item) => item['@type'] === 'UserCollectionView')[0] || {}
    if (hasProperty(userCollectionView, 'viewed')) {
      return userCollectionView.viewed
    }
    return false
  }

  return playlist.map((item) => (
    <Item
      key={item['@id']}
      className={content.id === item.id ? 'active' : ''}
      to={{
        pathname: `/conteudos/${toSlugCase(getType(item['@type']))}/${item.slug}`,
        search: location.search || '',
        state: {
          playlist: location.state ? location.state.playlist || '' : ''
        }
      }}>
      {item.thumb ? (
        <Image src={getImagePlaylistItem(item.thumb.url)} alt={item.title} />
      ) : (
        <Image src={loadPublicImage('/no-course.jpg')} alt={item.title} />
      )}
      {hasWatched(item) && <Watched className="far fa-check" title="Conteúdo já visto" />}
      <MetaInfo>
        <Title title={item.title}>{item.title}</Title>
        <Type>{getTitleByType(item['@type'])}</Type>
      </MetaInfo>
    </Item>
  ))
}

Playlist.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  playlist: PropTypes.array.isRequired
}
export default Playlist
