import fileDownload from 'js-file-download'
import api from '../../api'
import noToken from '../../noToken'

export const getContents = (endpoint = '/contents', params = {}) => {
  return api.get(endpoint, {
    params: {
      'exists[theme]': true,
      ...params
    }
  })
}

export const getContent = (slug) => {
  return api.get(`/contents/slug/${slug}`, {
    params: {}
  })
}

export const setContentAccess = (body) => {
  return api.post(`/user_collection_accesses`, {
    ...body
  })
}
export const checkCourse = (id) => {
  return api.get(`/courses/${id}/check_course`, {
    params: {}
  })
}
export const checkExam = (id) => {
  return api.get(`/contents/${id}/check_exam`, {
    params: {}
  })
}

export const getRelatedContents = (idContent, params = {perPage: 20}) => {
  return api.get(`/contents/${idContent}/related_contents`, {
    params: {...params}
  })
}

export const getCourseLessons = (idContent, params = {}) => {
  return api.get(`/courses/${idContent}/lessons`, {
    params: {...params}
  })
}

export const getFreeCourseLessons = (idContent, params = {}) => {
  return noToken.get(`/courses/${idContent}/lessons`, {
    params: {...params}
  })
}

export const getExam = (idContent) => {
  return api.get(`/contents/${idContent}/exam`, {
    params: {}
  })
}

export const postFavorite = (params = {}) => {
  return api.post('/user_collection_favorites', params)
}

export const postRatings = (params = {}) => {
  return api.post('/user_collection_ratings', params)
}

export const getTrails = (params, extraHeaders = {}) => {
  return api.get('/trails', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'desc',
      ...params
    }
  })
}

export const getGroupPartners = (params, extraHeaders = {}) => {
  return api.get('/partner_groups', {
    ...extraHeaders,
    params: {
      ...params
    }
  })
}

export const getGroupPartner = (slug, params) => {
  return api.get(`/partner_groups/slug/${slug}`, {
    params: {
      ...params
    }
  })
}

export const getPartners = (params, extraHeaders = {}) => {
  return api.get('/partners', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'desc',
      ...params
    }
  })
}

export const getPartner = (slug, params) => {
  return api.get(`/partners/slug/${slug}`, {
    params: {
      ...params
    }
  })
}

export const getTags = (params, extraHeaders = {}) => {
  return api.get('/tags', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'asc',
      ...params
    }
  })
}

export const getThemes = (params, extraHeaders = {}) => {
  return api.get('/themes', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'asc',
      ...params
    }
  })
}

export const getTheme = (slug, params) => {
  return api.get(`/themes/slug/${slug}`, {
    params: {
      active: true,
      ...params
    }
  })
}

/*
 * @params progress
 * @params percent
 * @params content
 * @params attachment
 * */
export const postContentView = (params = {}) => {
  return api.post('/user_collection_views', params)
}
export const postContentDownload = (params = {}) => {
  return api.post('/user_collection_downloads', params)
}
export const getMeExam = (idContent) => {
  return api.get(`/current_customer_content_exam_application?content=${idContent}`)
}

export const getCertified = () => {
  return api.get('/current_customer_certified_contents')
}

export const getContentCertifiedDownload = (ContentId) => {
  return api.get(`/contents/${ContentId}/download_certificate`)
}

export const getCertifiedDownload = (id) => {
  // return api.get(`/current_customer_certified_contents/${id}/download`);
  const getExtension = (contentType) => {
    return contentType.split('/')[1]
  }
  return api
    .get(`${process.env.REACT_APP_API_URL}/contents/${id}/download_certificate`, {
      responseType: 'arraybuffer' // important
    })
    .then((response) => {
      fileDownload(response.data, `Certificado.${getExtension(response.headers['content-type'])}`)
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getAttachmentDownload = (id, name) => {
  // return api.get(`/current_customer_certified_contents/${id}/download`);
  const getExtension = (contentType) => {
    switch (contentType) {
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx'
      case 'application/excel':
        return 'xls'
      case 'application/pdf':
        return 'pdf'
      case 'application/x-photoshop':
        return 'psd'

      default:
        return contentType.split('/')[1]
    }
  }
  return api
    .get(`${process.env.REACT_APP_API_URL}/attachments/${id}/download`, {
      responseType: 'arraybuffer' // important
    })
    .then((response) => {
      fileDownload(response.data, `${name}.${getExtension(response.headers['content-type'])}`)
      // window.open(url, '_blank');
    })
}
export const getContentDownload = (id, name) => {
  // return api.get(`/current_customer_certified_contents/${id}/download`);
  const getExtension = (contentType) => {
    return contentType.split('/')[1]
  }
  return api
    .get(`${process.env.REACT_APP_API_URL}/contents/${id}/download`, {
      responseType: 'arraybuffer' // important
    })
    .then((response) => {
      fileDownload(response.data, `${name}.${getExtension(response.headers['content-type'])}`)
    })
}

/*
 * Exemplo de uso
 * {
 *    "content": "/v1/contents/3",
 *    "examApplicationAnswers": [
 *      {
 *        "question": "/v1/exam_questions/1",
 *        "alternative": "/v1/exam_question_alternatives/1"
 *      },
 *    ]
 * }
 * */
export const postExamResolutions = (params = {}) => {
  return api.post('/exam_application_resolutions', params)
}
