import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../components/Body/contexts/PageContext'
import {settings} from '../config/settings'

const Pages = ({match}) => {
  const {slug = settings.home} = match.params
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage(slug)
    setHideBreadcrumb(slug === settings.home)
  }, [slug, setPageBase, setCurrentPage, setHideBreadcrumb])

  return null
}

Pages.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object])
}

export default Pages
