import React, {useState} from 'react'
import MaskedInput from 'react-input-mask'
import {Field} from 'formik'
import {makeMask} from './util/makeMask'

const MakeField = ({actions, type, ...el}) => {
  const [show, setShow] = useState(false)
  switch (type) {
    case 'div':
      return <div className="form-control">{el.value}</div>
    case 'select':
      if (el.dependentField) {
        return (
          <Field
            as="select"
            className="form-control form-select"
            name={el.name}
            id={el.name}
            placeholder={el.hasPlaceholder ? el.label : ''}
            onChange={async (e) => {
              const {value} = e.target
              actions.setFieldValue(el.name, value)
              el.dependentField(value)
              if (el.onChange) {
                el.onChange(value)
              }
            }}>
            {el.options.map((option, index) => (
              <option key={`option-${option.value}-${index}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
        )
      }

      return (
        <Field
          as="select"
          className="form-control form-select"
          name={el.name}
          id={el.name}
          onInput={(val) => {
            if (el.onChange) {
              el.onChange(val.target.value)
            }
          }}
          placeholder={el.hasPlaceholder ? el.label : ''}>
          {el.options.map((option, index) => (
            <option key={`option-${option.value}-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
      )
    case 'textarea':
      return (
        <Field
          as="textarea"
          onInput={(val) => {
            if (el.onChange) {
              el.onChange(val.target.value)
            }
          }}
          className="form-control form-textarea"
          name={el.name}
          id={el.name}
          placeholder={el.hasPlaceholder ? el.label : ''}
        />
      )
    case 'password':
      return (
        <div style={{position: 'relative'}}>
          <Field
            type={show ? 'text' : 'password'}
            className="form-control"
            name={el.name}
            onInput={(val) => {
              if (el.passwordValidation) {
                el.passwordValidation(val.target.value)
              }
            }}
            onFocus={() => {
              if (el.onFocus) {
                el.onFocus()
              }
            }}
            onBlur={() => {
              if (el.onBlur) {
                el.onBlur()
              }
            }}
            id={el.name}
            placeholder={el.hasPlaceholder ? el.label : ''}
            maxLength={el.maxLength || 60}
          />
          {el.showButtonViewPassword && (
            <button
              type="button"
              // role="button"
              tabIndex="0"
              aria-label="Exibir conteúdo"
              className={show ? 'far fa-eye' : 'far fa-eye-slash'}
              // onMouseDown={() => setShow(true)}
              // onMouseUp={() => setShow(false)}
              // onMouseOut={() => setShow(false)}
              onClick={() => setShow((prev) => !prev)}
              // onBlur={() => setShow(false)}
              style={{
                position: 'absolute',
                top: 0,
                right: 20,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#676767',
                background: 'transparent',
                border: 'none'
              }}
            />
          )}
        </div>
      )
    default:
      if (el.mask) {
        return (
          <Field name={el.name} element={el}>
            {({field}) => {
              return (
                <MaskedInput
                  {...field}
                  id={el.name}
                  className="form-control"
                  placeholder={el.hasPlaceholder ? el.label : ''}
                  maskChar=""
                  onInput={(val) => {
                    if (el.onChange) {
                      el.onChange(val.target.value)
                    }
                  }}
                  mask={makeMask(field.value, el.mask, el.maskType)}
                />
              )
            }}
          </Field>
        )
      }

      return (
        <Field
          type={type || 'text'}
          className="form-control"
          name={el.name}
          id={el.name}
          onInput={(val) => {
            if (el.onChange) {
              el.onChange(val.target.value)
            }
          }}
          placeholder={el.hasPlaceholder ? el.label : ''}
        />
      )
  }
}

export default MakeField
