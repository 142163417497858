import React from 'react'
import PropTypes from 'prop-types'
import {ButtonSyled} from './styled'

const Button = ({color, children, type, ...props}) => {
  return (
    <ButtonSyled color={color || ''} as={type || 'button'} {...props}>
      {children}
    </ButtonSyled>
  )
}

Button.propTypes = {
  color: PropTypes.string
}

export {Button}
