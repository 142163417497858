import React, {memo} from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import '../../assets/styles/sass/components/banner.scss'
import {BannerStyled, Item, NextArrowStyled, PrevArrowStyled} from './styled'
import BannerItem from './BannerItem'
import {sha256} from '../../utils/utilities'
import {buildImageUrl} from '../../utils/buildImageUrl'

const Banner = ({content}) => {
  const factoryBanner = (banner, image, index) => ({
    id: sha256(`id-${banner.id}-${index}`),
    title: image.title ? image.title : '',
    description: image.description ? image.description : '',
    link: image.link || '',
    action: image.action || '_self',
    image: image.image
      ? buildImageUrl(image.image.url, banner.width || 1920, banner.height || 960)
      : null,
    interval: image.transitionTime || 4000,
    activeFrom: image.activeFrom,
    activeUntil: image.activeUntil,
    textButton: image.textButton || null,
    mobileImage: image.mobileImage ? buildImageUrl(image.mobileImage.url, 576, 960) : null
  })

  const bannersFactory = () => {
    if (Object.keys(content.images).length) {
      const list = []
      Object.keys(content.images).map((key) => list.push(content.images[key]))

      return list
        .sort((a, b) => {
          if (a.position < b.position) {
            return -1 
          }
          if (a.position > b.position) {
            return 1 
          }
          return 0
        })
        .map((item, index) => {
          return factoryBanner(content, item, index)
        })
    }
    return []
  }

  const PrevArrow = ({onClick}) => {
    return <PrevArrowStyled className="fal fa-angle-left" onClick={onClick} />
  }

  const NextArrow = ({onClick}) => {
    return <NextArrowStyled className="fal fa-angle-right" onClick={onClick} />
  }

  const getIntervals = () => {
    const banners = bannersFactory()
    const intervals = []

    banners.forEach((banner) => {
      intervals.push(banner.interval)
    })

    return intervals
  }

  const intervals = getIntervals()
  return (
    <BannerStyled>
      <Slider
        dots
        infinite={intervals.length > 1}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        speed={500}
        draggable={false}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        fade
        autoplay>
        {bannersFactory().map((banner) => (
          <Item key={`banner-${banner.id}`}>
            <BannerItem banner={banner} />
          </Item>
        ))}
      </Slider>
    </BannerStyled>
  )
}

Banner.propTypes = {
  content: PropTypes.object.isRequired
}

export default memo(Banner)
