import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import {useHistory} from 'react-router-dom'
import {getTitleByType} from '../../../components/Contents/Enum/types'
import {Label, Select, SelectGroup} from './styled'

const SelectFormats = ({setFieldValue}) => {
  const history = useHistory()
  const params = queryString.parse(history.location.search)
  const filters = [
    'Video',
    // 'Webinar',
    'Article',
    'Podcast',
    'Ebook',
    'Infographic',
    // 'VideoConference',
    'Course'
  ]

  return (
    <SelectGroup>
      <Label>Selecione</Label>
      <Select type="select" onChange={setFieldValue} name="format" defaultValue={params.format}>
        <option value="">Formato</option>
        {filters.map((value) => (
          <option key={value} value={value}>
            {getTitleByType(value)}
          </option>
        ))}
      </Select>
    </SelectGroup>
  )
}

SelectFormats.propTypes = {
  setFieldValue: PropTypes.func.isRequired
}

export default SelectFormats
