import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import Pagination from 'react-js-pagination'
import queryString from 'query-string'
import Truncate from 'react-truncate'
import {settings} from '../../config/settings'
import {hasProperty} from '../../utils/functions'
import {getTools} from '../../services/endpoints/tools/tools'
import {Description, Item, LinkImage, MetaInfo, Title} from './styled'
import {Image} from '../../components/Contents/_partials/styled'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {loadPublicImage} from '../../utils/loadPublicImage'

const Tools = ({history, location}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('vitrine-de-ferramentas')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const [contents, setContents] = useState([])
  const params = queryString.parse(location.search)
  const {perPage} = settings
  const [loading, setLoading] = useState(true)
  const [totalItens, setTotalItens] = useState(0)
  const currentPage = hasProperty(params, 'page') ? parseInt(params.page, 10) : 1

  const loadContents = useCallback(async () => {
    try {
      const response = await getTools({
        perPage,
        page: currentPage
      })

      const {data} = response
      setTotalItens(data['hydra:totalItems'] || 0)
      setContents(data['hydra:member'] || [])

      setLoading(false)
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    } catch (e) {
      console.error('Tools.loadContents.getTools', e)
    }
  }, [currentPage, perPage])

  const handlePageChange = (pageNumber) => {
    let filter = queryString.parse(window.location.search)
    if (filter && filter.page) {
      filter = Object.assign(filter, {
        page: pageNumber
      })
    }
    history.push({
      search: queryString.stringify(filter)
    })
  }

  useEffect(() => {
    loadContents()
    return () => {
      setLoading(true)
    }
  }, [currentPage, loadContents])

  if (loading) {
    return null
  }

  const BuildImage = ({content}) => {
    const {thumb, title} = content

    if (thumb) {
      return <Image src={thumb.url} alt={title} />
    }
    return <Image src={loadPublicImage('/no-course.jpg')} alt={title} />
  }

  return (
    <Container>
      {!!contents.length && (
        <Row>
          {contents.map((content) => (
            <Col key={content['@id']} xs={12} sm={6} md={4} lg={3}>
              <Item>
                <LinkImage href={content.link || '#'} target={content.link ? '_blank' : '_self'}>
                  <BuildImage content={content} />
                </LinkImage>
                <MetaInfo>
                  <Title
                    href={content.link || '#'}
                    target={content.link ? '_blank' : '_self'}
                    height={3}>
                    <Truncate lines={3}>{content.title}</Truncate>
                  </Title>
                  <Description height={3}>
                    <Truncate lines={3}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(content.description)
                        }}
                      />
                    </Truncate>
                  </Description>
                </MetaInfo>
              </Item>
            </Col>
          ))}
        </Row>
      )}

      {contents.length > 0 && totalItens > perPage && (
        <Pagination
          {...settings.pagination}
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={totalItens}
          onChange={handlePageChange}
        />
      )}
    </Container>
  )
}

export default Tools
