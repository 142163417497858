import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'

export const ButtonSyled = styled.button`
  height: 46px;
  padding: 0 30px;
  border-radius: 30px;
  text-transform: uppercase;
  white-space: nowrap;
  background: none;
  border: 1px solid transparent;
  font-weight: 600;
  margin: 0 7px;
  cursor: pointer;
  transition: all 0.3s linear;
  outline: none;
  text-decoration: none;
  ${(props) => {
    switch (props.color) {
      case 'primary':
        return css`
          border-color: #ffd862;
          background-color: #ffd862;
          color: #002c63;
          &:hover {
            background-color: transparent;
            color: #ffd862;
          }
        `
      case 'primary-light':
        return css`
          border-color: #ffd862;
          background-color: #ffd862;
          color: #002c63;
          &:hover {
            border-color: rgba(253, 218, 112, 0.7);
            background-color: rgba(253, 218, 112, 0.8);
          }
        `
      case 'secondary':
        return css`
          border-color: #002c63;
          background-color: #ffffff;
          color: #002c63;
          &:hover {
            background-color: rgba(0, 176, 230, 0.25);
          }
        `
      case 'blue-to-white':
        return css`
          border-color: #002c63;
          background-color: #002c63;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            border-color: #002c63;
            background-color: #ffffff;
            color: #002c63;
          }
        `
      case 'green':
        return css`
          border-color: #00985f;
          background-color: #ffffff;
          color: #00985f;
          text-transform: none;
          &:hover {
            background-color: #00985f;
            color: #ffffff;
          }
        `
      case 'transparent':
        return css`
          border-color: none;
          background-color: transparent;
          color: #fff;
          font-size: 20px;
          padding: 5px 5px 0 0;
          margin: 0px;
          text-transform: none;
          align-items: center;
    display: flex;
}
          &:hover {
            background-color: transparent;
            color: #ffffff;
          }
        `
      default:
        return css`
          border-color: #ffd862;
          color: #ffd862;
          &:hover {
            background-color: #00254a;
            color: #ffd862;
          }
        `
    }
  }}
`

const Placeholder = styled.input`
  &::placeholder {
    color: ${({color}) => color};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${({color}) => color};
  }

  &::-ms-input-placeholder {
    color: ${({color}) => color};
  }
`

Placeholder.propType = {
  color: PropTypes.string.isRequired
}

export {Placeholder}
