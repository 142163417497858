import React from 'react'
import PropTypes from 'prop-types'
import SambaPlayer from './SambaPlayer'
import {hasProperty} from '../../utils/functions'
import { getToken } from '../../utils/auth'

const Medias = ({content, nextContent, publicView = false}) => {
  let progress
  if (!publicView && getToken()) {
    const userCollectionView =
    content.userCollections.filter(
      (collection) => collection['@type'] === 'UserCollectionView'
    )[0] || {}

    progress = hasProperty(userCollectionView, 'progress') ? userCollectionView.progress : true
  }

  if (!content.contentReference) {
    return null
  }


  return (
    <SambaPlayer
      reference={content.contentReference}
      contentId={content['@id']}
      progressVideo={progress}
      typeContent={content['@type']}
      nextVideo={nextContent}
    />
  )
}

Medias.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  nextContent: PropTypes.oneOfType([PropTypes.object]).isRequired
}
export default Medias
