import React, {useState} from 'react'
import styled from 'styled-components'

const ShareButton = () => {
  const [open, setOpen] = useState(false)

  const openShare = (type) => {
    if (type.toLowerCase() === 'facebook') {
      const url = `https://www.facebook.com/sharer.php?u=${encodeURI(window.location.href)}`
      window.open(url, type.toLowerCase(), 'height=269,width=550,resizable=1')
    } else if (type.toLowerCase() === 'twitter') {
      const url = `https://twitter.com/intent/tweet?d=0&text=${encodeURI(window.location.href)}`
      window.open(url, type.toLowerCase(), 'height=285,width=550,resizable=1')
    } else if (type.toLowerCase() === 'whatsapp') {
      const url = `https://api.whatsapp.com/send?text=${encodeURI(window.location.href)}`
      window.open(url, type.toLowerCase())
    }
    setOpen(false)
  }

  return (
    <>
      <Share
        className="far fa-share-alt"
        onClick={() => setOpen(true)}
      >
        {window.innerWidth > 576 && <span className="tooltip">
          Compartilhe este conteúdo
        </span>}
      </Share>
      {open && (
        <Modal>
          <Background onClick={() => setOpen(false)} />
          <ModalShared>
            <Close onClick={() => setOpen(false)}>x</Close>
            <I className="fab fa-facebook" onClick={() => openShare('Facebook')} />
            <I className="fab fa-twitter" onClick={() => openShare('Twitter')} />
            <I className="fab fa-whatsapp" onClick={() => openShare('Whatsapp')} />
          </ModalShared>
        </Modal>
      )}
    </>
  )
}

const Modal = styled.div``

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9998;
`

const ModalShared = styled.div`
  position: fixed;
  height: 200px;
  width: 80vw;
  max-width: 480px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #002c63;
  color: #ffffff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Close = styled.div`
  color: #ffffff;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 14px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
`

const I = styled.i`
  font-size: 35px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
`

const Share = styled.div`
  position: relative;
  font-size: 17px;
  color: #002c63;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
  width: 20px;

  &:hover {
    span.tooltip {
      visibility: visible;
    }
  }

  > span.tooltip {
    visibility: hidden;
    width: 200px;
    bottom: 110%;
    left: 50%;
    padding: 10px 12px;
    margin-left: -120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-family: 'Muli';
    font-size: 12px;
    font-weight: 700;
  
    position: absolute;
    z-index: 1;

  }
`

export default ShareButton
