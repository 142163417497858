import moment from 'moment'
import momentPT from 'moment/locale/pt-br'

function getDates (startDate, daysToAdd) {
  const aryDates = []

  for (let i = 0; i < daysToAdd; i += 1) {
    const currentDate = new Date()
    currentDate.setDate(startDate.getDate() + i)

    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()

    const date = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`

    aryDates.push(date)
  }

  return aryDates
}

function getTrialsUntil (trialDays) {
  const dates = getDates(new Date(), trialDays)

  return dates[dates.length - 1]
}

function getCardExpiration (dateStr) {
  const date = new Date(dateStr)
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${month}/${year}`
}

function dateBRToUS (date) {
  return date.split('/').reverse().join('-')
}

function dateUSToBR (date) {
  moment.updateLocale('pt-br', momentPT)
  return moment(date, 'YYYY MM DD').format('L')
}

export {getTrialsUntil, getCardExpiration, dateBRToUS, dateUSToBR}
