import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import * as Yup from 'yup'
import {useAlert} from 'react-alert'
import Breadcrumb from '../../components/Breadcrumb'
import {
  Box,
  DashboardIcon,
  DashboardItem,
  DashboardTitle,
  DashboardValue,
  FormStyled
} from './styled'
// import DisableAccount from './DisableAccount';
import AvatarUpload from '../../components/AvatarUpload'
import {getCurrentUser, setCurrentUser} from '../../utils/auth'
import GenerateForm from '../../components/GenerateForm'
import {isCPF} from '../../utils/yup/identityValidate'
import {
  changePasswordUser,
  getUserStatistics,
  updateUser
} from '../../services/endpoints/general/currentUser'
import {states} from './jsons/states'
import {dateBRToUS, dateUSToBR} from '../../utils/manipulateDates'
import {apiCeps} from '../../services/api'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'
import {testPassword} from '../../services/auth'

const Profile = () => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const alert = useAlert()
  const [loading, setLoading] = useState(true)
  const {profile} = getCurrentUser()
  const [statistics, setStatistics] = useState(true)

  Yup.addMethod(Yup.string, 'cpf', (message) => {
    return Yup.mixed().test('cpf', message, (value) => isCPF(value))
  })

  Yup.addMethod(Yup.string, 'validDate', (message) => {
    return Yup.mixed().test('validDate', message, (value) => {
      if (!value || !value.length || value === 'Invalid date') {
        return true
      }
      return /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/.test(value)
    })
  })

  const {state} = profile
  const [currentState, setCurrentState] = useState(state)
  const [profileTemp, setProfileTemp] = useState({
    name: profile.name || '',
    email: profile.email || '',
    phoneNumber: profile.phoneNumber || '',
    identity: profile.identity || '',
    birthday: dateUSToBR(profile.birthday) || '',
    gender: profile.gender || '',
    lastPassword: '',
    plainPassword: '',
    confirmPassword: ''
  })
  const [city, setCity] = useState(profile.city)
  const [cities, setCities] = useState([{label: 'Selecione a Cidade', value: ''}])
  const [showlegend, setShowLegend] = useState(false)
  const [passwordValidate, setPasswordValidate] = useState({
    min: false,
    number: false,
    letterlowercase: false,
    letteruppercase: false,
    letterespecial: false
  })

  useEffect(() => {
    (async () => {
      try {
        if (currentState.length) {
          setCities([{label: 'Carregando...', value: ''}])
          const response = await apiCeps(`/cities/${currentState}`)
          const data =
            response.data.map((item) => ({
              label: item.name,
              value: item.name
            })) || []
          if (state !== currentState) {
            setCity(data[0].value)
          }
          data.unshift({label: 'Selecione a Cidade', value: ''})
          setCities(data)
        }
      } catch (e) {
        console.error('Profile.useEffect.apiCeps.cities', e)
      }
    })()
  }, [state, currentState])

  const groupFields = [
    {
      name: 'IDENTIFICAÇÃO',
      fields: [
        {
          label: 'Nome e sobrenome *',
          hasPlaceholder: true,
          name: 'name',
          value: profileTemp.name,
          required: false,
          validations: {
            name: Yup.string().required('Campo obrigatório')
          },
          onChange: (value) => {
            const newValue = Object.assign(profileTemp, {name: value})
            setProfileTemp(newValue)
          },
          columns: {
            xs: 12,
            md: 6
          }
        },
        {
          label: 'E-mail *',
          hasPlaceholder: true,
          name: 'email',
          value: profileTemp.email,
          required: true,
          validations: {
            email: Yup.string().email('E-mail inválido').required('Campo obrigatório')
          },
          onChange: (value) => {
            const newValue = Object.assign(profileTemp, {name: value})
            setProfileTemp(newValue)
          },
          columns: {
            xs: 12,
            md: 6
          }
        },
        {
          label: 'Celular',
          hasPlaceholder: true,
          name: 'phoneNumber',
          value: profileTemp.phoneNumber || '',
          required: false,
          onChange: (value) => {
            const newValue = Object.assign(profileTemp, {phoneNumber: value})
            setProfileTemp(newValue)
          },
          validations: {
            phoneNumber: Yup.string()
              .notRequired()
              .test('phoneNumber', 'Telefone incorreto', (value) => {
                const origimValue = profile.phoneNumber
                if (value !== origimValue) {
                  const schema = Yup.string().min(18, 'Telefone incorreto')
                  return schema.isValidSync(value)
                }
                return true
              })
          },
          mask: '+99 (99) 99999-9999',
          maskType: 'phone',
          columns: {
            xs: 6
          }
        },
        {
          label: 'CPF',
          hasPlaceholder: true,
          name: 'identity',
          value: profileTemp.identity || '',
          required: false,
          validations: {
            identity: Yup.string().cpf('CPF Inválido')
          },
          onChange: (value) => {
            const newValue = Object.assign(profileTemp, {identity: value})
            setProfileTemp(newValue)
          },
          mask: '999.999.999-99',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Data de nascimento',
          hasPlaceholder: true,
          name: 'birthday',
          value: profileTemp.birthday || '',
          required: false,
          validations: {
            birthday: Yup.string().validDate('Não é uma data válida')
            // .matches(
            //  /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
            // 'Não é uma data válida',
            // ),
          },
          onChange: (value) => {
            const newValue = Object.assign(profileTemp, {birthday: value})
            setProfileTemp(newValue)
          },
          mask: '99/99/9999',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Gênero',
          type: 'select',
          hasPlaceholder: true,
          name: 'gender',
          value: profileTemp.gender || '',
          required: false,
          validations: {
            gender: Yup.string()
          },
          onChange: (value) => {
            const newValue = Object.assign(profileTemp, {gender: value})
            setProfileTemp(newValue)
          },
          options: [
            {
              label: 'Selecionar um gênero',
              value: ''
            },
            {
              label: 'Masculino',
              value: 'masculino'
            },
            {
              label: 'Feminino',
              value: 'feminino'
            },
            {
              label: 'Outro',
              value: 'outro'
            }
          ],
          columns: {
            xs: 6
          }
        }
      ]
    },
    {
      name: 'ENDEREÇO',
      fields: [
        {
          label: 'Estado',
          hasPlaceholder: true,
          name: 'state',
          value: currentState || '',
          required: false,
          validations: {
            state: Yup.string()
          },
          mask: '',
          type: 'select',
          options: states,
          columns: {
            xs: 6
          },
          dependentField: setCurrentState
        },
        {
          label: 'Cidade',
          type: 'select',
          options: cities,
          disabled: true,
          hasPlaceholder: true,
          name: 'city',
          value: city || '',
          required: false,
          validations: {
            city: Yup.string()
          },
          mask: '',
          columns: {
            xs: 6
          }
        }
      ]
    },
    {
      name: 'TROCAR SENHA',
      fields: [
        {
          label: 'Senha anterior',
          hasPlaceholder: false,
          type: 'password',
          name: 'lastPassword',
          value: profileTemp.lastPassword,
          showButtonViewPassword: true,
          maxLength: 24,
          required: false,
          validations: {
            lastPassword: Yup.string()
              .min(8, 'Senha com no mínimo 8 caracteres')
              .max(24, 'Senha com no máximo 24 caracteres')
          },

          mask: '',
          columns: {
            xs: 12
          }
        },
        {
          label: 'Nova senha',
          hasPlaceholder: false,
          type: 'password',
          name: 'plainPassword',
          value: profileTemp.plainPassword,
          onFocus: () => setShowLegend(true),
          onBlur: () => setShowLegend(false),
          passwordValidation: (value) => {
            let obj = {}
            if (value.length >= 8) {
              obj = Object.assign(obj, {min: true})
            } else {
              obj = Object.assign(obj, {min: false})
            }

            if (/[a-z]/gm.test(value)) {
              obj = Object.assign(obj, {letterlowercase: true})
            } else {
              obj = Object.assign(obj, {letterlowercase: false})
            }

            if (/[A-Z]/gm.test(value)) {
              obj = Object.assign(obj, {letteruppercase: true})
            } else {
              obj = Object.assign(obj, {letteruppercase: false})
            }

            if (/[0-9]/gm.test(value)) {
              obj = Object.assign(obj, {number: true})
            } else {
              obj = Object.assign(obj, {number: false})
            }
            if (/[!@#$%*()_+^&{}}:;?.]/gm.test(value)) {
              obj = Object.assign(obj, {letterespecial: true})
            } else {
              obj = Object.assign(obj, {letterespecial: false})
            }
            setPasswordValidate(obj)
          },
          showButtonViewPassword: true,
          showLegend: (() => {
            return (
              <>
                {showlegend && (
                  <>
                    <div>A senha deve ter:</div>
                    <ul>
                      <li className={`${passwordValidate.min ? 'complete' : ''}`}>
                        Mínimo de 8 caracteres
                      </li>
                      <li className={`${passwordValidate.number ? 'complete' : ''}`}>1 número</li>
                      <li className={`${passwordValidate.letterlowercase ? 'complete' : ''}`}>
                        1 letra minúscula
                      </li>
                      <li className={`${passwordValidate.letteruppercase ? 'complete' : ''}`}>
                        1 letra maiúscula
                      </li>
                      <li className={`${passwordValidate.letterespecial ? 'complete' : ''}`}>
                        1 caractere especial (*&$!)
                      </li>
                    </ul>
                  </>
                )}
              </>
            )
          })(),
          colorLegend: 'rgb(103, 103, 103)',
          maxLength: 24,
          required: false,
          validations: {
            plainPassword: Yup.string()
              .min(8, 'Senha com no mínimo 8 caracteres')
              .max(24, 'Senha com no máximo 24 caracteres')
              .matches(/[a-z]/, 'Pelo menos um caractere minúsculo')
              .matches(/[A-Z]/, 'Pelo menos um caractere maiúsculo')
              .matches(/[0-9]/, 'Pelo menos um número')
              .matches(/[@$!%*#?&.<>]/, 'Pelo menos um caractere especial ex: @,$,!,%...')
          },
          mask: '',
          columns: {
            xs: 12,
            sm: 6
          }
        },
        {
          label: 'Confirmar senha',
          hasPlaceholder: false,
          type: 'password',
          name: 'confirmPassword',
          value: profileTemp.confirmPassword,
          showButtonViewPassword: true,
          maxLength: 24,
          required: false,
          validations: {
            confirmPassword: Yup.string().when('plainPassword', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('plainPassword')], 'As senhas não são iguais')
                .required('Campo obrigatório')
            })
          },
          mask: '',
          columns: {
            xs: 6
          }
        }
      ]
    }
  ]

  const onSubmit = async (values, actions) => {
    const data = {
      name: values.name,
      email: values.email,
      phoneNumber: values.phoneNumber.replace(/\D/g, ''),
      identity: values.identity,
      zipCode: values.zipCode,
      street: values.street,
      houseNumber: values.houseNumber,
      complement: values.complement,
      birthday:
        values.birthday && values.birthday !== 'Invalid date' ? dateBRToUS(values.birthday) : null,
      gender: values.gender,
      neighborhood: values.neighborhood,
      city: values.city,
      state: values.state
    }

    try {
      const response = await updateUser(data)
      setCurrentUser({...getCurrentUser(), profile: response.data})

      // save password if exits
      if (values.plainPassword.length >= 8) {
        try {
          await testPassword(values.email, values.lastPassword)
          await changePasswordUser({
            plainPassword: values.plainPassword
          })

          actions.resetForm({
            ...profileTemp,
            plainPassword: '',
            confirmPassword: '',
            lastPassword: ''
          })
          alert.show('Dados salvos com sucesso!', {
            closeCopy: 'Ok'
          })
        } catch (e) {
          console.error('AUTH', e)
          alert.show('Senha anterior não confere')
        }
      }
      if (values.plainPassword.length === 0) {
        alert.show('Dados salvos com sucesso!', {
          closeCopy: 'Ok'
        })
      }

      actions.setSubmitting(false)
    } catch (e) {
      alert.show('Ocorreu um erro ao salvar os dados, tente novamente mais tarde!', {
        closeCopy: 'Ok'
      })
      actions.setSubmitting(false)
      console.error('Profile.onSubmit.updateUser', e)
    }

    actions.setSubmitting(false)
  }

  const loadStatistics = useCallback(async () => {
    const response = await getUserStatistics()
    setStatistics(response.data || {})
    setLoading(false)
  }, [])

  useEffect(() => {
    loadStatistics()
    return () => {
      setLoading(true)
    }
  }, [loadStatistics])

  if (loading) {
    return null
  }

  return (
    <Container className="pb-5">
      <h1>Meu Perfil</h1>

      <Breadcrumb contents={[{title: 'Meu Perfil'}]} />

      <Box>
        <Row className="align-items-center">
          <Col xs={12} sm={4} md={7} lg={6}>
            <AvatarUpload user={getCurrentUser()} />

            <Row>
              <Col xs={12} sm={4}>
                <DashboardItem to="/certificados">
                  <DashboardIcon className="far fa-graduation-cap" />
                  <DashboardTitle>Certificados</DashboardTitle>
                  <DashboardValue>{statistics.certificatesQuantity}</DashboardValue>
                </DashboardItem>
              </Col>
              <Col xs={12} sm={4}>
                <DashboardItem to="/conteudos-visualizados">
                  <DashboardIcon className="far fa-eye" />
                  <DashboardTitle>Visualizados</DashboardTitle>
                  <DashboardValue>{statistics.viewedContentsQuantity}</DashboardValue>
                </DashboardItem>
              </Col>
              <Col xs={12} sm={4}>
                <DashboardItem to="/favoritos">
                  <DashboardIcon className="fas fa-heart" />
                  <DashboardTitle>Favoritos</DashboardTitle>
                  <DashboardValue>{statistics.favoritedContentsQuantity}</DashboardValue>
                </DashboardItem>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={8} md={5} lg={6}>
            {/* <DisableAccount email={profile.email} /> */}
            <FormStyled>
              <GenerateForm
                onSubmit={onSubmit}
                groupFields={groupFields}
                settings={{
                  button: {
                    text: 'Salvar Alteração'
                  }
                }}
              />
            </FormStyled>
          </Col>
        </Row>
      </Box>
    </Container>
  )
}

export default Profile
