import React from 'react'
import PropTypes from 'prop-types'
import {Link as LinkRouter} from 'react-router-dom'
import {hasExternalLink} from '../../utils/utilities'

const Link = ({to, action, children}) => {
  if (to && hasExternalLink(to)) {
    return (
      <a href={to || '/'} target={action || '_self'} rel="noopener noreferrer">
        {children}
      </a>
    )
  }
  if (!to) {
    return <> {children}</>
  }

  return (
    <LinkRouter to={to} target={action || '_self'}>
      {children}
    </LinkRouter>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  action: PropTypes.string,
  children: PropTypes.any
}

export {Link}
