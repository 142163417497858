import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const Item = styled(Link)`
  display: flex;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  &.active {
    background: #eee;
  }

  &:hover {
    span.tooltip {
      visibility: visible;
    }
  }

  span.tooltip {
    visibility: hidden;
    width: 210px;
    top: 50px;
    left: 50%;
    margin-left: -105px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 6px 12px;
    border-radius: 6px;
    font-family: 'Muli';
    font-size: 12px;
    font-weight: 700;
  
    position: absolute;
    z-index: 2;
  }
`

export const Image = styled.div`
  max-width: 80px;
  width: 100%;
  margin-right: 5px;
  object-fit: contain;

  div.underImage {
    width: 100%;
    height: calc(100% - 5px);
    background: #003475;
    background-image: url('/images/block.png');
    background-position: center;
    background-repeat: no-repeat;
    

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }    
  }

  img {
    max-width: 100%;
    width: 100%;
  }
`

export const Watched = styled.i`
  position: absolute;
  top: 5px;
  left: 5px;
  background: #00985f;
  color: #fff;
  font-size: 8px;
  padding: 3px;
  border-radius: 3px;
  z-index: 9;
`

export const MetaInfo = styled.div`
  position: relative;
  overflow: hidden;
`

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  color: #004685;
  font-size: 14px;
  letter-spacing: 0.5px;
`

export const Type = styled.div`
  font-size: 13px;
  color: #666e7a;
  letter-spacing: 0.5px;
`
