import styled from 'styled-components'
import {Button} from '../Elements'

export const Item = styled.div`
  position: relative;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 30px;
`

export const ImageAdjust = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
`

export const Image = styled.img`
  position: absolute;
  max-width: 90%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
`

export const MetaInfo = styled.div`
  padding: 15px;
`

export const Title = styled.h3`
  color: #002c63;
  font-size: 22px;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  position: relative;
`

export const Btn = styled(Button)`
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
`

export const SocialMedias = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
`

export const Social = styled.a`
  font-size: 20px;
  line-height: 20px;
  width: 24px;
  height: 24px;
  color: #002c63;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 16px;
  }
`
