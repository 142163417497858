import React, {useCallback, useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Medias from '../../../components/Medias'
import Collections from '../../../components/Contents/_partials/Collections'
import {getToken} from '../../../utils/auth'
import {
  ContentTitle,
  Description,
  Embed,
  ImageResponsive
} from '../../../components/Contents/_partials/styled'
import {postContentView} from '../../../services/endpoints/contents/contents'
import {ContentFile} from './ContentFile'
import {sanitizeHtml} from '../../../utils/santinize/sanitizeHtml'
import {WatchedContext, WatchedCourseContext} from '../context'
import {buildImageUrl} from '../../../utils/buildImageUrl'
import {decodeHtml} from '../../../utils/santinize/decodeHtml'

const ContentType = ({content, watched, showTitle = false, updatePreferences, publicView = false}) => {
  const changeWatchedStateLesson = useContext(WatchedCourseContext)
  const [currentWatched, setCurrentWatched] = useState(watched)
  const [apiHtml, setApiHtml] = useState('')
  
  useEffect(() => {
    setCurrentWatched(watched)
  }, [watched])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleCurrentWatched = useCallback(() => {
    if (changeWatchedStateLesson) {
      changeWatchedStateLesson()
    } else {
      updatePreferences()
      setCurrentWatched(true)
    }
  }, [changeWatchedStateLesson, updatePreferences])

  useEffect(() => {
    const apiHtmlSanitize = sanitizeHtml(
      content.summaryInternal
        ? content.summaryInternal
        : content.content || content.summary
    )

    setApiHtml(apiHtmlSanitize)
  }, [content.content, content.summary, content.summaryInternal])

  if (apiHtml.match(/<a href/i)) {
    const modifierHtml = apiHtml.replaceAll(
      '<a href',
      '<a target="_blank" rel="noopener noreferrer" href'
    )

    setApiHtml(modifierHtml)
  }

  const DefaultElements = () => {
    return (
      <>
        {showTitle && <ContentTitle>{decodeHtml(content.title)}</ContentTitle>}
        <Collections
          content={content}
          hasShare
          publicView={publicView}
          enabledRatings
          watched={currentWatched}
          showWatched
        />
        <Description
          className="content-text"
          dangerouslySetInnerHTML={{
            __html: apiHtml
          }}
        />
      </>
    )
  }

  React.useEffect(() => {
    const blackList = ['Video', 'Podcast', 'Ebook']
    if (!blackList.includes(content['@type'])) {
      if (!watched && getToken()) {
        setTimeout(async () => {
          try {
            await postContentView({
              content: content['@id'],
              percent: 100,
              progress: 1
            })
            handleCurrentWatched()
          } catch (e) {
            console.error('ContentType.useEffect.postContentView', e)
          }
        }, 1000 * 5)
      }
    }
  }, [content, watched, handleCurrentWatched])

  switch ((content['@type'].toLowerCase())) {
    case 'video':
      return (
        <WatchedContext.Provider value={handleCurrentWatched}>
          <Medias content={content} nextContent={{}} publicView={publicView} />
          <DefaultElements />
        </WatchedContext.Provider>
      )
    case 'podcast':
      return (
        <WatchedContext.Provider value={handleCurrentWatched}>
          {content.cover && (
            <AdjustImage>
              <ImgResponsive src={buildImageUrl(content.cover.url, 930, 650)} />
            </AdjustImage>
          )}
          <Medias content={content} nextContent={{}} />
          <DefaultElements />
        </WatchedContext.Provider>
      )
    case 'infographic':
      return (
        <>
          {content.infographicImage && (
            <ImageResponsive
              src={buildImageUrl(content.infographicImage.url, 850, 0, 'inside', true)}
            />
          )}
          <DefaultElements />
        </>
      )
    case 'article':
      return (
        <>
          {content.cover && (
            <ImageResponsive src={buildImageUrl(content.cover.url, 850, 0, 'inside', true)} />
          )}
          {content.thumb && !content.cover && (
            <ImageResponsive src={buildImageUrl(content.thumb.url, 850, 0, 'inside', true)} />
          )}
          <DefaultElements />
        </>
      )
    case 'ebook':
      return (
        <>
          <ContentFile
            content={content}
            currentWatched={currentWatched}
            setCurrentWatched={handleCurrentWatched}
          />
          <DefaultElements />
        </>
      )
    case 'videoConference':
    case 'webinar':
      return (
        <>
          {content.contentSource && (
            <Embed dangerouslySetInnerHTML={{__html: sanitizeHtml(content.contentSource)}} />
          )}
          <DefaultElements />
        </>
      )
    default:
      return <DefaultElements />
  }
}

const AdjustImage = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 41%;
  margin-bottom: 30px;
`

const ImgResponsive = styled.img`
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

ContentType.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  watched: PropTypes.bool.isRequired,
  showTitle: PropTypes.bool
}
export default ContentType
