import {getExam, getMeExam} from '../../../services/endpoints/contents/contents'

export const loadExamUser = async (contentId) => {
  const params = {
    exam: {}, // Se tem exame retorna o exame
    mandatoryApproval: false, // requer fazer a prova, para obter o certificado
    examApproval: false, // se foi aprovado no resolvado
    userGradeObtained: 0 // nota máxima obtida no exame
  }

  try {
    // Checks whether the course has an exam
    const resExam = await getExam(contentId)

    Object.assign(params, {
      exam: resExam.data || {},
      mandatoryApproval: resExam.data.mandatoryApproval || false
    })

    try {
      // Checks whether the user has taken an exam
      const myExam = await getMeExam(contentId)
      const myExameData = myExam.data['hydra:member'] || []

      Object.assign(params, {
        examApproval: !!myExameData.filter(
          (item) => item.gradeObtained >= resExam.data.approvalPercentage
        ).length,
        userGradeObtained: Math.max(...myExameData.map((item) => item.gradeObtained)) || 0
      })
    } catch (e) {
      if (e.response.status === 404) {
        Object.assign(params, {
          examApproval: false
        })
      } else {
        console.error('ContentList.loadContent.getContents.getExam.getMeExam', e)
      }
    }
  } catch (e) {
    if (e.response.status === 404) {
      Object.assign(params, {
        exam: {},
        mandatoryApproval: false
      })
    } else {
      console.error('ContentShow.loadContent.getContents.getExam', e)
    }
  }

  return params
}
