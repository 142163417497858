import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Col, Row} from 'reactstrap'
import queryString from 'query-string'
import {useHistory} from 'react-router-dom'
import SelectFormats from '../_selects/formats'
import {Button} from '../../../components/Elements'
import SelectPartners from '../_selects/SelectPartners'

const FilterBar = ({by}) => {
  const history = useHistory()
  const search = {by} || queryString.parse(history.location.search)

  const [values, setValues] = useState(search)
  const isMounted = useRef(true)

  const setFieldValue = (event) => {
    if (isMounted.current) {
      const {name = '', value = ''} = event.target
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }))
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleSubmit = () => {
    if (values && values.page) {
      delete values.page
    }
    const category = history.location.pathname.split('/')[1]

    if (category === 'parceiro' && values.parceiro) {
      history.push(`/${category}/${values.parceiro}?${queryString.stringify(values)}`)
    } else {
      history.push(`${history.location.pathname}?${queryString.stringify(values)}`)
    }
  }

  return (
    <Bar>
      <Row className="align-items-center">
        <Col xs={12} md={6} lg={3} className="mb-3 mb-lg-0">
          <FilterTitle>
            <FilterIcon />
            Refine sua busca
          </FilterTitle>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-3 mb-lg-0">
          <SelectFormats setFieldValue={setFieldValue} />
        </Col>

        {search.by !== 'parceiro' ? <SelectPartners mayHave={!!(search.by === 'favoritos' || search.by === 'conteudos-visualizados')} setFieldValue={setFieldValue} /> : <div className="col" />}

        <Col xs={12} md={6} lg={2}>
          <Btn color="primary-light" onClick={handleSubmit}>
            Filtrar
          </Btn>
        </Col>
      </Row>
    </Bar>
  )
}

const Bar = styled.div`
  background-color: #f2f2f2;
  border-radius: 3px;
  color: #002c63;
  padding: 15px;
  margin-bottom: 15px;
`

const Btn = styled(Button)`
  width: 100%;
`

const FilterTitle = styled.div`
  display: flex;
`

const FilterIcon = styled.div`
  position: relative;
  width: 16px;
  height: 2px;
  background: #002c63;
  margin-top: 4px;
  margin-right: 5px;
  &:before {
    content: '';
    width: 10px;
    height: 2px;
    background: #002c63;
    position: absolute;
    top: 4px;
    left: 3px;
  }
  &:after {
    content: '';
    width: 6px;
    height: 2px;
    background: #002c63;
    position: absolute;
    top: 8px;
    left: 5px;
  }
`

FilterBar.propTypes = {
  by: PropTypes.string.isRequired
}
export default FilterBar
