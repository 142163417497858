import styled from 'styled-components'
import {loadPublicImage} from '../../utils/loadPublicImage'

const IcoUserCircle = loadPublicImage('/icons/ico1.png')
const IcoNotebook = loadPublicImage('/icons/ico2.png')
const IcoTheme = loadPublicImage('/icons/ico3.png')

export const Box = styled.div`
  background: rgba(0, 176, 230, 0.2);
  padding: 30px 0;
`

export const Title = styled.div`
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  color: #002c63;
  margin-bottom: 10px;
`

export const Description = styled.div`
  font-size: 35px;
  line-height: 44px;
  letter-spacing: -1px;
  color: #0063de;
  p {
    margin: 0;
  }
`

export const Item = styled.div`
  position: relative;
  margin-bottom: 30px;
  &:before {
    content: '';
    background: #ffffff;
    padding-bottom: 100%;
    display: block;
    border-radius: 50%;
  }
`

export const MetaInfo = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  right: 15px;
  text-align: center;
`

export const Icon = styled.div`
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 5px;
  &.ico-user-circle {
    background-image: url(${IcoUserCircle});
  }
  &.ico-notebook {
    background-image: url(${IcoNotebook});
  }
  &.ico-theme {
    background-image: url(${IcoTheme});
  }
`

export const Number = styled.div`
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.5px;
  color: #002c63;
  margin-bottom: 5px;
`

export const Info = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #0063de;
`
