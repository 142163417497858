import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'
import {Link} from 'react-router-dom'
import {MetaInfo, Image, Item, ImageAdjust, Description, Btn, SocialMedias, Social} from './styled'
import GetIcons from '../../utils/icons'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'

const PartnerItem = ({partner}) => {
  return (
    <Item>
      <Link to={`/parceiro/${partner.slug}`} title={partner.name}>
        <ImageAdjust>
          {partner.image && <Image src={partner.image.url} alt={partner.name} />}
        </ImageAdjust>
      </Link>
      <MetaInfo>
        <Description height={3}>
          <Truncate lines={3}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(partner.description)
              }}
            />
          </Truncate>
        </Description>

        <Btn color="primary-light" type={Link} to={`/parceiro/${partner.slug}`}>
          Conteúdos
        </Btn>

        <SocialMedias>
          {partner.socialMedia.map((social) =>
            social.url ? (
              <Social key={social.id} href={social.url} target="_blank" title={social.title}>
                <GetIcons icon={social.icon} />
              </Social>
            ) : null
          )}
        </SocialMedias>
      </MetaInfo>
    </Item>
  )
}

PartnerItem.propTypes = {
  partner: PropTypes.oneOfType([PropTypes.object]).isRequired
}
export default PartnerItem
