/* eslint-disable consistent-return */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row} from 'reactstrap'
import Spinner from 'reactstrap/es/Spinner'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'
import ReactModal from 'react-modal'
import {Button} from '../../components/Elements'
import {getEndpoints} from '../../services/endpoints/getEndpoints'
import {
  getContent,
  getContents,
  getCertifiedDownload,
  postContentView,
  checkExam,
  checkCourse,
  setContentAccess
} from '../../services/endpoints/contents/contents'
import {hasProperty} from '../../utils/functions'
import {getToken} from '../../utils/auth'
import {loadRelatedContents} from './utils/loadRelatedContents'
import {userWatchedTheContent} from './utils/userWatchedTheContent'
import {loadExamUser} from './utils/loadExamUser'
import {initialContentParams} from './utils/initialContentParams'
import {loadContentLessons} from './utils/loadContentLessons'
import {getAtualContent} from './utils/getAtualContent'
import Breadcrumb from '../../components/Breadcrumb'
import Attachments from '../../components/Contents/_partials/Attachments'
import ShowBannerExam from './_partials/ShowBannerExam'
import RelatedContents from './_partials/RelatedContents'
import {Box, BoxScrollInner, BoxScrollOuter, Title, BoxScrollInnerRelative} from './styled'
import ContentType from './_contents/ContentType'
import PlaylistLessons from './_partials/PlaylistLessons'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'
import {CertifiedDownload} from '../../components/Contents/_partials/styled'
import {makeUrlContent} from './data/makeUrlContent'
import {WatchedCourseContext} from './context/WatchedCourseContext'
import {decodeHtml} from '../../utils/santinize/decodeHtml'
import {getImageHeader} from '../../utils/getImageHeader'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'
import { ContentLoggedOut } from '../../components/Contents/Types/ContentLoggedOut'
import { SignModal } from '../../components/Modal'

ReactModal.setAppElement('#root')

const ContentCourseShow = ({match, history, location}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()
  const [courseCompleted, setCourseCompleted] = useState(null)
  const [examCompleted, setExamCompleted] = useState(null)
  const [apiHtml, setApiHtml] = useState('')


  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const [playlist, setPlaylist] = useState([])
  const {slug} = match.params
  const [loading, setLoading] = useState(true)

  const [content, setContent] = useState({})
  const [relatedContents, setRelatedContents] = useState([])
  const [currentLesson, setCurrentLesson] = useState(0)
  const [reset, setReset] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const ButtonNext = () => {
    if (Object.keys(content).length) {
      const length = content.lessons.length - 1
      if (currentLesson < length) {
        return (
          <AlignBtnNextContent className="col-9">
            <BtnNextContent type="button" onClick={() => setCurrentLesson((prev) => prev + 1)}>
              Próxima Aula
            </BtnNextContent>
          </AlignBtnNextContent>
        )
      }
      if (Object.keys(playlist).length) {
        return (
          <AlignBtnNextContent className="col-9">
            <BtnNextContent
              to={{
                pathname: makeUrlContent(playlist),
                state: {
                  playlist: location.state ? location.state.playlist || '' : location.search || ''
                }
              }}>
              Próximo conteúdo
            </BtnNextContent>
          </AlignBtnNextContent>
        )
      }
    }
    return null
  }
  const handleGetCertified = async () => {
    try {
      await getCertifiedDownload(content.course.id)
    } catch (e) {
      alert.show('Ocorreu um erro ao baixar o certificado')
      console.error('ContentItem.handleGetCertified', e)
    }
  }
  useEffect(() => {
    const nextContent = async (contentData) => {
      if (location && location.state && location.state.playlist && contentData) {
        try {
          try {
            const response = await getEndpoints(location.state.playlist)
            const data = response.data['hydra:member'] || []

            const index = data.flatMap((item, idx) => {
              return item.id === contentData.id ? idx : []
            })
            if (index.length && data[index[0] + 1]) {
              setPlaylist(data[index[0] + 1])
              return data[index[0] + 1]
            }
            return {}
          } catch (e) {
            console.error('ContentShow.useEffect[search]', e)
          }
        } catch (e) {
          console.error(e)
        }
      }
    }

    let isCancelled = false;
    (async () => {
      try {
        const response = await getContent(slug)
        const {data} = response

        const courseParams = initialContentParams

        if (data['@type'] === 'Course') {
          if (getToken()) {
            Object.assign(courseParams, userWatchedTheContent(data.userCollections))
            if (data.exam) {
              Object.assign(courseParams, await loadExamUser(data.id))
            }
          }
          
          const lessons = await loadContentLessons(data.id)
          Object.assign(courseParams, currentLesson > 0 ? currentLesson : getAtualContent(lessons))
          if (!isCancelled) {
            setRelatedContents(await loadRelatedContents(data.id) || [])
            // setCurrentLesson(courseParams.currentLesson);
            setContent({
              course: data,
              ...courseParams,
              lessons
            })

            if (getToken()) {
              await nextContent(data)
            }
            setLoading(false)
          }
        } else {
          history.push({
            pathname: makeUrlContent(data)
          })
        }
      } catch (e) {
        if (e && e.response && e.response.status === 404) {
          history.push('/404')
        }

        if (e.response && (e.response.status === 401 || e.response.status === 500)) {
          let slugOff = window.location.pathname.split('/')
          slugOff = slugOff[slugOff.length - 1]
          try {
            const res = await getContents('/contents', {slug: slugOff})
            const {data} = res
            setContent(data['hydra:member'][0])
            setLoading(false)
          } catch (error) {
            console.error('erro ao buscar conteudo privado')
            // history.push('/404');
            return error
          }
        }
        if (!isCancelled) {
          console.error('ContentCourseShow.useEffect.course.getContent', e)
        }
      }
    })()

    return () => {
      isCancelled = true
    }
  }, [slug, history, location, reset])

  const changeWatchedStateLesson = () => {
    const newLessons = content.lessons.map((lesson, index) => {
      if (index === currentLesson) {
        return {
          ...lesson,
          watched: true
        }
      }
      return lesson
    })

    if (!content.lessons[currentLesson].watched) {
      setContent((preContent) => ({
        ...preContent,
        lessons: newLessons,
        updated: currentLesson
      }))
      setReset((prev) => !prev)
    }
  }

  useEffect(() => {
    if (getToken() && !content.watched && content.lessons) {
      const watchedLessonsCount = content.lessons.filter((i) => i.watched).map((i) => i.watched)
        .length
      const lessonsCount = content.lessons.length
      if (watchedLessonsCount >= Math.floor((lessonsCount * 100) / 100)) {
        (async () => {
          try {
            await postContentView({
              content: content.course['@id'],
              percent: 100,
              progress: 1
            })
          } catch (e) {
            console.error('ContentCourseShow.useEffect.postContentView', e)
          }
        })()
      }
    }
    if (getToken() && content && content.course) {
      (async () => {
        const courseCheck = await checkCourse(content.course.id)
        const examCheck = await checkExam(content.course.id)
        setCourseCompleted(courseCheck.data)
        setExamCompleted(examCheck.data)
      })()
    }
    if (
      getToken() &&
      content &&
      content.lessons &&
      content.lessons[currentLesson] &&
      content.lessons[currentLesson].content['@id']
    ) {
      (async () => {
        await setContentAccess({
          content: content.lessons[currentLesson].content['@id']
        })
      })()
    }
  }, [content, currentLesson])

  useEffect(() => {
    if(content && content.course && content.course.additionalText){
      const apiHtmlSanitize = sanitizeHtml(
        content.course.additionalText
      )
  
      setApiHtml(apiHtmlSanitize)
      
    }
  }, [content])

  if (apiHtml.match(/<a href/i)) {
    const modifierHtml = apiHtml.replaceAll(
      '<a href',
      '<a target="_blank" rel="noopener noreferrer" href'
    )

    setApiHtml(modifierHtml)
  }

  return (
    <Container className="pb-5">
      {loading ? (
        <Spinner />
      ) : (
        <div
          key={
            content &&
            content.lessons &&
            content.lessons[currentLesson] &&
            content.lessons[currentLesson]['@type']
          }>
          <SignModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} />
          {content && content.course && <Title>{decodeHtml(content.course.title)}</Title>}
          <Breadcrumb
            contents={[
              {title: 'Curso', url: '/para-voce?format=Course'},
              {
                title:
                  content && content.course && content.course.title
                    ? decodeHtml(content.course.title)
                    : decodeHtml(content.title)
              }
            ]}
          />
          {content && content.course && content.course.additionalText && (
            <p
              className="content-text"
              dangerouslySetInnerHTML={{__html: apiHtml}}
            />
          )}

          {(Object.keys(content.course).length && content.course) ? (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{content.course ? decodeHtml(content.course.title) : decodeHtml(content.title)}</title>
              <meta property="og:title" content={content.course ? decodeHtml(content.course.title) : decodeHtml(content.title)} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={getImageHeader(content.course)} />
              <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
          ) : (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{content && decodeHtml(content.title)}</title>
              <meta property="og:title" content={content && decodeHtml(content.title)} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={getImageHeader(content)} />
              <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
          )}

          {!getToken() && content.course.free === false ? (
            <>
              {content.lessons[currentLesson] && content.lessons[currentLesson].content ? 
                (<Row>
                  <Col xs={12} md={9}>
                    <ContentType 
                      showTitle
                      content={content.lessons[currentLesson].content}
                      playlist={[]}
                      watched={false}
                      publicView
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <Box style={{marginBottom: 90}}>
                      <BoxScrollOuter>
                        <BoxScrollInner>
                          <PlaylistLessons
                            isFree
                            content={content.lessons[currentLesson].content}
                            playlist={content.lessons}
                            setCurrentLesson={setCurrentLesson}
                          />
                        </BoxScrollInner>
                      </BoxScrollOuter>
                    </Box>
                  </Col>
                  <AlignBtnNextContent className="col-9">
                    <BtnNextContent type="button" onClick={() => setModalOpen(true)}>
                      Próxima Aula
                    </BtnNextContent>
                  </AlignBtnNextContent>
                </Row>) : (
                  <ContentLoggedOut content={content || []} />
                )}
            </>
          ) : (
            <>
              {content && content.lessons && content.lessons[currentLesson] ? (
                <Row>
                  <Col xs={12} md={9}>
                    <WatchedCourseContext.Provider value={changeWatchedStateLesson}>
                      <ContentType
                        showTitle
                        content={content.lessons[currentLesson].content}
                        playlist={content.lessons.slice(currentLesson, content.lessons.length)}
                        watched={content.lessons[currentLesson].watched}
                      />
                    </WatchedCourseContext.Provider>
                    <Attachments
                      attachments={[
                        ...content.course.attachments || [],
                        ...content.lessons[currentLesson].content.attachments || []
                      ]}
                    />
                    {hasProperty(content.lessons[currentLesson], 'content') &&
                      !!content.lessons[currentLesson].content.exam &&
                      !content.lessons[currentLesson].examApproval && (
                      <ShowBannerExam
                        content={content.lessons[currentLesson].content}
                        preferences={{
                          examApproval: content.lessons[currentLesson].examApproval,
                          watched: content.lessons[currentLesson].watched,
                          userGradeObtained: content.lessons[currentLesson].userGradeObtained,
                          msg: 'Você concluiu 100% dessa aula',
                          message: `Você concluiu esta aula. 
                            Para finalizá-la realize essa avaliação.`,
                          buttonMessage: 'Fazer avaliação da Aula'
                        }}
                      />
                    )}
                    {/* {!!Object.keys(content.exam).length && !content.examApproval && (
                      <ShowBannerExam
                        content={content.course}
                        preferences={{
                          examApproval: content.examApproval,
                          watched: content.watched,
                          userGradeObtained: content.userGradeObtained,
                        }}
                      />
                    )} */}
                    {getToken() && courseCompleted && !!Object.keys(content.exam).length && (
                      <ShowBannerExam
                        content={content.course}
                        preferences={{
                          examApproval: content.examApproval,
                          watched: content.watched,
                          userGradeObtained: content.userGradeObtained,
                          msg: 'Você concluiu 100% do curso',
                          message: examCompleted
                            ? `Você concluiu o curso.`
                            : 'Você concluiu 100% do curso. Para finalizar realize a avaliação.',
                          buttonMessage: examCompleted
                            ? 'Fazer avaliação novamente'
                            : 'Fazer avaliação'
                        }}
                      />
                    )}
                    {getToken() &&
                      content.course.certificate &&
                      courseCompleted &&
                      !!Object.keys(content.exam).length &&
                      examCompleted && (
                      <CertifiedDownload
                        className="mb-3"
                        type="button"
                        onClick={handleGetCertified}>
                          Emitir Certificado do Curso (Clique aqui para baixar)
                      </CertifiedDownload>
                    )}
                    {getToken() &&
                      content.course.certificate &&
                      courseCompleted &&
                      !Object.keys(content.exam).length && (
                      <CertifiedDownload
                        className="mb-3"
                        type="button"
                        onClick={handleGetCertified}>
                          Emitir Certificado do Curso (Clique aqui para baixar)
                      </CertifiedDownload>
                    )}
                    {/* {getToken() &&
                      content.course.certificate &&
                      (!content.mandatoryApproval || content.examApproval) &&
                      content.watched && (
                        <CertifiedDownload
                          className="mb-3"
                          type="button"
                          onClick={handleGetCertified}>
                          Emitir Certificado do Curso (Clique aqui para baixar)
                        </CertifiedDownload>
                      )} */}
                  </Col>
                  <Col xs={12} md={3}>
                    <Box style={{marginBottom: 90}}>
                      <BoxScrollOuter>
                        <BoxScrollInner>
                          <PlaylistLessons
                            content={content.lessons[currentLesson].content}
                            playlist={content.lessons}
                            setCurrentLesson={setCurrentLesson}
                          />
                        </BoxScrollInner>
                      </BoxScrollOuter>
                    </Box>
                  </Col>
                  <ButtonNext setCurrentLesson={setCurrentLesson} />
                </Row>
              ) : (
                <Title>Curso não possui aulas</Title>
              )}

            </>
          )}
          <Row>
            <Col xs={12} className="mt-5">
              {relatedContents && !!relatedContents.length && (
                <Box>
                  <Title>Veja também</Title>

                  <BoxScrollInnerRelative>
                    <RelatedContents contents={relatedContents} />
                  </BoxScrollInnerRelative>
                </Box>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Container>
  )
}

ContentCourseShow.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired
}

const AlignBtnNextContent = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BtnNextContent = styled(Button).attrs((props) => ({
  type: props.type || Link,
  color: 'primary'
}))`
  display: inline-flex;
  align-items: center;
`
export default ContentCourseShow
