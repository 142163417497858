import {getContent} from '../../../services/endpoints/contents/contents'
import {getToken} from '../../../utils/auth'
import {userWatchedTheContent} from '../utils/userWatchedTheContent'
import {loadExamUser} from '../utils/loadExamUser'

export const makeContent = async (slug) => {
  const response = await getContent(slug)
  const {data} = response
  const {userCollections} = data
  const params = {}
  if (getToken()) {
    Object.assign(params, userWatchedTheContent(userCollections))
    if (data.exam) {
      Object.assign(params, await loadExamUser(data.id))
    }
  }

  return {
    data,
    params
  }
}
