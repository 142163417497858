import {useEffect} from 'react'
// import api from '../../services/api';
import {logout} from '../../utils/auth'

const Logout = () => {
  useEffect(() => {
    (async () => {
      try {
        await logout()
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  return null
}

export default Logout
