import api from '../api'

const getEndpoints = (endpoints, params = {}) => {
  if (endpoints.indexOf('#') === -1) {
    return api.get(endpoints.replace('/v1/', '/'), {
      params: {
        ...params
      }
    })
  }
  return []
}

export {getEndpoints}
