import React from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {GuardedRoute, GuardProvider} from 'react-router-guards'
import Cookie from 'js-cookie'
import {getToken} from './utils/auth'
import {authenticator} from './services/auth'
import Pages from './pages'
import NotFound from './pages/NotFound'
import ContentExam from './pages/Contents/ContentExam'
import Profile from './pages/Profile'
import Tools from './pages/Tools'
import Search from './pages/Search/Search'
import PageErrors from './pages/Errors'
import Partners from './pages/Partners/Partners'
import PartnersList from './pages/Partners'
import ContentCourseShow from './pages/Contents/ContentCourseShow'
import ContentList from './pages/Contents/ContentList'
import Body from './components/Body'
import PageProvider from './components/Body/contexts/PageContext'
import ContentShowByTheme from './pages/Contents/ContentShowByTheme'
import Logout from './pages/Logout/logout'

const Routes = () => {
  const [loading, setLoading] = React.useState(true)
  Object.keys(Cookie.get()).forEach((item) => {
    if (item.indexOf('CognitoIdentityServiceProvider') > -1) {
      localStorage.setItem(item, Cookie.get(item))
      setTimeout(() => {
        Cookie.remove(item, {path: '/', domain: process.env.REACT_APP_DOMAIN})
      }, 500)
    }
  })

  React.useEffect(() => {
    const blackList = [
      '/perfil',
      '/certificados',
      '/conteudos-visualizados',
      '/avaliacao/',
      '/favoritos/',
      '/sair'
    ];
    (async () => {
      if (getToken() || blackList.includes(window.location.pathname)) {
        await authenticator()
      }
      setLoading(false)
    })()
  }, [])

  if (loading) {
    return null
  }

  return (
    <Router>
      <GuardProvider>
        <PageProvider>
          <Body>
            <Switch>
              <GuardedRoute path="/" exact component={Pages} />

              <GuardedRoute path="/404" component={NotFound} />
              <GuardedRoute path="/erros" component={PageErrors} />

              <GuardedRoute path="/busca" exact component={Search} />

              <GuardedRoute path="/parceiro" exact component={Partners} />
              <GuardedRoute path="/parceiros" exact component={Partners} />
              <GuardedRoute path="/parceiros/:slug" exact component={PartnersList} />

              <GuardedRoute path="/favoritos" exact component={ContentList} />
              <GuardedRoute path="/conteudos-visualizados" exact component={ContentList} />
              <GuardedRoute path="/certificados" exact component={ContentList} />
              <GuardedRoute path="/parceiro/:subcategory" exact component={ContentList} />

              {/* Categorias */}
              <GuardedRoute path="/para-voce" exact component={ContentList} />
              <GuardedRoute path="/para-voce/:subcategory" exact component={ContentList} />
              <GuardedRoute
                path="/para-voce/:subcategory/:slug"
                exact
                component={ContentShowByTheme}
              />

              <GuardedRoute path="/curso/:slug" exact component={ContentCourseShow} />

              <GuardedRoute path="/avaliacao/:id/:pathCourse" exact component={ContentExam} />

              {/* init required login */}
              <GuardedRoute path="/vitrine-de-ferramentas" exact component={Tools} />
              <GuardedRoute path="/perfil" exact component={Profile} />
              <GuardedRoute path="/sair" exact component={Logout} />
              {/* end required login */}

              <GuardedRoute path="/:slug" exact component={Pages} />
              <GuardedRoute path="*" component={NotFound} />
            </Switch>
          </Body>
        </PageProvider>
      </GuardProvider>
    </Router>
  )
}

export default Routes
