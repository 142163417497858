import api from '../../api'

export const seeNotification = (id, params = {}) => {
  return api.post(`/notifications/${id}/see`, {
    params: {
      ...params
    }
  })
}

export const unseenNotifications = (params = {}) => {
  return api.get('/unseen_notifications', {
    params: {
      ...params
    }
  })
}
