// eslint-disable-next-line consistent-return
export function createTitleTag (pathname) {
  // Página inicial
  if (!pathname.includes('quem-somos') 
  && !pathname.includes('parceiros') 
  && !pathname.includes('para-voce')
  && !pathname.includes('fale-conosco')
  && !pathname.includes('faq')) {
    return '[B]³ Educação | Página Inicial'
  }

  // Para Você
  if (pathname.includes('para-voce')) {
    if (pathname.includes('financas-pessoais')) {
      return '[B]³ Educação | Finanças Pessoais'
    }
    if (pathname.includes('entenda-o-mercado')) {
      return '[B]³ Educação | Entenda o Mercado'
    }
    if (pathname.includes('comece-a-investir')) {
      return '[B]³ Educação | Comece a Investir'
    }
    if (pathname.includes('invista-melhor')) {
      return '[B]³ Educação | Invista Melhor'
    }
    return '[B]³ Educação | Para Você'
  }

  // Parceiros
  if (pathname.includes('parceiros')) {
    if (pathname.includes('reguladores')) {
      return 'Reguladores | [B]³ Educação'
    }
    if (pathname.includes('instituicoes-financeiras')) {
      return 'Instituições Financeiras | [B]³ Educação'
    }
    if (pathname.includes('formadores-de-opiniao')) {
      return 'Formadores de Opinião | [B]³ Educação'
    }
    if (pathname.includes('instituicoes-de-ensino')) {
      return 'Instituições de Ensino | [B]³ Educação'
    }
  }

  // Sobre a B3
  if (pathname.includes('quem-somos')) {
    return '[B]³ Educação | Quem somos'
  }

  // Fale conosco
  if (pathname.includes('fale-conosco')) {
    return '[B]³ Educação | Fale Conosco'
  }

  // FAQ
  if (pathname.includes('faq')) {
    return '[B]³ Educação | Perguntas Frequentes'
  }
}