import api from '../../api'

const getSettings = (params = {}) => {
  return api.get(`/settings`, {
    params: {
      ...params
    }
  })
}

export {getSettings}
