import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import styled from 'styled-components'
import {Link} from '../../Elements'
import {nameFormat} from '../../../utils/manipulateString'
import {ButtonMobile} from '../styled'

const ProfileMenu = ({user, open, setOpen}) => {
  return (
    <li className="colapse-mobile profile">
      <ButtonMobile
        onClick={() => setOpen(() => (open === 'profile' ? false : 'profile'))}
        className={`position-relative ${open === 'profile' ? 'active' : ''}`}>
        <Link to="/perfil">
          <I className="icon-user fal fa-user-circle" title={nameFormat(user.name)} />
          {nameFormat(user.name)}
        </Link>
        {setOpen && (
          <span className="arrow" type="button">
            <i className="fas fa-angle-right" />
          </span>
        )}
      </ButtonMobile>
      <AnimateHeight duration={500} height={open === 'profile' ? 'auto' : 0}>
        <ul style={{right: 0}}>
          <li>
            <Link to="/perfil">Meu Perfil</Link>
          </li>
          <li>
            <Link to="/favoritos">Favoritos</Link>
          </li>

          <li>
            <Link to="/sair">Sair</Link>
          </li>
        </ul>
      </AnimateHeight>
    </li>
  )
}

const I = styled.i`
  font-size: 20px !important;
`

ProfileMenu.proptTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default ProfileMenu
