import React from 'react'
import PropTypes from 'prop-types'
import {RatingStarsInner, RatingStarsOuter} from './styled'

const RatingsShow = ({ratings, publicView = false}) => {
  const total = 5
  const rounded = `${Math.round((ratings / total) * 100)}%`

  return (
    publicView ? (
      <>
        <RatingStarsOuter>
          {window.innerWidth > 576 && <span className="tooltip">
            Cadastre-se grátis para votar
          </span>}
          <RatingStarsInner stars={rounded} />
        </RatingStarsOuter>
      </>
    ) : (
      <>
        <RatingStarsOuter>
          <RatingStarsInner stars={rounded} />
        </RatingStarsOuter>
      </>
    )
  )
}

RatingsShow.propTypes = {
  ratings: PropTypes.number.isRequired
}

export {RatingsShow}
