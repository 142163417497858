import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import {logout, getToken} from '../utils/auth'
// eslint-disable-next-line import/no-cycle
import {checkDetails} from '../utils/checkDetails'
import {hasProperty} from '../utils/functions'

const apiUrl = process.env.REACT_APP_API_URL
const {CancelToken} = axios
let cancel

export const apiCeps = axios.create({
  withCredentials: true,
  baseURL: `${apiUrl.replace('/v1', '')}/izap_ceps/address`
})

const api = axios.create({
  baseURL: apiUrl,
  withCredentials: !!getToken(),
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.response.use(
  (response) => {
    if (cancel) {
      cancel() // cancel request
    }

    response.cancelToken = new CancelToken(function executor (c) {
      cancel = c
    })

    return response
  },
  (error) => {
    try {
      if (error.response) {
        const status = hasProperty(error, 'response') ? error.response.status : error
        switch (status) {
          case 401:
            if (error.response.config.url.indexOf('/contents/slug') === -1) {
              checkDetails(error.response.data.detail)
            }
            break
          case 403:
            if (error.response.data['hydra:description']) {
              checkDetails(error.response.data['hydra:description'])
            } else {
              checkDetails('')
            }
            break
          case 404:
            if (error.response.config.url.indexOf('/pages/slug') !== -1) {
              window.location = `${window.location.origin}/404`
            }
            console.error(`Error ${status}`)
            break
          case 405:
          case 406:
          case 422:
          default:
            console.error(`Error ${status}`)
        }
      } else {
        logout()
      }
    } catch (e) {
      console.error('Service.api', e)
    }
    return Promise.reject(error)
  }
)

export default api
