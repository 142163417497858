import React, {useEffect} from 'react'
import {Container} from 'reactstrap'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'

const NotFound = () => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])
  return (
    <Container>
      <div className="text-center pt-5 pb-5">
        <h1 className="hide-border">Pagina não encontrada</h1>
        <p>Lamentamos, mas a página que você está procurando não existe.</p>
      </div>
    </Container>
  )
}

export default NotFound
