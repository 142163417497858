import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link, useLocation} from 'react-router-dom'
import {Col, Container, Row} from 'reactstrap'
import {useMediaQuery} from 'react-responsive'
import Helmet from 'react-helmet'
import {getCurrentUser, getToken} from '../../utils/auth'
import {useCurrentPage, useHideBreadcrumb} from './contexts/PageContext'
import {getEndpoints} from '../../services/endpoints/getEndpoints'
import {getPage} from '../../services/endpoints/cms/pages'
import {settings} from '../../config/settings'
import {Copyright, Header, HeaderContent} from './styled'
import {SearchBlock} from '../Search'
import LoadBlocks from './_partials/LoadBlocks'
import Banner from '../Banner'
import {hasProperty} from '../../utils/functions'
import Breadcrumb from '../Breadcrumb'
import MenuFooterAutomatic from '../MenuFooterAutomatic'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'
import {loadPublicImage} from '../../utils/loadPublicImage'
import api from '../../services/api'
import {decodeHtml} from '../../utils/santinize/decodeHtml'
import {createTitleTag} from '../../utils/createTitleTag'

const Body = ({children}) => {
  const {currentPage} = useCurrentPage()
  const {hideBreadcrumb} = useHideBreadcrumb()
  const isMobile = useMediaQuery({query: '(max-width: 575px)'})
  const [errorDefaultPage, setErrorDefaultPage] = useState(false)
  const [page, setPage] = useState({})
  const [containersDefault, setContainersDefault] = useState([])
  const [containers, setContainers] = useState([])

  const location = useLocation()

  const [pathname, setPathname] = useState('')
  useEffect(() => {
    setPathname(location.pathname)
  }, [location])

  useEffect(() => {
    const sendRdStation = async () => {
      const userEmail = getCurrentUser()
      try {
        await api.post(`/rd/events`, {
          eventType: 'CONVERSION',
          eventFamily: 'CDP',
          payload: {
            conversion_identifier: `Visitou a página: 
            ${process.env.REACT_APP_ACADEMY_URL}${pathname}`,
            email: userEmail.user.email
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    if (getToken()) {
      sendRdStation()
    }

    // handleWootricTag(pathname);
  }, [pathname])

  const [addNoindex, setAddNoindex] = useState(false)
  const blackListNoindex = [
    '/faq',
    '/assine',
    '/parceiro/ágora-investimentos',
    '/parceiro/b3',
    '/parceiro/bs2',
    '/parceiro/btg',
    '/parceiro/clear-corretora',
    '/parceiro/easynvest',
    '/parceiro/genial-investimentos',
    '/parceiro/guide-investimentos',
    '/parceiro/itau-asset',
    '/parceiro/modalmais',
    '/parceiro/nova-futura',
    '/parceiro/toro-investimentos',
    '/parceiro/vert-capital',
    '/parceiro/cvm',
    '/parceiro/stn',
    '/parceiro/banco-do-brasil'
  ]
  useEffect(() => {
    if (blackListNoindex.find((item) => item === location.pathname)) {
      setAddNoindex(true)
    } else {
      setAddNoindex(false)
    }

    window.scrollTo(0, 0)
  }, [location])

  const makeBlock = async (block) => {
    try {
      let blockContent = null

      if (block.block.endpoint.indexOf(',') > -1) {
        const arrRes = block.block.endpoint.replace(/ /g, '').split(',')
        const data = await Promise.all(
          arrRes.map((endpoint) => getEndpoints(decodeHtml(endpoint)))
        )
        blockContent = {
          data: data.map((item) => item.data)
        }
      } else {
        blockContent = await getEndpoints(decodeHtml(block.block.endpoint))
      }

      const componentName = block.block.component ? block.block.component.name : ''
      const {container} = block
      return {
        position: block.position || 0,
        container: container.name || null,
        componentName,
        content: blockContent.data || null,
        block: {
          id: block.block.id,
          title: block.block.title || '',
          description: block.block.description || null
        }
      }
    } catch (e) {
      return null
    }
  }

  const hasLocale = (locale) => {
    return !!containers.filter(({container}) => container === locale).length
  }

  useEffect(() => {
    (async () => {
      try {
        const sessionCommonsBlocks = JSON.parse(sessionStorage.getItem('list-all-pages'))
        if (!sessionCommonsBlocks) {
          const response = await getPage('list-all-pages')
          response.data.blockPages.forEach(async (block) => {
            const content = await makeBlock(block)
            if (content) {
              setContainersDefault((prevValue) => {
                sessionStorage.setItem('list-all-pages', JSON.stringify([...prevValue, content]))
                return [...prevValue, content]
              })
            }
          })
        } else {
          setContainersDefault(sessionCommonsBlocks)
        }
      } catch (err) {
        if (err.response && err.response.status) {
          setErrorDefaultPage(true)
        }
        console.error('Base.useEffect.defaultBlocks', err)
      }
    })()
  }, [])

  useEffect(() => {
    setPage({})
    setContainers([])
    if (currentPage) {
      (async () => {
        try {
          const enableCachedInUrls = /^\/v1\/(banners|feature|statistics)/g
          const response = await getPage(currentPage)
          setPage(response.data || {})

          response.data.blockPages.forEach(async (block) => {
            const currentBlock = JSON.parse(sessionStorage.getItem(block.block.endpoint))
            let content
            if (!currentBlock) {
              content = await makeBlock(block)
              if (enableCachedInUrls.test(decodeHtml(block.block.endpoint))) {
                sessionStorage.setItem(decodeHtml(block.block.endpoint), JSON.stringify(content))
              }
            } else {
              content = JSON.parse(sessionStorage.getItem(decodeHtml(block.block.endpoint)))
            }
            if (content) {
              setContainers((prevValue) => [...prevValue, content])
            }
          })
        } catch (err) {
          console.error('Base.useEffect.slug', err)
        }
      })()
    }
  }, [currentPage])

  if (errorDefaultPage) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{createTitleTag(window.document.URL)}</title>
      </Helmet>
      <Header className={isMobile ? 'mobile' : 'desktop'}>
        <Container>
          <HeaderContent clasName={isMobile ? 'logo-mobile' : ''}>
            <Link to="/" className="logo">
              <img
                className="img-fluid"
                src={loadPublicImage('/logo.png')}
                alt={settings.titleSite}
              />
            </Link>
            {!isMobile && <SearchBlock />}
            <LoadBlocks containers={[...containersDefault, ...containers]} locale="header" />
          </HeaderContent>
        </Container>
      </Header>

      {page && page.banner && <Banner content={page.banner} />}

      {page && page.blockPages && (
        <section className={`${page.slug}`}>
          <LoadBlocks containers={[...containersDefault, ...containers]} locale="main" />
        </section>
      )}

      <main
        className={`page-content${!page.hideTitle || hasProperty(page, 'content') ? ' pt-3' : ''}`}
        style={{
          backgroundColor: '#f7f7f7'
        }}>
        {Object.keys(page).length > 0 && (
          <Container>
            <div className="content-show">
              {!page.hideTitle && <h1>{page.title}</h1>}

              {!hideBreadcrumb && (
                <Breadcrumb contents={[{title: 'Home', url: '/'}, {title: page.title}]} />
              )}

              <LoadBlocks
                containers={[...containersDefault, ...containers]}
                locale="innerContent"
              />

              {hasProperty(page, 'content') === true && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(page.content)
                  }}
                />
              )}
            </div>
          </Container>
        )}

        {children && <div className="content">{children}</div>}
      </main>

      <section style={{backgroundColor: '#f7f7f7', padding: hasLocale('content') ? '3em 0' : 0}}>
        <LoadBlocks containers={[...containersDefault, ...containers]} locale="content" />
      </section>

      <footer className="page-footer">
        <div className="container">
          <div className="page-footer-items">
            <Row>
              <Col xs={12} lg={6}>
                <LoadBlocks containers={[...containersDefault, ...containers]} locale="footer" />
              </Col>
              <Col xs={12} lg={6}>
                <MenuFooterAutomatic />
              </Col>
            </Row>
          </div>

          <Copyright>{settings.copyright}</Copyright>
        </div>
      </footer>
    </>
  )
}

Body.propTypes = {
  children: PropTypes.node.isRequired
}
export default Body
