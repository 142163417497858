export const settings = {
  titleSite: '[B]³ Educação - Hub de Educação Financeira da B3',
  perPage: 12,
  itemsCarousel: 4,
  copyright: '©B3 Educação 2020',
  pageBase: 'list-all-pages',
  home: 'home-logada',
  enableImageBuild: true,
  minPercentageToView: 80,
  pagination: {
    pageRangeDisplayed: 3,
    hideDisabled: true,
    hideFirstLastPages: false,
    hideNavigation: true,
    linkClassFirst: 'arrow first',
    linkClassPrev: 'arrow prev',
    linkClassNext: 'arrow next',
    linkClassLast: 'arrow last'
  },
  codes: {
    hotjar: {
      hjid: 1857620,
      hjsv: 6
    },
    gtm: {
      uaId: 'UA-161429558-2',
      gtmId: 'GTM-MQ4DM2L',
      awId: 'AW-615809848'
    },
    linkedin: {
      partnerId: '2575305',
      conversionId: ''
    },
    pixel: {
      id: '224101575526074'
    }
  }
}
