import {Auth} from 'aws-amplify'
import {getMe} from './endpoints/general/currentUser'
import {getEndpoints} from './endpoints/getEndpoints'
import {
  // createCookie,
  getAsyncCurrentUser,
  getCurrentUser,
  logout,
  setAsyncCurrentUser
} from '../utils/auth'

export const testPassword = async (email, pass) => {
  try {
    return Auth.signIn(email, pass)
  } catch (error) {
    console.error('AUTH.signIn', error)
    return error
  }
}

const load = async () => {
  try {
    // await Auth.currentSession();
    // createCookie(auth);
    const response = await getMe()

    if (
      !getCurrentUser() ||
      (getCurrentUser() && response.data.user['@id'] !== getCurrentUser().user['@id'])
    ) {
      const res = await getEndpoints(response.data.user['@id'])

      await setAsyncCurrentUser({...response.data, profile: res.data})
      await getAsyncCurrentUser()
    }
  } catch (e) {
    console.error('authenticator.load error', e)
    logout()
  }
}

export const authenticator = async () => {
  await load()
}
