import styled from 'styled-components'

export const Breadcrumb = styled.ul`
  list-style: none;
  padding: 3px 0 30px;
  margin: 0 0 0px;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
`

export const BreadcrumbItem = styled.li`
  font-size: 12px;
  &,
  a {
    color: #002c63;
    i {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  a {
    text-decoration: none;
  }
  .fa-angle-right {
    margin: 0 5px;
  }
  > span {
    color: #666e7a;
  }
`
