import styled from 'styled-components'

export const Title = styled.h2`
  text-align: center;
`

export const SliderStyled = styled.div`
  margin: 0 30px;
  @media (min-width: 992px) {
    margin: 0 30px;
  }
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Partner = styled.div`
  display: flex;
  padding: 10px 15px;
`

export const Image = styled.img`
  max-width: 100%;
`

export const PrevArrowStyled = styled.i`
  font-size: 40px;
  color: #002c63;
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
  cursor: pointer;
  left: -30px;
`

export const NextArrowStyled = styled(PrevArrowStyled)`
  left: inherit;
  right: -30px;
`
