import {loadPublicImage} from './loadPublicImage'

export const getImageHeader = (content) => {
  if (content.thumb && !content.cover) {
    return content.thumb.url
  }

  if (content.cover) {
    return content.cover.url
  }

  return loadPublicImage('/sharedImage.jpg')
}
