export const mapInitialValues = (groupsFields) => {
  const values = {}

  groupsFields.forEach((group) => {
    group.fields.forEach((field) => {
      Object.assign(values, {
        [field.name]: field.value
      })
    })
  })

  return values
}
