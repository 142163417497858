import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import {getTitle} from '../../Contents/Enum/categories'
import {ButtonMobile} from '../styled'

const ThemesMenu = ({contents, screenType, setOpen, open}) => {
  if (!contents && !contents.length) {
    return null
  }

  return (
    <>
      {!screenType && (
        <li>
          <Link to="/para-voce">Para você</Link>
          <ul>
            {contents.map((content) => (
              <li key={content['@id']}>
                <Link to={`/para-voce/${content.slug}`}>{content.title}</Link>
              </li>
            ))}
          </ul>
        </li>
      )}
      {screenType === 'mobile' && (
        <li className="colapse-mobile">
          <ButtonMobile
            onClick={() => setOpen(() => (open !== 'theme' ? 'theme' : false))}
            className={`position-relative ${open === 'theme' ? 'active' : ''}`}>
            <Link to="/para-voce">{getTitle('para-voce')}</Link>
            {setOpen && (
              <span className="arrow" type="button">
                <i className="fas fa-angle-right" />
              </span>
            )}
          </ButtonMobile>

          <ul>
            <AnimateHeight duration={500} height={open === 'theme' ? 'auto' : 0}>
              {contents.map((content) => (
                <li key={content['@id']}>
                  <Link to={`/para-voce/${content.slug}`}>{content.title}</Link>
                </li>
              ))}
            </AnimateHeight>
          </ul>
        </li>
      )}
    </>
  )
}

ThemesMenu.propTypes = {
  contents: PropTypes.array.isRequired,
  screenType: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

export default ThemesMenu
