import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {hasExternalLink} from '../../utils/utilities'
import {BannerImage, BannerMask, Description, MetaInfo, MetaInfoContent, Title} from './styled'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'
import {Button} from '../Elements'

const BannerItem = ({banner}) => {
  const MyLink = (props) => {
    const {children} = props

    if (hasExternalLink(banner.link)) {
      return (
        <a href={banner.link || '/'} target={banner.action || '_self'} rel="noopener noreferrer">
          {props.children}
        </a>
      )
    }

    return <Link to={banner.link}>{children}</Link>
  }

  return (
    <MyLink>
      <BannerMask>
        {!!banner.image && (
          <BannerImage
            src={window.innerWidth < 576 && banner.mobileImage ? banner.mobileImage : banner.image}
            alt={banner.title}
          />
        )}
      </BannerMask>
      <MetaInfo>
        <div className="container">
          <MetaInfoContent>
            <Title>{banner.title}</Title>
            <Description dangerouslySetInnerHTML={{__html: sanitizeHtml(banner.description)}} />
            {banner.textButton && banner.link && (
              <Button color="primary-light">{banner.textButton}</Button>
            )}
          </MetaInfoContent>
        </div>
      </MetaInfo>
    </MyLink>
  )
}

BannerItem.propTypes = {
  banner: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default memo(BannerItem)
