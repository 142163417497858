import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Col, Row} from 'reactstrap'
import {Link, useHistory} from 'react-router-dom'
import Playlist from '../_partials/Playlist'
import {getToken} from '../../../utils/auth'
import {hasProperty} from '../../../utils/functions'
import Attachments from '../../../components/Contents/_partials/Attachments'
import RelatedContents from '../_partials/RelatedContents'
import ContentType from './ContentType'
import {ContentLoggedOut} from '../../../components/Contents/Types/ContentLoggedOut'
import ShowBannerExam from '../_partials/ShowBannerExam'
import {Button} from '../../../components/Elements'
import {
  getCertifiedDownload,
  setContentAccess
} from '../../../services/endpoints/contents/contents'
import {CertifiedDownload} from '../../../components/Contents/_partials/styled'
import {makeUrlContent} from '../data/makeUrlContent'
import {decodeHtml} from '../../../utils/santinize/decodeHtml'

const ContentShowByType = ({
  content,
  relatedContents,
  preferences,
  playlist,
  breadcrumb,
  updatePreferences
}) => {
  const history = useHistory()
  const {location} = history
  useEffect(() => {
    (async () => {
      if (getToken()) {
        await setContentAccess({
          content: content['@id']
        })
      }
    })()
  }, [])
  if (!getToken() && !content.free) {
    return <ContentLoggedOut content={content} />
  }

  const nextContent = (() => {
    const index = playlist.flatMap((item, idx) => (item.id === content.id ? idx : []))
    if (index.length && playlist[index[0] + 1]) {
      return playlist[index[0] + 1]
    }
    return {}
  })()
  const handleGetCertified = async (id) => {
    try {
      await getCertifiedDownload(id)
    } catch (e) {
      alert.show('Ocorreu um erro ao baixar o certificado')
      console.error('ContentItem.handleGetCertified', e)
    }
  }

  console.log(content)
  return (
    <>
      <Row>
        <Col xs={12} md={content && content['@type'] === 'Curso' ? 9 : 12}>
          <ContentType
            content={content}
            playlist={playlist}
            watched={preferences.watched}
            breadcrumb={breadcrumb}
            updatePreferences={updatePreferences}
          />
          {hasProperty(content, 'attachments') && !!content.attachments.length && (
            <Attachments attachments={content.attachments} />
          )}
          {!!Object.keys(preferences.exam).length && !preferences.examApproval && (
            <ShowBannerExam content={content} preferences={preferences} />
          )}
          {content.certificate && (!preferences.mandatoryApproval || preferences.examApproval) && (
            <CertifiedDownload className="mb-5" onClick={() => handleGetCertified(content.id)}>
              Emitir Certificado (Clique aqui para baixar)
            </CertifiedDownload>
          )}
          {!!Object.keys(nextContent).length && (
            <AlignBtnNextContent>
              <BtnNextContent
                to={{
                  pathname: makeUrlContent(nextContent),
                  state: {
                    playlist: location.state
                      ? location.state.playlist || ''
                      : location.search || ''
                  }
                }}>
                Próximo conteúdo
              </BtnNextContent>
            </AlignBtnNextContent>
          )}
        </Col>
        <Col xs={12} md={3}>
          {!!playlist.length && content && content['@type'] === 'Curso' && (
            <Box style={{marginBottom: 90}}>
              <Title>{decodeHtml(content.title)}</Title>
              <BoxScrollOuter>
                <BoxScrollInner>
                  <Playlist content={content} playlist={playlist} />
                </BoxScrollInner>
              </BoxScrollOuter>
            </Box>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mt-5">
          {!!relatedContents.length && (
            <Box>
              <Title>Veja também</Title>
              <BoxScrollInnerRelative>
                <RelatedContents contents={relatedContents} />
              </BoxScrollInnerRelative>
            </Box>
          )}
        </Col>
      </Row>
    </>
  )
}

const Box = styled.div`
  margin-bottom: 30px;
  position: relative;
`

const BoxScrollOuter = styled.div`
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #002c63;
  }
  &::before {
    content: '';
    padding-bottom: ${({relatedContent}) => (relatedContent ? '149%' : '165.5%')};
    display: block;
  }
`

const BoxScrollInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
`
const BoxScrollInnerRelative = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 5px;
  .slick-track {
    margin: 0 !important;
  }
`

const Title = styled.h2`
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  color: #004685;
  margin-bottom: 0;
`

const AlignBtnNextContent = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BtnNextContent = styled(Button).attrs({type: Link, color: 'primary'})`
  display: inline-flex;
  align-items: center;
`

ContentShowByType.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  preferences: PropTypes.oneOfType([PropTypes.object]).isRequired,
  relatedContents: PropTypes.array.isRequired,
  playlist: PropTypes.array.isRequired
}
export default ContentShowByType
