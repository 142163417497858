import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import queryString from 'query-string'
import Pagination from 'react-js-pagination'
import Spinner from 'reactstrap/es/Spinner'
import {
  getKeys,
  getType,
  getTitle,
  getFilterCategory
} from '../../components/Contents/Enum/categories'
import {settings} from '../../config/settings'
import {hasProperty} from '../../utils/functions'
import {getContents} from '../../services/endpoints/contents/contents'
import Breadcrumb from '../../components/Breadcrumb'
import {ContentItem} from '../../components/Contents'
import {getEndpoints} from '../../services/endpoints/getEndpoints'
import FilterBar from './_partials/FilterBar'
import {getTitleByType, getTypeBySlug} from '../../components/Contents/Enum/types'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'

const ContentList = ({location, history, match}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])

  const [loading, setLoading] = useState(true)
  const search = queryString.parse(location.search)
  const {format = '', parceiro = ''} = search
  const category = location.pathname.split('/')[1]
  const {subcategory, type} = match.params
  const [subCategory, setSubCategory] = useState({})
  const [pageNotFound, setPageNotFound] = useState(false)
  const [playlist, setPlaylist] = useState({})

  const {perPage} = settings
  const [totalItens, setTotalItens] = useState(0)
  const [contents, setContents] = useState([])
  const currentPage = hasProperty(search, 'page') ? parseInt(search.page, 10) : 1

  const typeContent = (() => {
    return getTypeBySlug(category) || ''
  })()

  const handlePageChange = (pageNumber) => {
    let filter = queryString.parse(window.location.search)
    if (filter && pageNumber) {
      filter = Object.assign(filter, {
        page: pageNumber
      })
    }
    history.push({
      search: queryString.stringify(filter)
    })
  }

  useEffect(() => {
    (async () => {
      const dataSubCategory = {}
      let urlEndpoint = '/contents'
      const parameters = {
        perPage,
        page: currentPage
      }

      switch (typeContent) {
        case 'Free':
        case 'Saved':
          urlEndpoint = '/current_customer_favorite_contents'
          break
        case 'ViewedContents':
          urlEndpoint = '/current_customer_accessed_contents'
          break
        case 'Certificates':
        case 'FinishedCourses':
          urlEndpoint = '/current_customer_certified_contents'

          break
        case 'Contents':
          if (type) {
            Object.assign(parameters, {
              resourceType: getTypeBySlug(type)
            })
          }
          break
        default:
          Object.assign(parameters, {})
          break
      }

      if (format) {
        Object.assign(parameters, {
          resourceType: format
        })
      }

      if (location.pathname === '/parceiro/cvm' ||
      location.pathname === '/certificados' ||
      location.pathname === '/favoritos' ||
      location.pathname === '/conteudos-visualizados') {
        Object.assign(parameters, { partnerPrivate: true })
      } else {
        Object.assign(parameters, { partnerPrivate: false })
      }

      if (getKeys().includes(category)) {
        Object.assign(parameters, {
          [`exists[${getType(category)}]`]: true
        })

        if (subcategory || parceiro) {
          let endpointSubCategory = ''
          const paramSubCategory = {}
          if (subcategory && getType(category) === 'themes') {
            endpointSubCategory = `/v1/themes/slug/${subcategory}`
            Object.assign(paramSubCategory, {
              slug: subcategory
            })
          } else if (subcategory && getType(category) === 'partners') {
            endpointSubCategory = `/v1/partners/slug/${subcategory}`
            Object.assign(paramSubCategory, {
              slug: subcategory
            })
          }

          try {
            const responseSubCategory = await getEndpoints(endpointSubCategory, paramSubCategory)
            const dataSub = responseSubCategory.data || {}
            Object.assign(dataSubCategory, dataSub)
            if (getType(category) === 'themes' && parceiro) {
              try {
                const resPartner = await getEndpoints(`/v1/partners/slug/${parceiro}`)
                const dataPartner = resPartner.data || {}
                Object.assign(parameters, {
                  partner: dataPartner.id
                })
              } catch (e) {
                console.error('ContentList.filterParceiro', e)
              }
            }

            Object.assign(parameters, {
              [getFilterCategory(category)]: dataSub.id
            })
          } catch (e) {
            setPageNotFound(true)
            console.error('ContentList.loadSubCategory', e)
          }
        }
      }

      try {
        const response = await getContents(urlEndpoint, parameters)
        const {data} = response
        setSubCategory(dataSubCategory)
        setTotalItens(data['hydra:totalItems'] || 0)

        // if (typeContent === 'FinishedCourses') {
        //   const contentsFilted = data['hydra:member'].map((content) => ({
        //     ...content.certifiedContents,
        //   }));
        //   setContents(Object.values(contentsFilted[0]));
        if (typeContent === 'Certificates') {
          setContents(data.certifiedContents || [])
          setTotalItens(data.totalItems || 0)
        } else if (typeContent === 'Saved') {
          setContents(data['hydra:member'] || [])
          setTotalItens(data['hydra:totalItems'] || 0)
        } else if (typeContent === 'Saved') {
          setContents(data['hydra:member'] || [])
          setTotalItens(data['hydra:totalItems'] || 0)
        } else if (typeContent === 'ViewedContents') {
          // const contentsFilted = data['hydra:member'].map((content) => ({
          //   ...content.contents,
          // }));
          // setContents(Object.values(contentsFilted[0]));
          setContents(data['hydra:member'] || [])
          setTotalItens(data['hydra:totalItems'] || 0)
        } else {
          setContents(data['hydra:member'] || [])
          setTotalItens(data['hydra:totalItems'] || 0)
        }

        setPlaylist(`${urlEndpoint}?${queryString.stringify(parameters)}`)
        setLoading(false)
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
      } catch (e) {
        console.error('ContentList.loadContents', e)
      }
    })()

    return () => {
      setLoading(true)
    }
  }, [category, typeContent, currentPage, perPage, subcategory, type, format, parceiro])

  const Title = () => {
    if (Object.keys(subCategory).length) {
      return <h1>{subCategory.title || subCategory.name}</h1>
    }

    switch (getTypeBySlug(category)) {
      case 'Saved':
      case 'ViewedContents':
      case 'Certificates':
      case 'FinishedCourses':
        return <h1>{getTitleByType(getTypeBySlug(category))}</h1>
      case 'Contents':
        if (type) {
          return <h1>{getTitleByType(getTypeBySlug(type))}</h1>
        }
        return <h1>{getTitleByType(getTypeBySlug(category))}</h1>
      default:
        return <h1>{getTitle(category)}</h1>
    }
  }
  const Description = () => {
    if (Object.keys(subCategory).length) {
      return (
        <p
          className="content-text"
          dangerouslySetInnerHTML={{__html: sanitizeHtml(subCategory.description)}}
        />
      )
    }
    return null
  }

  const getBreadcrumb = () => {
    if (Object.keys(subCategory).length) {
      return [
        {title: getTitle(category), url: `/${category}`},
        {title: subCategory.title || subCategory.name}
      ]
    }

    switch (getTypeBySlug(category)) {
      case 'Saved':
      case 'ViewedContents':
      case 'Certificates':
      case 'FinishedCourses':
        return [{title: getTitleByType(getTypeBySlug(category))}]
      case 'Contents':
        if (type) {
          return [
            {title: getTitleByType(getTypeBySlug(category)), url: `/${category}`},
            {title: getTitleByType(getTypeBySlug(type))}
          ]
        }
        return [{title: getTitleByType(getTypeBySlug(category))}]
      default:
        return [{title: getTitle(category)}]
    }
  }

  if (pageNotFound) {
    history.push('/404')
  }

  return (
    <Container className="pb-1">
      {!loading && (
        <>
          <Title />

          <Breadcrumb contents={getBreadcrumb()} />
          <Description />
          {typeContent !== 'Certificates' && <FilterBar by={category} />}
        </>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          {contents.length ? (
            contents.map((content) => (
              <Col key={content['@id']} xs={12} sm={6} md={4} lg={3}>
                <ContentItem
                  content={
                    typeContent === 'FinishedCourses' || typeContent === 'Certificates'
                      ? content.content
                      : content
                  }
                  typeContent={typeContent}
                  showFavoriteRemove={typeContent === 'Saved'}
                  settings={{
                    breadcrumb: getBreadcrumb(),
                    description: {
                      truncate: 3
                    }
                  }}
                  certified={
                    content['@type'] === 'CertifiedContent'
                      ? {
                        certifiedId: content.id,
                        concludedAt: content.concludedAt,
                        maxGrade: content.maxGrade,
                        progress: content.progress
                      }
                      : null
                  }
                  playlist={playlist}
                />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <p>Nenhum registro encontrado até o momento...</p>
            </Col>
          )}
        </Row>
      )}

      {contents.length > 0 && totalItens > perPage && (
        <Pagination
          {...settings.pagination}
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={totalItens}
          onChange={handlePageChange}
        />
      )}
    </Container>
  )
}

export default ContentList
