import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'
import {useHistory} from 'react-router-dom'
import {useAlert} from 'react-alert'
import {useMediaQuery} from 'react-responsive'
import moment from 'moment'
import {getToken} from '../../../utils/auth'
import {sanitizeHtml} from '../../../utils/santinize/sanitizeHtml'
import {
  Box,
  ButtonRemoveFav,
  Category,
  CertifiedDownload,
  CertifiedProgress,
  CertifiedProgressBar,
  CertifiedProgressValue,
  CertifiedRow,
  CertifiedTitle,
  Description,
  FreeContent,
  Image,
  Item,
  LinkImage,
  MetaInfo,
  Partner,
  TagsButtons,
  Title,
  WatchedContent
} from './styled'
import {hasProperty} from '../../../utils/functions'
import {getTitleByType, getType} from '../Enum/types'
import Collections from './Collections'
import {getCertifiedDownload, postFavorite} from '../../../services/endpoints/contents/contents'
import {getImageContentItem} from '../../../utils/buildImageUrl'
import {loadPublicImage} from '../../../utils/loadPublicImage'
import {makeUrlContent} from '../../../pages/Contents/data/makeUrlContent'
import {decodeHtml} from '../../../utils/santinize/decodeHtml'

const ContentItem = ({
  content,
  settings,
  playlist,
  showFavoriteRemove,
  addClass,
  certified = {}
}) => {
  const alert = useAlert()
  const type = content['@type']
  const history = useHistory()
  const isMobile = useMediaQuery({query: '(max-width: 575px)'})

  const watched = (() => {
    const userCollectionView =
      (hasProperty(content, 'userCollections') &&
        content.userCollections.filter((item) => item['@type'] === 'UserCollectionView')[0]) ||
      {}
    if (hasProperty(userCollectionView, 'viewed')) {
      return userCollectionView.viewed
    }
    if (certified && Object.keys(certified).length > 0) {
      return true
    }
    return false
  })()

  const getProperty = (field, attr, defaultValue) => {
    if (settings && hasProperty(settings, field) && hasProperty(settings[field], attr)) {
      return settings[field][attr]
    }
    return defaultValue
  }

  const buildUrl = () => {
    if (playlist) {
      return {
        pathname: makeUrlContent(content),
        state: {
          playlist,
          breadcrumb: hasProperty(settings, 'breadcrumb') ? settings.breadcrumb : []
        }
      }
    }

    return makeUrlContent(content)
  }
  const urlContent = buildUrl()

  const BuildImage = () => {
    const image = content.thumb

    if (image) {
      return <Image src={getImageContentItem(image.url)} alt={content.title} />
    }
    return <Image src={loadPublicImage('/no-course.jpg')} alt={content.title} />
  }

  const removeFavorite = async () => {
    try {
      await postFavorite({
        content: content['@id']
      })
      history.go()
    } catch (e) {
      alert.show(`Ocorreu um erro excluir o conteúdo\n${content.title}`)
    }
  }

  const handleGetCertified = async () => {
    try {
      await getCertifiedDownload(certified.certifiedId)
    } catch (e) {
      alert.show('Ocorreu um erro ao baixar o certificado')
      console.error('ContentItem.handleGetCertified', e)
    }
  }

  return (
    <Box showFavoriteRemove={showFavoriteRemove}>
      {showFavoriteRemove && (
        <ButtonRemoveFav onClick={removeFavorite}>
          <i className="fal fa-times" />
        </ButtonRemoveFav>
      )}
      <Item
        noHover={hasProperty(settings, 'noHover') ? settings.noHover : false}
        className={addClass}>
        <LinkImage to={urlContent}>
          {content.free && <FreeContent>Gratuito</FreeContent>}
          {getToken() && watched && (
            <WatchedContent title="Conteúdo visto">Visualizado</WatchedContent>
          )}
          <BuildImage />
        </LinkImage>

        <MetaInfo>
          {!getProperty('collections', 'hide', false) && (
            <Collections publicView={false} content={content} showFavoriteRemove={showFavoriteRemove} insideContentItem />
          )}

          <TagsButtons>
            {!getProperty('category', 'hide', false) && getType(type) && (
              <Category to={`/para-voce/${content.theme.slug}?format=${type}`}>
                {getTitleByType(type)}
              </Category>
            )}

            {!getProperty('partner', 'hide', false) && content.partner && (
              <Partner to={`/parceiro/${content.partner.slug}`}>{content.partner.name}</Partner>
            )}
          </TagsButtons>

          {!getProperty('title', 'hide', false) && (
            <Title
              to={urlContent}
              height={getProperty('title', 'truncate', 3)}
              disableheight={getProperty('title', 'disableHeight', false).toString()}>
              <Truncate lines={getProperty('title', 'truncate', 3)}>
                {decodeHtml(content.title)}
              </Truncate>
            </Title>
          )}

          {!getProperty('description', 'hide', false) && (
            <Description
              height={getProperty('description', 'truncate', 2)}
              disableheight={getProperty('description', 'disableHeight', false).toString()}>
              <Truncate lines={getProperty('description', 'truncate', 2)}>
                {content.summary && (
                  <div dangerouslySetInnerHTML={{__html: sanitizeHtml(content.summary)}} />
                )}
              </Truncate>
            </Description>
          )}
          {certified && certified.progress && !!Object.keys(certified).length && (
            <CertifiedRow>
              <CertifiedProgress>
                <CertifiedTitle>Progresso</CertifiedTitle>
                <CertifiedProgressBar width={certified.progress} />
                <CertifiedProgressValue>{certified.progress}%</CertifiedProgressValue>
              </CertifiedProgress>
              <CertifiedTitle>
                Conclusão: {moment(certified.concludedAt, 'YYYY MM DD').format('L')}
              </CertifiedTitle>
              {!!certified && !!certified.maxGrade ? (
                <CertifiedTitle>Nota máxima: {certified.maxGrade}</CertifiedTitle>
              ) : (
                <span className="fake-data" />
              )}
              <CertifiedDownload onClick={handleGetCertified}>
                Baixar Certificado
                <i className="far fa-download" />
              </CertifiedDownload>
            </CertifiedRow>
          )}
        </MetaInfo>
      </Item>
    </Box>
  )
}

ContentItem.propTypes = {
  content: PropTypes.object.isRequired,
  settings: PropTypes.object,
  showFavoriteRemove: PropTypes.bool,
  playlist: PropTypes.string,
  addClass: PropTypes.string,
  certified: PropTypes.object
}

export {ContentItem}
