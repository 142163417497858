import React from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row} from 'reactstrap'
import {Box, Description, Icon, Info, Item, MetaInfo, Number, Title} from './styled'
import {sanitizeHtml} from '../../utils/santinize/sanitizeHtml'
import {formatNumber} from '../../utils/functions'

const BlockHubEducacao = ({content, block}) => {
  const data = {
    ...content[0],
    ...content[1]
  }

  return (
    <Box>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={12} lg={4} xl={5} className="mb-3 mb-lg-0">
            <Title>{block.title}</Title>
            <Description dangerouslySetInnerHTML={{__html: sanitizeHtml(block.description)}} />
          </Col>
          <Col xs={12} sm={12} lg={8} xl={7}>
            <div>
              <Row>
                <Col xs={12} sm={4}>
                  <Item>
                    <MetaInfo>
                      <Icon className="ico-user-circle" />
                      <Number>{formatNumber(data.totalUsers || 0)}</Number>
                      <Info>Usuários Cadastrados</Info>
                    </MetaInfo>
                  </Item>
                </Col>
                <Col xs={12} sm={4}>
                  <Item>
                    <MetaInfo>
                      <Icon className="ico-notebook" />
                      <Number>{formatNumber(data.contentQuantity)}</Number>
                      <Info>Conteúdos</Info>
                    </MetaInfo>
                  </Item>
                </Col>
                <Col xs={12} sm={4}>
                  <Item>
                    <MetaInfo>
                      <Icon className="ico-theme" />
                      <Number>{formatNumber(data.activeCourses)}</Number>
                      <Info>Cursos</Info>
                    </MetaInfo>
                  </Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Box>
  )
}

BlockHubEducacao.propTypes = {
  content: PropTypes.any.isRequired,
  block: PropTypes.oneOfType([PropTypes.object]).isRequired
}
export default BlockHubEducacao
