import React from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Row} from 'reactstrap'
import {Box, Feature, FeatureBody, Icon, Image, Title} from './styled'
import {getImageFeatures} from '../../utils/buildImageUrl'
import {Link} from '../Elements'

const FeatureGroupsInfors = ({content}) => {
  const features = content['hydra:member'] || []
  return (
    <Box>
      <Container>
        <Row>
          {features.map((feature) => (
            <Col xs={12} sm={6} md={6} lg={3} xl={3} key={feature.id} className="mb-3">
              <Link to={feature.link} action="_self">
                <Feature>
                  <FeatureBody>
                    {feature.image ? (
                      <Image
                        src={getImageFeatures(feature.image.url)}
                        alt={feature.title}
                        title={feature.title}
                      />
                    ) : (
                      <Icon className={feature.icon} />
                    )}
                    <Title>{feature.title}</Title>
                  </FeatureBody>
                </Feature>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </Box>
  )
}

FeatureGroupsInfors.propTypes = {
  content: PropTypes.object.isRequired
}
export default FeatureGroupsInfors
