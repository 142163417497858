import toSlugCase from 'to-slug-case'
import {getType} from '../../../components/Contents/Enum/types'

export const makeUrlCategory = (history, content) => {
  const type = content['@type']
  if (type === 'Course') {
    return `/conteudos/${toSlugCase(getType(type))}?format=${type}`
  }
  if (history.location.pathname.indexOf('para-voce') >= 0) {
    return `${history.location.pathname}?format=${type}`
  }
  if (content.partner) {
    return `/parceiro/${content.partner.slug}?format=${type}`
  }
  return `/conteudos/${toSlugCase(getType(type))}`
}

export const makeUrlContent = (content) => {
  // eslint-disable-next-line no-nested-ternary
  return content['@type'] === 'Course'
    ? content.slug
      ? `/curso/${content.slug}`
      : null
    : `/para-voce/${content.theme.slug}/${content.slug}`
}
