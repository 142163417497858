import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import styled from 'styled-components'
import {ContentItem} from '../../../components/Contents'
import {CardSpape} from '../../../components/Banner/styled'

import {
  PrevArrowStyled,
  NextArrowStyled
} from '../../../components/Contents/Blocks/BlockContentListStyled'

const RelatedContents = ({contents}) => {
  if (!contents.length) {
    return null 
  }

  const PrevArrow = ({onClick}) => {
    return <PrevArrowStyled className="fal fa-angle-left" onClick={onClick} />
  }

  const NextArrow = ({onClick}) => {
    return <NextArrowStyled className="fal fa-angle-right" onClick={onClick} />
  }
  const settings = {
    dots: false,
    infinite: false,
    arrow: true,
    speed: 500,
    draggable: false,
    fade: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <SlideContainer>
      <Slider {...settings}>
        {contents.map(({id, relatedContent}) => (
          <CardSpape key={`/v1/related_contents/${id}`}>
            <ContentItem
              content={relatedContent}
              settings={{
                title: {
                  truncate: 3,
                  disableHeight: false
                },
                collections: {
                  hide: true
                },
                description: {
                  hide: true
                }
              }}
            />
          </CardSpape>
        ))}
      </Slider>
    </SlideContainer>
  )
}

const SlideContainer = styled.div`
  & .slick-track {
    margin-left: 0 !important;
  }
`

RelatedContents.propTypes = {
  contents: PropTypes.array.isRequired
}
export default RelatedContents
