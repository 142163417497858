import {hasProperty} from '../../../utils/functions'

const Categories = {
  'para-sua-empresa': {
    title: 'Para sua empresa',
    type: 'trails',
    filter: 'trails',
    params: {
      title: 'title',
      description: 'description',
      image: null
    }
  },
  parceiros: {
    title: 'Parceiros',
    type: 'partners',
    filter: 'partner',
    params: {
      title: 'name',
      description: 'description',
      image: 'image'
    }
  },
  parceiro: {
    title: 'Parceiros',
    type: 'partners',
    filter: 'partner',
    params: {
      title: 'name',
      description: 'description',
      image: 'image'
    }
  },
  'para-voce': {
    title: 'Para você',
    type: 'themes',
    filter: 'theme',
    params: {
      title: 'title',
      description: 'description',
      image: null
    }
  },
  // filtro de parceiros para pagina de visualizados e favortitados
  'conteudos-visualizados': {
    title: 'Conteúdos visualizados',
    type: 'themes',
    filter: 'theme',
    params: {
      title: 'title',
      description: 'description',
      image: null
    }
  },
  favoritos: {
    title: 'favoritos',
    type: 'themes',
    filter: 'theme',
    params: {
      title: 'title',
      description: 'description',
      image: null
    }
  },

  certificados: {
    title: 'certificados',
    type: 'themes',
    filter: 'theme',
    params: {
      title: 'title',
      description: 'description',
      image: null
    }
  }
}

export const getTitle = (key) => {
  return hasProperty(Categories, key) ? Categories[key].title : ''
}

export const getType = (key) => {
  return hasProperty(Categories, key) ? Categories[key].type : ''
}

export const getFilterCategory = (key) => {
  return hasProperty(Categories, key) ? Categories[key].filter : ''
}

export const getParamsCategory = (key) => {
  return hasProperty(Categories, key) ? Categories[key].params : null
}

export const getKeys = () => {
  return Object.keys(Categories).map((key) => key)
}
