import IMask from 'imask'

export const hasProperty = (source, param) => {
  if (!source) {
    return false 
  }
  return Object.prototype.hasOwnProperty.call(source, param)
}

export const priceFormat = (number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(number)
}

export const formatNumber = (value) => {
  return IMask.createMask({
    mask: Number, // enable number mask

    // other options are optional with defaults below
    scale: 2, // digits after point, 0 for integers
    signed: true, // disallow negative
    thousandsSeparator: '.', // any single char
    padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
    normalizeZeros: false, // appends or removes zeros at ends
    radix: ',', // fractional delimiter

    // additional number interval options (e.g.)
    min: -99999999999999,
    max: 99999999999999
  }).resolve(value.toString())
}
