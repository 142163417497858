import React, { useState } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import {decodeHtml} from '../../../utils/santinize/decodeHtml'
import {getTitleByType} from '../../../components/Contents/Enum/types'
import {getImagePlaylistItem} from '../../../utils/buildImageUrl'
import {Image, Item, MetaInfo, Title, Type, Watched} from './PlaylistStyled'
import {loadPublicImage} from '../../../utils/loadPublicImage'
import {getToken} from '../../../utils/auth'
import { SignModal } from '../../../components/Modal'

ReactModal.setAppElement('#root')

const PlaylistLessons = ({content, isFree = false, playlist, setCurrentLesson}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const changeContent = (index) => {
    if (isFree && index > 0) {
      setModalOpen(true)
    } else {
      setCurrentLesson(index)
    }
  }

  return (
    <>
      <SignModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} />
      {playlist.map(({content: item, ...params}, index) => (
        <>
          <Item
            as="div"
            key={item['@id']}
            className={content.id === item.id ? 'active' : ''}
            onClick={() => changeContent(index)}>
            {isFree && window.innerWidth > 576 && index > 0 && <span className="tooltip">
                Cadastre-se grátis para assistir
            </span>}
            {item.thumb ? (
              <Image>

                {isFree && index > 0 ? (
                  <>
                    <div className="underImage">
                      <img 
                        src={getImagePlaylistItem(item.thumb.url)} 
                        alt={item.title} 
                      />
                    </div>
                  </>
                ) : (
                  <img 
                    src={getImagePlaylistItem(item.thumb.url)} 
                    alt={item.title} 
                  />
                )}
              </Image>
            ) : (
              <Image>
                <img 
                  src={loadPublicImage('/no-course.jpg')} 
                  alt={item.title} 
                />
              </Image>
            )}
            {getToken() && params.watched && (
              <Watched className="far fa-check" title="Conteúdo já visto" />
            )}
            <MetaInfo>
              <Title>{decodeHtml(item.title)}</Title>
              <Type>{getTitleByType(item['@type'])}</Type>
            </MetaInfo>
          </Item>
        </>
      ))}
    </>
  )
}

PlaylistLessons.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  playlist: PropTypes.array.isRequired,
  setCurrentLesson: PropTypes.func.isRequired
}
export default PlaylistLessons