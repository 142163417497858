/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Favorite} from './Favorite'
import {RatingsShow} from './RatingsShow'
import {Ratings} from './Ratings'
import {getToken} from '../../../utils/auth'
import ShareButton from './ShareButton'

const Collections = ({
  content,
  hasShare,
  enabledRatings,
  watched,
  showWatched,
  showFavoriteRemove,
  insideContentItem,
  publicView = false
}) => {
  return (
    <Row insideContentItem={insideContentItem}>
      {enabledRatings && getToken() ? (
        <Ratings content={content} />
      ) : (
        <RatingsShow publicView={publicView} ratings={content.rating || 5} />
      )}
      {getToken() && showWatched && watched && <Watched>Visualizado</Watched>}
      {hasShare && <ShareButton />}
      {content && content.userCollections && publicView === false ? (
        !getToken() ? (<Favorite
          publicView={false}
        />) : (<Favorite
          favorite={
            !!content.userCollections.filter(
              (collection) => collection['@type'] === 'UserCollectionFavorite'
            )[0]
          }
          showFavoriteRemove={showFavoriteRemove}
          content={content['@id']}
        />)
      ) : (
        <Favorite publicView />
      )}
    </Row>
  )
}

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.insideContentItem ? "15px" : "40px"};
`

export const Watched = styled.div`
  background: #00985f;
  font-size: 9px;
  text-transform: uppercase;
  padding: 0 15px;
  margin: 0 5px;
  border-radius: 99px;
  letter-spacing: 1.5px;
  font-weight: 700;
  color: #002c63;
`

Collections.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hasShare: PropTypes.bool,
  enabledRatings: PropTypes.bool,
  watched: PropTypes.bool,
  showWatched: PropTypes.bool
}
export default Collections
