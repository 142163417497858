import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import {Placeholder} from '../../Elements/styled'

export const Box = styled.div`
  display: flex;
  border-bottom: 1px solid #00b0e6;
  width: 100%;
  justify-content: space-between;
  margin: 0 5px;
  position: relative;
  background: rgba(0, 176, 230, 0.08);
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 50%;
    flex: 0 0 50%;
    margin: 0;
  }
  @media (min-width: 992px) {
    max-width: 260px;
  }
`

export const Input = styled(Placeholder).attrs({color: '#bdccda', as: 'input'})`
  border: 0;
  background: none;
  height: 50px;
  padding: 0 15px;
  font: inherit;
  outline: none;
  color: #fff;
  width: 100%;
  ::placeholder {
    color: #fff;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  ::-ms-input-placeholder {
    color: #fff;
  }
`

export const Button = styled.button`
  border: 0;
  background: none;
  color: #ffffff;
  width: 40px;
  cursor: pointer;
  font-size: 20px;
`

export const Results = styled.div`
  padding: 25px;
  position: absolute;
  top: 50px;
  background: #fff;
  z-index: 99;
  border-radius: 10px;
  width: 120%;
  box-shadow: 0 0 10px rgba(189, 189, 189, 0.5);
`

export const ContentLists = styled.div`
  color: #a3a3a3;
  ${(props) =>
    props.hasBorder &&
    css`
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #a3a3a3;
    `}
`

export const Content = styled(Link)`
  color: #a3a3a3;
  text-decoration: none;
  font-size: 17px;
  margin-bottom: 10px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const ObjetivesLists = styled.div`
  background-color: #b1b3b5;
  overflow: hidden;
  border-radius: 10px;
`

export const ObjetiveTitle = styled.div`
  font-size: 17px;
  background: #666e7a;
  color: #fff;
  padding: 10px;
`

export const Objetive = styled(Link)`
  display: block;
  color: #ffffff;
  font-size: 15px;
  padding: 10px;
  text-decoration: none;
  &:hover {
    background-color: rgba(0, 176, 230, 0.25);
  }
`
