/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import Modal from 'react-modal'
import { redirectToLogin } from '../../utils/auth'
import {Button} from '../Elements/Button'

import { Container, SignInButton } from './styles'

export const SignModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <img 
        className="react-modal-close" 
        src="/images/close.png" 
        alt="Fechar modal" 
        onClick={onRequestClose}
        style={{ cursor: "pointer" }}
      />
      <Container>
        <h2>Ops! Faça seu cadastro para acessar esse conteúdo.</h2>
        <p>Para ter acesso completo aos nossos conteúdos, basta realizar um cadastro gratuito. Caso você já tenha cadastro, faça seu login.</p>

        <Button className="SignUp" onClick={() => {
          window.location.href = `${
            process.env.REACT_APP_SIGNIN_URL
          }/cadastro?redirect=${btoa(window.location.href)}`
        }} color="primary" type="button">
          CADASTRA-SE GRÁTIS
        </Button>

        <SignInButton type="button" onClick={redirectToLogin}>
          JÁ TENHO CADASTRO
        </SignInButton>
      </Container>
    </Modal>
  )
}