import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link} from '../../Elements'
import {nameFormat} from '../../../utils/manipulateString'

const ProfileMenu = ({user}) => {
  return (
    <Li>
      <span className="customPadding">
        <Link to="/perfil">
          <I className="icon-user fal fa-user-circle" title={nameFormat(user.name)} />
          {nameFormat(user.name)}
        </Link>
      </span>

      <ul style={{right: 0}}>
        <li>
          <Link to="/perfil">Meu Perfil</Link>
        </li>

        <li>
          <Link to="/favoritos">Favoritos</Link>
        </li>

        <li>
          <Link to="/sair">Sair</Link>
        </li>
      </ul>
    </Li>
  )
}

const Li = styled.li`
  .customPadding a {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
`

const I = styled.i`
  font-size: 20px !important;
`

ProfileMenu.proptTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default ProfileMenu
