import React, {useEffect, useRef, useState, memo} from 'react'
import AnimateHeight from 'react-animate-height'
import {useRouteMatch} from 'react-router-dom'
import {BtnClose, BurguerMenu, FloatingButton, Navbar, NavMenu} from './styled'
import {Button, Link} from '../Elements'
import {getCurrentUser, getToken, redirectToLogin} from '../../utils/auth'
import BlockNotifications from '../Notifications/BlockNotifications'
import ProfileMenu from './_partials/ProfileMenu'
import ProfileMenuMobile from './_partials/ProfileMenuMobile'
import {SearchBlock} from '../Search/Block/SearchBlock'
import PartnersMenu from './_partials/PartnersMenu'
import ThemesMenu from './_partials/ThemesMenu'
import {getGroupPartners, getThemes} from '../../services/endpoints/contents/contents'
import {unseenNotifications} from '../../services/endpoints/notifications/notifications'

const Menu = () => {
  const isMounted = useRef(true)
  const [toogle, setToogle] = useState(!window.screen.width < 576)
  const [open, setOpen] = useState(!window.screen.width < 576)
  const [showButtonSingUp, setShowButtonSingUp] = useState(true)
  const {user} = getCurrentUser()
  const hasToken = getToken()
  const match = useRouteMatch()
  const [contentsPartners, setContentsPartners] = useState([])
  const [contentsTheme, setContentsTheme] = useState([])
  const [contentsNotifications, setContentsNotifications] = useState([])
  const menuSession = JSON.parse(sessionStorage.getItem('menus'))

  const removeNotification = (id) => {
    setContentsNotifications((old) => old.filter((item) => item.id !== id))
  }

  const handleCloseSignUp = () => {
    setShowButtonSingUp((prev) => !prev)
  }

  useEffect(() => {
    setToogle(true)
    setOpen(true)
  }, [match])

  useEffect(() => {
    if (isMounted.current) {
      (async () => {
        try {
          if (getToken()) {
            const responseNotifications = await unseenNotifications()
            setContentsNotifications(responseNotifications.data['hydra:member'] || [])
          }

          if (!menuSession) {
            const responsePartners = await getGroupPartners()
            const responseTheme = await getThemes()
            const partners = responsePartners.data['hydra:member'] || []
            const themes = responseTheme.data['hydra:member'] || []

            setContentsPartners(partners)
            setContentsTheme(themes)
            sessionStorage.setItem(
              'menus',
              JSON.stringify({
                partners,
                themes
              })
            )
          } else {
            setContentsPartners(menuSession.partners)
            setContentsTheme(menuSession.themes)
          }
        } catch (e) {
          console.error('Menu.useEffect', e)
        }
      })()
    }
    return () => {
      isMounted.current = false
    }
  }, [menuSession])

  const Item = ({item}) => {
    return (
      <li>
        <Link to={item.url} action={item.target}>
          {item.title}
          {item.childrens && item.childrens.length > 0 && <i className="far fa-angle-down" />}
        </Link>
        {item.childrens && item.childrens.length > 0 && (
          <ul>
            {item.childrens.map((children) => (
              <Item key={children.id} item={children} />
            ))}
          </ul>
        )}
      </li>
    )
  }

  return (
    <>
      <Navbar className="desktop">
        <BurguerMenu onClick={() => setToogle((prevValue) => !prevValue)}>
          <i className="far fa-bars" />
        </BurguerMenu>
        <NavMenu>
          <AnimateHeight duration={500} height={!toogle ? 'auto' : 0}>
            <ul>
              <ThemesMenu contents={contentsTheme} />
              <PartnersMenu contents={contentsPartners} />
              {hasToken ? (
                <>
                  <BlockNotifications
                    contents={contentsNotifications}
                    removeNotification={removeNotification}
                  />
                  <ProfileMenu user={user} />
                </>
              ) : (
                <>
                  <li>
                    <Button
                      onClick={() => {
                        redirectToLogin()
                      }}>
                      Entrar
                    </Button>
                  </li>
                  <li>
                    <Button
                      color="primary-light"
                      onClick={() => {
                        window.location.href = `${
                          process.env.REACT_APP_SIGNIN_URL
                        }/cadastro?redirect=${btoa(window.location.href)}`
                      }}>
                      Cadastre-se
                    </Button>
                  </li>
                </>
              )}
            </ul>
          </AnimateHeight>
        </NavMenu>
        {!hasToken && (
          <FloatingButton>
            <BtnClose
              className={`fal fa-${showButtonSingUp ? 'times' : 'plus'}`}
              onClick={handleCloseSignUp}
            />
            {showButtonSingUp && (
              <Button
                color="primary-light"
                onClick={() => {
                  window.location.href = `${
                    process.env.REACT_APP_SIGNIN_URL
                  }/cadastro?redirect=${btoa(window.location.href)}`
                }}>
                Cadastre-se
                <i className="far fa-user-circle" />
              </Button>
            )}
          </FloatingButton>
        )}
      </Navbar>
      <Navbar className="mobile">
        <BurguerMenu onClick={() => setToogle((prevValue) => !prevValue)}>Menu</BurguerMenu>

        <NavMenu>
          <AnimateHeight duration={500} height={!toogle ? 'auto' : 0}>
            {hasToken ? (
              <div className="pt-3">
                <div className="notification-mobile">
                  <ProfileMenuMobile setOpen={setOpen} open={open} user={user} />
                  <BlockNotifications
                    contents={contentsNotifications}
                    setOpen={setOpen}
                    open={open}
                    screenType="mobile"
                    removeNotification={removeNotification}
                  />
                </div>

                <div className="mb-3 mt-4">
                  <SearchBlock />
                </div>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => {
                    redirectToLogin()
                  }}>
                  Entrar
                </Button>
                <div className="mb-3 mt-4">
                  <SearchBlock />
                </div>
              </>
            )}
            <ul>
              <ThemesMenu
                contents={contentsTheme}
                screenType="mobile"
                setOpen={setOpen}
                open={open}
              />
              <PartnersMenu
                contents={contentsPartners}
                screenType="mobile"
                setOpen={setOpen}
                open={open}
              />
            </ul>
          </AnimateHeight>
        </NavMenu>
        {!hasToken && (
          <FloatingButton>
            <BtnClose
              className={`fal fa-${showButtonSingUp ? 'times' : 'plus'}`}
              onClick={handleCloseSignUp}
            />
            {showButtonSingUp && (
              <Button
                color="primary-light"
                onClick={() => {
                  window.location.href = `${
                    process.env.REACT_APP_SIGNIN_URL
                  }/cadastro?redirect=${btoa(window.location.href)}`
                }}>
                Cadastre-se
                <i className="far fa-user-circle" />
              </Button>
            )}
          </FloatingButton>
        )}
      </Navbar>
    </>
  )
}

export default memo(Menu)
