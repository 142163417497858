import {hasProperty} from '../../../utils/functions'

export const userWatchedTheContent = (userCollections) => {
  const UserCollectionView =
    userCollections.filter((item) => item['@type'] === 'UserCollectionView')[0] || {}

  if (hasProperty(UserCollectionView, 'viewed')) {
    return {
      watched: UserCollectionView.viewed
    }
  }
  return {
    watched: false
  }
}
