export const changeMaskPhone = (value) => {
  if (value !== undefined && value !== null) {
    // eslint-disable-next-line no-param-reassign
    value = value.replace(/\D/g, '')

    if (value.slice(4, 5) === '9') {
      return '+55 (99) 99999-9999'
    }
  }
  return '+55 (99) 9999-9999'
}

export const comparar = (a, b) => {
  if (a.position < b.position) {
    return -1
  }
  if (a.position > b.position) {
    return 1
  }
  // a deve ser igual a b
  return 0
}
