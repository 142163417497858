import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Container} from 'reactstrap'
import {getExam} from '../../services/endpoints/contents/contents'
import Exam from '../../components/Exam'
import {
  useCurrentPage,
  useHideBreadcrumb,
  usePageBase
} from '../../components/Body/contexts/PageContext'
import {settings} from '../../config/settings'

const ContentExam = ({match, history}) => {
  const {setPageBase} = usePageBase()
  const {setCurrentPage} = useCurrentPage()
  const {setHideBreadcrumb} = useHideBreadcrumb()

  useEffect(() => {
    setPageBase(settings.pageBase)
    setCurrentPage('')
    setHideBreadcrumb(false)
  }, [setPageBase, setCurrentPage, setHideBreadcrumb])
  const {id, pathCourse} = match.params
  const [exam, setExam] = useState({})
  const [loading, setLoading] = useState(true)

  const backUrl = () => {
    try {
      return atob(pathCourse)
    } catch (e) {
      return '/'
    }
  }

  const loadExam = useCallback(async () => {
    try {
      const response = await getExam(id)
      const {data} = response

      setExam(data || {})
      setLoading(false)
    } catch (e) {
      if (e.response.status === 404) {
        history.push('/404')
      } else {
        console.error('ContentExam.loadExam', e)
      }
    }
  }, [id, history])

  useEffect(() => {
    (async () => loadExam())()

    return () => {
      setLoading(true)
    }
  }, [loadExam])

  if (loading) {
    return null 
  }

  return (
    <Container>
      <BackToCourse to={backUrl}>Voltar para o curso</BackToCourse>

      <h1>Avaliação</h1>

      <p>{exam.title}</p>

      <Exam content={exam} contentId={`/v1/contents/${id}`} redirectUrl={backUrl()} />
    </Container>
  )
}

const BackToCourse = styled(Link)`
  color: #f4a325;
  font-style: italic;
  margin: 15px 0;
  display: inline-block;
`

export default ContentExam
