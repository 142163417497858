import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Button} from '../../components/Elements'

export const Box = styled.div`
  margin-bottom: 30px;
  position: relative;
`

export const BoxScrollOuter = styled.div`
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #002c63;
  }
  &::before {
    content: '';
    padding-bottom: ${({relatedContent}) => (relatedContent ? '149%' : '165.5%')};
    display: block;
  }
`

export const BoxScrollInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
`
export const BoxScrollInnerRelative = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 5px;
`

export const Title = styled.h1`
  font-weight: normal;
  font-size: 30px;
  line-height: 110%;
  margin-bottom: 30px;
  color: #004685;
`

export const AlignBtnNextContent = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const BtnNextContent = styled(Button).attrs({type: Link, color: 'primary'})`
  display: inline-flex;
  align-items: center;
`
export const Description = styled.div`
  font-size: 15px;
  line-height: 23px;
  font-weight: 100;
`
