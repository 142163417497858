import React, {useState} from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import {useHistory} from 'react-router-dom'
import {
  Alternative,
  Alternatives,
  Button,
  ButtonSubmit,
  ExamForm,
  Progress,
  ProgressBar,
  ProgressLabel,
  Question,
  QuestionGroup,
  Questions
} from './styled'
import {postExamResolutions} from '../../services/endpoints/contents/contents'

const Exam = ({content, contentId, redirectUrl}) => {
  const history = useHistory()
  const [toogle, setToogle] = useState(content.examQuestions[0].id)
  const [state, setState] = useState([])
  const [enableFinish, setEnableFinish] = useState(false)

  const handleToogle = (id) => {
    setToogle((prevValue) => (prevValue !== id ? id : 0))
  }

  const handleChange = (event) => {
    const {name, value} = event.target

    setState((old) => {
      const newState = old

      if (newState.filter((item) => item.question === name).length) {
        newState.forEach((item, idx) => {
          if (item.question === name) {
            Object.assign(newState[idx], {
              alternative: value
            })
          }
        })
      } else {
        newState.push({
          question: name,
          alternative: value
        })
      }

      return newState
    })

    if (state.length === content.examQuestions.length) {
      setEnableFinish(true)
    } else {
      setEnableFinish(false)
    }
  }

  const nextQuestion = (idx) => {
    if (idx < content.examQuestions.length - 1) {
      setToogle(content.examQuestions[idx + 1].id)
    } else {
      setToogle(0)
    }
  }

  const onSubmit = async () => {
    try {
      await postExamResolutions({
        content: contentId,
        examApplicationAnswers: state
      })
      history.push(redirectUrl)
    } catch (e) {
      console.error('Exam.onSubmit', e)
      alert('Ocorreu um erro ao enviar as respostas\nTente novamente mais tarde.')
    }
  }

  return (
    <ExamForm>
      <Questions>
        {content.examQuestions.map((question, index) => (
          <QuestionGroup key={question.id}>
            <Question onClick={() => handleToogle(question.id)}>
              {question.title}
              <i className="far fa-angle-down" />
            </Question>
            <AnimateHeight duration={500} height={question.id === toogle ? 'auto' : 0}>
              <Alternatives>
                {question.examQuestionAlternatives.map((alternative) => (
                  <Alternative
                    key={alternative.id}
                    htmlFor={`radio_question_${question.id}_${alternative.id}`}>
                    <input
                      type="radio"
                      id={`radio_question_${question.id}_${alternative.id}`}
                      name={`/v1/exam_questions/${question.id}`}
                      value={`/v1/exam_question_alternatives/${alternative.id}`}
                      onChange={handleChange}
                    />
                    {alternative.title}
                  </Alternative>
                ))}
              </Alternatives>
              <Button onClick={() => nextQuestion(index)}>Marcar alternativa</Button>
            </AnimateHeight>
          </QuestionGroup>
        ))}
      </Questions>

      {enableFinish && <ButtonSubmit onClick={onSubmit}>Concluir avaliação</ButtonSubmit>}

      <Progress>
        <ProgressLabel>Progresso</ProgressLabel>
        <ProgressBar width={Math.floor((state.length * 100) / content.examQuestions.length)} />
      </Progress>
    </ExamForm>
  )
}

Exam.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  contentId: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired
}
export default Exam
