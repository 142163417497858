import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Button as ButtonEl} from '../Elements'

export const AboutBlockHome = styled.div`
  margin-top: 45px;
  margin-bottom: 45px;
`
export const AboutBlockInternal = styled.div`
  margin-bottom: 30px;
  @media (min-width: 992px) {
    margin-bottom: 60px;
  }
`

export const Title = styled.h2`
  color: #002c63;
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 40px;
`

export const Description = styled.div`
  font-size: 15px;
  line-height: 23px;
  font-weight: 100;
`

export const Button = styled(ButtonEl).attrs({
  type: Link
})`
  margin-top: 40px;
  border-radius: 30px;
  padding: 15px 48px;
  height: 55px;
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 1.5px;
  max-width: 210px;
`

export const Embed = styled.div.attrs({
  className: 'embed-responsive embed-responsive-16by9'
})``

export const Iframe = styled.iframe.attrs({
  className: 'embed-responsive-item',
  webkitallowfullscreen: true,
  mozallowfullscreen: true,
  allowfullscreen: true,
  scrolling: 'no',
  frameborder: 0
})``

export const Image = styled.img`
  max-width: 100%;
`
