import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useAlert} from 'react-alert'
import {FavoriteBookmark} from './styled'
import {postFavorite} from '../../../services/endpoints/contents/contents'
import {settings} from '../../../config/settings'

const Favorite = ({favorite, content, showFavoriteRemove, publicView = false}) => {
  const alert = useAlert()
  const [state, setState] = useState({
    favorite
  })

  const handleFavorite = async () => {
    try {
      await postFavorite({
        content
      })

      alert.show(
        state.favorite
          ? 'Conteúdo removido dos favoritos'
          : 'Adicionado aos seus conteúdos favoritos',
        {
          title: settings.titleSite,
          closeCopy: 'Ok'
        }
      )

      setState((old) => ({
        favorite: !old.favorite
      }))
      if (showFavoriteRemove) {
        window.location.reload()
      }
    } catch (e) {
      alert.show('Ocorreu um erro ao favoritar o conteúdo', {
        title: settings.titleSite,
        closeCopy: 'Ok'
      })
    }
  }

  return (
    <FavoriteBookmark onClick={handleFavorite}>
      {publicView && window.innerWidth > 576 && <span className="tooltip">
          Cadastre-se grátis para favoritar
      </span>}
      <i
        className={`fa${state.favorite ? 's' : 'r'} fa-heart`}
      />
    </FavoriteBookmark>
  )
}

Favorite.propTypes = {
  favorite: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired
}

export {Favorite}
