import {logout, refreshLogin} from './auth'

function checkDetails (detail) {
  switch (detail) {
    case 'Authentication Required: Expired Cognito Token':
      refreshLogin()
      break
    case 'Authentication Required: Login Limit Exceed':
      refreshLogin()
      break
    case 'Authenticated user is not a customer':
    case 'Access Denied.':
    case 'Authentication Required: Full authentication is required to access this resource.':
    case 'Authentication Required: Invalid Cognito Token':
    default:
      logout()
  }
}

export {checkDetails}
